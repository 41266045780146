import styled from 'styled-components/macro'

export const SearchResultsContainerHeader = styled.div`
  width: 100%;
  height: 12%;
  padding-left: 15px;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.heading5};

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    font-size: ${({ theme }) => theme.fontSize.textL};
    justify-content: left;
  }
`

import styled from 'styled-components/macro'

export const HeaderContainer = styled.header`
  width: 100%;
  height: 60px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.background.secondary};
  display: grid;
  grid-template-columns: auto 1fr;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.mediaBreakpoints.numbers.pc + 1}px`}) {
    border-radius: ${({ theme }) => theme.border.radius};
    height: 70px;
    grid-template-columns: auto auto auto;
  }

  @media screen and (min-width: 1400px) {
    grid-template-columns: 1fr auto 1fr;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    position: sticky;
    top: 0px;
    left: 0;
    z-index: 9998;
  }
`

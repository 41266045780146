import { Account } from 'src/types'
import { StyledSearchResult } from './styles'
import styled from 'styled-components/macro'
type SearchResultsProps = {
  results: Account[]
}

const Grid = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: stretch;
  place-items: center;
  flex-flow: column nowrap;
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    display: grid;
    grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);

    gap: 15px;
  }
`

export const SearchResults: React.FC<SearchResultsProps> = ({ results }) => {
  const renderResults = results.map((result) => (
    <StyledSearchResult key={result._id} result={result} />
  ))

  return <Grid>{renderResults}</Grid>
}

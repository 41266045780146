import { NotificationMenuPCIcon } from 'src/icons'
import styled from 'styled-components/macro'

export const StyledNotificationMenuPC = styled(NotificationMenuPCIcon)`
  display: none !important;

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    display: block !important;
  }
`

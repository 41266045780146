import styled from 'styled-components'

export const Container = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 20px;
  flex: none;
  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    width: 300px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.mobile}) {
    width: 100%;
    padding: 20px;
  }
`

export const Logo = styled.div`
  width: auto;
  height: 80px;
  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    height: 60px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.mobile}) {
    height: 40px;
  }
`

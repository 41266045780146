import { createAsyncThunk } from '@reduxjs/toolkit'
import { getActiveAccountService } from 'src/services/Account/getActiveAccountService'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type GetAccountArgs = {
  token?: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const getActiveAccount = createAsyncThunk<
  Account & { exp: number },
  GetAccountArgs,
  { rejectValue: ApiError }
>(
  'account/getCurrentData',
  async ({ token, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await getActiveAccountService({ token }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import { createAsyncThunk } from '@reduxjs/toolkit'
import { postResendVerifyEmailServiceByAdmin } from 'src/services/Account/postResendVerifyEmailServiceByAdmin'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type postResendVerifyEmailArgs = {
  onSuccess?: OnSuccess
  onError?: OnError
  payload: { email: string }
}

export const postResendVerifyEmailByAdmin = createAsyncThunk<
  Account,
  postResendVerifyEmailArgs,
  { rejectValue: ApiError }
>(
  'account/verifyEmail',
  async ({ onSuccess, onError, payload }, { rejectWithValue, signal }) => {
    try {
      const result = await postResendVerifyEmailServiceByAdmin(
        { payload },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

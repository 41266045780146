import {
  eventsFeedQuerySelector,
  eventsFeedStateSelector,
  getEventsFeedAction,
  inProgressEventsFeedSelector,
  inProgressEventsFeedStateSelector,
  previousEventsFeedSelector,
  previousEventsFeedStateSelector,
  todaysEventsFeedSelector,
  todaysEventsFeedStateSelector,
  upcomingEventsFeedSelector,
  upcomingEventsFeedStateSelector,
} from 'src/store'
import { useAppSelector } from '../useAppSelector'
import { useAppDispatch } from '../useAppDispatch'
import { useEffect } from 'react'
import { formatQueryTyped } from 'src/utils'

export const useEventsFeed = () => {
  const dispatch = useAppDispatch()
  const query = useAppSelector(eventsFeedQuerySelector)

  const todaysState = useAppSelector(todaysEventsFeedStateSelector)
  const todaysEvents = useAppSelector(todaysEventsFeedSelector.selectAll)

  const upcomingState = useAppSelector(upcomingEventsFeedStateSelector)
  const upcomingEvents = useAppSelector(upcomingEventsFeedSelector.selectAll)

  const previousState = useAppSelector(previousEventsFeedStateSelector)
  const previousEvents = useAppSelector(previousEventsFeedSelector.selectAll)

  const inProgressState = useAppSelector(inProgressEventsFeedStateSelector)
  const inProgressEvents = useAppSelector(
    inProgressEventsFeedSelector.selectAll
  )

  const { count, tags, get } = useAppSelector(eventsFeedStateSelector)

  useEffect(() => {
    dispatch(
      getEventsFeedAction({
        query: formatQueryTyped(query),
      })
    )
  }, [query])

  return {
    count,
    tags,
    get,
    todaysEvents: {
      data: todaysEvents,
      get: todaysState.get,
    },
    upcomingEvents: {
      data: upcomingEvents,
      get: upcomingState.get,
    },
    previousEvents: {
      data: previousEvents,
      get: previousState.get,
    },
    inProgressEvents: {
      data: inProgressEvents,
      get: inProgressState.get,
    },
  }
}

import styled from 'styled-components'

export const InputGrid = styled.div<{
  $row?: boolean
  $twoColumns?: boolean
  danger?: boolean
}>`
  ${({ $twoColumns, $row, theme }) =>
    $twoColumns
      ? `
    display: grid;
    grid-template-columns: 100%;
    
    @media screen and (min-width: ${theme.mediaBreakpoints.tablet}) {
     grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
    }
   `
      : `
    display: flex;
 
   place-items: center;
   flex-flow: ${$row ? 'row' : 'column'} nowrap;
   
   `};
  align-items: stretch;
  width: 100%;
  max-width: 800px;

  gap: 15px;
`

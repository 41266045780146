import { AccountsPL } from './AccountsPL'
import { CTAButtonsPL } from './CTAButtonsPL'
import { DatePL } from './DatePL'
import { Entry } from './Entry'
import { EventsPL } from './EventsPL'
import { AccountsPagePL } from './AccountsPagePL'
import { Navbar } from './Navbar'
import { Profile } from './Profile'
import { YupValidationErrors } from './YupValidationErrors'
import { Settings } from './Settings'
import { AddModalPL } from './AddModalPL'
import { SupportUsPL } from './SupportUsPL'
import { WelcomePL } from './Welcome'
import { EventsPagePL } from './EventsPagePL'

export const components = {
  ...Entry,
  ...YupValidationErrors,
  ...Navbar,
  ...Profile,
  ...EventsPL,
  ...AccountsPL,
  ...AccountsPagePL,
  ...Settings,
  ...DatePL,
  ...CTAButtonsPL,
  ...AddModalPL,
  ...SupportUsPL,
  ...WelcomePL,
  ...EventsPagePL,
}

import { NavbarItem } from 'src/components/Navbar/NavbarItem'
import styled from 'styled-components/macro'

export const StyledNavbarItem = styled(NavbarItem)`
  visibility: visible !important;

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    visibility: hidden !important;
  }
`

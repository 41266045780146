import styled from 'styled-components'

export const ButtonBoxContainer = styled.div<{ $danger?: boolean }>`
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  padding: 10px;
  border: 1px solid
    ${({ theme, $danger }) =>
      $danger ? theme.colors.red.main : theme.colors.borderColor};
  border-radius: ${({ theme }) => theme.border.radius};
  gap: 10px;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    flex-flow: row nowrap;
    place-items: center;
    gap: 0px;
  }
`
export const Left = styled.div<{ $danger?: boolean }>`
  width: 100%;
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  :not(.ant-btn) > span {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ $danger, theme }) =>
      $danger ? theme.colors.red.main : theme.colors.fontColor.primary};
  }
  p {
    margin: 0;
    padding: 0;
  }
`
export const Right = styled.div`
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  width: 50%;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    place-items: flex-end;
  }
`

import { Events } from 'src/locale/types/components/Events'

export const EventsPL: Events = {
  Events: 'Wydarzenia',
  Price: 'Cena',
  AddedBy: 'Dodane przez',
  Details: 'Szczegóły',
  Discussion: 'Dyskusja',
  Reviews: 'Opinie',
  Public: 'Publiczne',
  Private: 'Prywatne',
  PrivacyOnlyWithTheLink: 'Tylko dla posiadających link',
  Duration: 'Czas trwania',
  Privacy: 'Prywatność',
  EventMode: 'Tryb',
  Remote: 'Zdalny',
  Hybrid: 'Hybrydowy',
  Stationary: 'Stacjonarny',
  AddEventImage: 'Dodaj zdjęcie do wydarzenia',
  EventDescription: 'Opis wydarzenia',
  EventTitle: 'Tytuł wydarzenia',
  Delete: 'Usuń',
  ChangeImage: 'Zmień zdjęcie',
  EventEndDate: 'Koniec wydarzenia',
  EventStartDate: 'Start wydarzenia',
  EventCTAPreview: 'Podgląd',
  EventPrivacyWarning:
    'Uwaga! Poprzez zmianę prywatności możesz upublicznić informacje na temat wydarzenia!',
  EventModeWarning:
    'Uwaga! Zmieniając tryb wydarzenia pamiętaj, żeby zamieścić odpowiednie informacje w opisie, które umożliwią wzięcie w nim udziału.',
  EventFree: 'Wydarzenie darmowe',
  EventDelete: 'Usuń wydarzenie',
  EventDeleteWarning:
    'Uwaga! Po usunięciu wydarzenia nie ma już powrotu... Wszystkie dane oraz cała aktywność zostają usunięte.',
  EventTerms: 'Daty startu i końca wydarzenia',
  GoToEvent: 'Zobacz wydarzenie',
  EventModalConfirmTitle: 'Chcesz opuścić stronę bez zapisania zmian?',
  Yes: 'Tak',
  No: 'Nie',
  EventInProgess: 'Trwa właśnie teraz',
  EventEnded: 'Odbyło się',
  EventToday: 'Dzisiaj',
  LoadMore: 'Załaduj więcej',
  SaveAndGoToEvent: 'Zapisz i przejdź do wydarzenia',
  Draft: 'Wersja robocza',
  Free: 'BEZPŁATNE',
  EventPriceOver099: 'Cena musi być większa bądź równa 0.99zł.',
  Search: 'Szukaj',
  confirmEventDelete: 'Jesteś pewien, że chcesz usunąć to wydarzenie?',
  Category: 'Kategoria',
  PaidEvent: 'Wydarzenie płatne',
  DoYouWantToAddPaidEvent:
    'Chcesz dodać płatne wydarzenie? Jeśli tak, to jest to możliwe po dokonaniu dobrowolnej darowizny ma rzecz naszego projektu!',
  ThankYouForSupportingOurProject:
    'Bardzo dziękujemy za wsparcie naszego projektu!',
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { postSendVerificationEmailService } from 'src/services/Account/postSendVerificationEmailService'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type postSendVerificationEmailArgs = {
  payload: {
    token: string
  }
  onSuccess?: OnSuccess
  onError?: OnError
}

export const postSendVerificationEmail = createAsyncThunk<
  undefined,
  postSendVerificationEmailArgs,
  { rejectValue: ApiError }
>(
  'account/verifyEmail',
  async ({ payload, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await postSendVerificationEmailService(
        { payload },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

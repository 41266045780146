import { Upload } from 'antd'
import styled from 'styled-components'

export const StyledForm = styled.div`
  width: 100%;
  max-width: 800px;
  padding-top: 20px;
  form {
    gap: 20px;
    display: flex;
    flex-flow: column nowrap;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    margin-top: 0;
    padding-bottom: 50px;
  }
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    padding-bottom: 0;
  }
`

export const InputLabel = styled.label<{ $withGap?: boolean }>`
  span {
    font-size: ${({ theme }) => theme.fontSize.textXM};
  }
  p {
    font-size: ${({ theme }) => theme.fontSize.textXM};
    color: ${({ theme }) => theme.colors.red.main};
  }

  ${({ $withGap }) =>
    $withGap &&
    `
    display: flex;
    flex-direction: column;
    gap: 10px;
  `}
`

export const EventImageUploadConfig = styled(Upload)`
  .ant-upload {
    display: flex;
    width: 100%;
    max-width: 800px;
  }
`

export const EventImageUpload = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: transparent;
  border-radius: ${({ theme }) => theme.border.radius};
  width: calc(100% - 20px);
  max-width: 800px;
  height: 100px;
  color: ${({ theme }) => theme.colors.purple.main};
  display: flex;
  place-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.textL};
  transition: 0.3s;
  cursor: pointer;
  margin-bottom: 20px;
  :hover {
    background: ${({ theme }) => theme.colors.background.secondary};
    transition: 0.3s;
  }
  margin: 0 10px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    width: 100%;
    margin: 0;
  }
`

export const EventImageUploadedContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  align-self: center;
  margin: auto;
`

export const EventImageUploadBar = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background.secondary}B3;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  place-items: center;
  height: 40px;
  position: absolute;
  bottom: 0;
`

export const PreviewButtonContainer = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 80%;
`

export const EventPriceCheckbox = styled.div`
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  gap: 10px;
  color: ${({ theme }) => theme.colors.fontColor.primary};
  strong {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`

export const SupportUs = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
`

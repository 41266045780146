import styled from 'styled-components'

export const SingleEventHeaderProfileContainer = styled.div`
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  gap: 5px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.textXM};
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.textM};
    flex-flow: row nowrap;
    place-items: center;
  }
`

export const SingleEventHeaderProfileAccount = styled.div`
  display: flex;
  place-items: center;
  flex-flow: row nowrap;
  gap: 5px;
`

export const SingleEventHeaderProfileImage = styled.div`
  max-width: 25px;
  max-height: 25px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-table;
  place-items: center;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  overflow: hidden;
  img {
    width: 25px;
    height: 25px;
  }
`

export const SingleEventHeaderProfileName = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  place-items: center;
  flex-flow: row wrap;
  gap: 5px;
  :hover {
    transition: 0.3s;
    text-decoration: underline;
  }
  span {
    word-break: break-all;
  }
  font-size: ${({ theme }) => theme.fontSize.textXM};
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.textM};

  }
`

import { Helmet } from 'react-helmet'
import { EmptyContainer, EntryContainer } from 'src/components'
import { Logo } from 'src/components/Containers/EntryContainer/EntryContainer.styles'
import { LongLogo } from 'src/components/Logo/LongLogo'
import { t } from 'src/locale'

export const E404 = () => {
  return (
    <>
      <Helmet>
        <title>{t('pageNotFound')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <EmptyContainer>
        <Logo>
          <LongLogo />
        </Logo>
        <EmptyContainer>
          <h2>{t('pageNotFound')}</h2>
        </EmptyContainer>
      </EmptyContainer>
    </>
  )
}

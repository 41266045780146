import { Form } from 'formik'
import { AppInput } from 'src/components/AppComponents'
import styled from 'styled-components'

export const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 15px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    flex-flow: row;
  }
`

export const Placeholder = styled.div`
  display: flex;
`

export const Input = styled(AppInput)`
  display: flex;
  width: 400px;
`

export const Buttons = styled.div`
  display: flex;
  place-items: center;
  gap: 15px;
`

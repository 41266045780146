import { useAppSelector } from 'src/hooks'
import { Account } from 'src/types'
import { AppLoading } from '../AppComponents/AppLoading'
import { SearchResults } from './SearchResults'
import { ExploreAccountsContainer } from './styles/ExploreAccounts'
import { ResultsNotFound } from './styles/ResultsNotFound'

export const ExploreAccounts: React.FC<{
  results: Account[]
}> = ({ results }) => {
  const { isLoading } = useAppSelector(
    (state) => state.account.fetchedAccounts.status
  )

  return (
    <ExploreAccountsContainer>
      {isLoading && <AppLoading />}
      {!isLoading && results.length > 0 && <SearchResults results={results} />}
      {!isLoading && results.length === 0 && (
        <ResultsNotFound>Results not found</ResultsNotFound>
      )}
    </ExploreAccountsContainer>
  )
}

import { notification } from 'antd'
import { FormikErrors } from 'formik'

export const handleFormikErrors = (validationErrors: FormikErrors<any>) => {
  Object.values(validationErrors).map((value) => {
    if (typeof value === 'string') {
      notification.error({
        message: `${value}`,
      })
    } else if (typeof value === 'object') {
      Object.values(value).map((objErr) => {
        if (typeof objErr === 'object') {
          Object.values(objErr).map((objErrErr) => {
            notification.error({
              message: `${objErrErr}`,
            })
          })
        } else {
          notification.error({
            message: `${objErr}`,
          })
        }
      })
    }
  })
}

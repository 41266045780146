import { MenuProps, notification } from 'antd'
import { AiOutlineCalendar } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { AppButton, AppDropdown } from 'src/components/AppComponents'
import { useActiveAccount, useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { postOneEventByIdAction } from 'src/store'
import { DropdownItem } from './DropdownItem'

export const AppHeaderAddButton: React.FC = () => {
  const dispatch = useAppDispatch()
  const activeAccount = useActiveAccount()
  const navigate = useNavigate()

  const postOneEvent = () => {
    if (activeAccount?._id && activeAccount.isEmailVerified) {
      dispatch(
        postOneEventByIdAction({
          payload: {
            author: activeAccount._id,
          },
          onSuccess: (result) => {
            navigate(`/events/${result.eventId}/edit`)
          },
          onError: (err: any) => {
            notification.error({
              message: String(err.response.data.message),
            })
          },
        })
      )
    } else if (!activeAccount?.isEmailVerified) {
      notification.error({
        message: t('YourEmailIsNotVerified'),
      })
    }
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <DropdownItem
          title={t('AddEventTitle')}
          content={t('AddEventContent')}
          onClick={postOneEvent}
        />
      ),
      key: 2,
      icon: <AiOutlineCalendar size={24} />,
    },
    // {
    //   label: (
    //     <DropdownItem
    //       title={t('AddPostTitle')}
    //       content={t('AddPostContent')}
    //     />
    //   ),
    //   key: 1,
    //   icon: <AiOutlineAlignLeft size={24} />,
    // },
  ]

  return (
    <AppDropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
      <AppButton>+ {t('AddButtonText')}</AppButton>
    </AppDropdown>
  )
}

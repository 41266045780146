import { notification } from 'antd'
import { useState } from 'react'
import { useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { postResendVerifyEmail } from 'src/store'
import { AppButton } from '../AppComponents'
import { AppProgress } from '../AppComponents/AppProgress'
import {
  Button,
  NotificationHeaderBox,
  NotificationTitle,
  Progress,
  Title,
  WarningIcon,
} from './styles'

export const NotificationHeader = () => {
  const dispatch = useAppDispatch()
  const countDownInSeconds = 180
  const [counter, setCounter] = useState<number>(countDownInSeconds)
  const [showCounter, setShowCounter] = useState<boolean>(false)
  const [intervalId, setIntervalId] = useState<any>(0)

  function timer() {
    setIntervalId(
      setInterval(() => {
        setCounter((counter: number) => {
          if (counter > 1) {
            return counter - 0.2
          } else {
            setIntervalId((intervalId: any) => {
              if (intervalId) {
                clearInterval(intervalId)
                return 0
              }
            })
            setShowCounter(false)
            return countDownInSeconds
          }
        })
      }, 200)
    )
  }
  const onClick = () => {
    dispatch(
      postResendVerifyEmail({
        onSuccess: () => {
          notification.success({ message: t('WeHaveSentYouAnEmail') })
        },
      })
    )
    setShowCounter(true)
    timer()
  }

  return (
    <NotificationHeaderBox>
      <NotificationTitle>
        <Title>
          <WarningIcon size={25} />
          {t('YourEmailIsNotVerified')}
        </Title>
        <Button>
          {t('DontSeeAnEmail')}{' '}
          {showCounter ? (
            <Progress>
              <AppProgress
                percent={
                  ((countDownInSeconds - counter) / countDownInSeconds) * 100
                }
                showInfo={false}
              />
            </Progress>
          ) : (
            <>
              <AppButton onClick={onClick} size="small">
                {t('ResendEmail')}
              </AppButton>
            </>
          )}
          <WarningIcon size={25} />
        </Button>
      </NotificationTitle>
    </NotificationHeaderBox>
  )
}

import { ReactComponent as Explore } from './explore.svg'
import { ReactComponent as Event } from './event.svg'
import { ReactComponent as Home } from './home.svg'
import { ReactComponent as Map } from './map.svg'
import { ReactComponent as Offer } from './offer.svg'
import { ReactComponent as NotificationMenuMobile } from './notificationMenuMobile.svg'
import { ReactComponent as NotificationMenuPC } from './notificationMenuPC.svg'

export * from './SuccessIcon'

export const HomeIcon = Home
export const ExploreIcon = Explore
export const EventIcon = Event
export const OfferIcon = Offer
export const MapIcon = Map
export const NotificationMenuMobileIcon = NotificationMenuMobile
export const NotificationMenuPCIcon = NotificationMenuPC

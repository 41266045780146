import { FormikErrors } from 'formik'
import { AppDivider, AppInput, InputBlock } from 'src/components/AppComponents'
import { AppTextArea } from 'src/components/AppComponents/AppInputs/AppTextArea'
import { t } from 'src/locale'
import { EditEvent } from '../EditSingleEventForm'
import { InputLabel } from '../styles/EditSingleEventForm.styles'
import { CategoryTagsSelector, ModeSelector } from 'src/components'

type DetailsProps = {
  errors: FormikErrors<EditEvent>
}

export const Details = ({ errors }: DetailsProps) => {
  return (
    <InputBlock>
      <InputLabel htmlFor="title">
        <span>{t('EventTitle')}</span>
        <AppInput
          name="title"
          maxLength={100}
          showCount
          required
          error={errors.title}
        />
      </InputLabel>
      <InputLabel htmlFor="description">
        <span>{t('EventDescription')}</span>
        <AppTextArea
          name="description"
          maxLength={2500}
          showCount
          autoSize={true}
          error={errors.description}
          required
        />
      </InputLabel>
      <AppDivider />
      <InputLabel htmlFor="categories">
        <CategoryTagsSelector name="categories" />
      </InputLabel>
      <AppDivider />
      <InputLabel htmlFor="mode" $withGap>
        <ModeSelector name="mode" />
        <p>{t('EventModeWarning')}</p>
      </InputLabel>
    </InputBlock>
  )
}

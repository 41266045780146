import { t } from 'src/locale'
import * as Yup from 'yup'

export const editSingleEventValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required(`${t('EventTitle')}: ${t('required')}`)
    .max(100, `${t('EventTitle')}: ${t('TextIsTooLong')}`),
  description: Yup.string()
    .required(`${t('EventDescription')}: ${t('required')}`)
    .max(2500, `${t('EventDescription')}: ${t('TextIsTooLong')}`),
  image: Yup.string().nullable(),
  terms: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.string(),
        from: Yup.date().required(`${t('EventStartDate')}: ${t('required')}`),
        to: Yup.date()
          .required(`${t('EventEndDate')}: ${t('required')}`)
          .when(
            'from',
            (from, schema) =>
              from &&
              schema.min(
                from,
                `${t('EventEndDate')}: ${t('EndDateMustBeLaterThanStartDate')}`
              )
          ),
        parent: Yup.string(),
        updated: Yup.boolean().notRequired(),
      })
    )
    .min(1, `${t('EventTerms')}: ${t('required')}`),
  cta: Yup.object({
    link: Yup.string()
      .matches(/^\S+$/, 'Link: Link nie powinien zawierać spacji.')
      .when('type', {
        is: 'youtube',
        then: Yup.string().matches(
          /^(?:(?:https?:\/\/|HTTPS?:\/\/)?(?:[\w-]+\.)+(?:youtube\.|[a-z]{2,}|youtu\.be\/))([^\s?#]*)(?:\?[^#\s]*)?(?:#[^\s]*)?$/,
          `Link: ${t('CTAYoutubeUrlNotValid')}`
        ),
        otherwise: Yup.string().matches(
          /^(?:https?:\/\/|HTTPS?:\/\/)?(?:[\w-]+\.)+[a-z]{2,6}(\/[^\s?#]*)?(?:\?[^\s#]*)?(?:#[^\s]*)?$/,
          `Link: ${t('NotValidUrl')}`
        ),
      })
      .when('type', {
        is: 'facebook',
        then: Yup.string().matches(
          /^(?:(?:https?:\/\/|HTTPS?:\/\/)?(?:[\w-]+\.)+(?:[a-z]{2,6}|facebook|fb))(\/[^\s?#]*)?(?:\?[^#\s]*)?(?:#[^\s]*)?$/,
          `Link: ${t('CTAFacebookUrlNotValid')}`
        ),
        otherwise: Yup.string().matches(
          /^(?:https?:\/\/|HTTPS?:\/\/)?(?:[\w-]+\.)+[a-z]{2,6}(\/[^\s?#]*)?(?:\?[^\s#]*)?(?:#[^\s]*)?$/,
          `Link: ${t('NotValidUrl')}`
        ),
      })
      .required(`Link: ${t('required')}`),
    type: Yup.string(),
  }),
  price: Yup.number().when('free', {
    is: false,
    then: Yup.number().min(0.99, `${t('Price')}: ${t('EventPriceOver099')}`),
    otherwise: Yup.number().notRequired(),
  }),
  categories: Yup.array(Yup.string())
    .min(1, `${t('Category')}: ${t('required')}`)
    .required(`${t('Category')}: ${t('required')}`),
  mode: Yup.string().required(`Tryb: ${t('required')}`),
})

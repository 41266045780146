import { MetaTags } from 'src/components'
import { List } from 'src/components/Events/EventsPage'
import { Query } from 'src/components/Events/EventsPage/Query'
export const EventsPage = () => {
  return (
    <>
      <MetaTags
        title="Wszystkie wydarzenia w jednym miejscu!"
        desc="Przeglądaj wydarzenia z całej Polski! Wszystkie zebrane w jednym miejscu!"
        url="https://mapawiedzy.com/"
        image="https://mapawiedzy.com/meta-images/events.png"
      />
      <Query />
      <List />
    </>
  )
}

import { FormikErrors } from 'formik'
import { AppInput, AppSelect, InputBlock } from 'src/components/AppComponents'
import { t } from 'src/locale'
import { SingleEventHeaderCTAs } from '../../SingleEvent/SingleEventHeader'
import { EditEvent } from '../EditSingleEventForm'
import {
  InputLabel,
  PreviewButtonContainer,
} from '../styles/EditSingleEventForm.styles'
type EventCTAButtonProps = {
  errors: FormikErrors<EditEvent>
  values: EditEvent
}
export const EventCTAButton = ({ errors, values }: EventCTAButtonProps) => {
  const buttonTypeOptions = [
    {
      label: t('CTASignUp'),
      value: 'sign_up',
    },
    {
      label: t('CTASeeMore'),
      value: 'see_more',
    },
    {
      label: 'Youtube',
      value: 'youtube',
    },
    {
      label: 'Facebook',
      value: 'facebook',
    },
    {
      label: t('CTAWebsite'),
      value: 'website',
    },
    {
      label: t('CTAOther'),
      value: 'other',
    },
  ]

  return (
    <InputBlock $twoColumns>
      <div>
        <InputLabel>
          <span>{t('CTAType')}</span>
          <AppSelect
            name="cta.type"
            //@ts-ignore
            error={errors.cta?.type}
            options={buttonTypeOptions}
            required
            getPopupContainer={(trigger) =>
              trigger.parentElement as HTMLElement
            }
          />
        </InputLabel>
        <InputLabel>
          <span>{t('CTAOther')}</span>
          <AppInput
            name="cta.link"
            //@ts-ignore
            error={errors.cta?.link}
            touched={true}
            required
          />
        </InputLabel>
      </div>
      <InputLabel>
        <span>{t('EventCTAPreview')}</span>
        {values.cta?.type && (
          <PreviewButtonContainer>
            <SingleEventHeaderCTAs type={values.cta?.type} link="#" />
          </PreviewButtonContainer>
        )}
      </InputLabel>
    </InputBlock>
  )
}

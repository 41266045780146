import { Popover } from 'antd'
import styled from 'styled-components/macro'

type AppNotificationPopoverWrapperProps = React.ComponentProps<typeof Popover>

// Hack to apply styled component to popover
const AppNotificationPopoverWrapper: React.FC<
  AppNotificationPopoverWrapperProps
> = ({ className, children, ...otherProps }) => {
  return (
    <Popover overlayClassName={className} {...otherProps}>
      {children}
    </Popover>
  )
}

export const AppNotificationPopover = styled(AppNotificationPopoverWrapper)`
  .ant-popover-inner {
    border-radius: 16px;
  }
  .ant-popover-inner-content {
    padding: 0;
  }
`

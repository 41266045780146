import { useNavigate } from 'react-router-dom'
import {
  DrawerItemContainer,
  DrawerItemContent,
  DrawerItemTitleWrappper,
} from './styles'

type DrawerItemProps = {
  title: string
  content: string
  icon: React.ReactNode
  to?: string
}

export const DrawerItem: React.FC<DrawerItemProps> = ({
  title,
  content,
  icon,
  to,
}) => {
  const navigate = useNavigate()

  return (
    <DrawerItemContainer
      onClick={to !== undefined ? () => navigate(`/${to}`) : () => {}}
    >
      {icon}
      <DrawerItemContent>
        <DrawerItemTitleWrappper>{title}</DrawerItemTitleWrappper>
        {content}
      </DrawerItemContent>
    </DrawerItemContainer>
  )
}

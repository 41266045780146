import { AiFillHeart } from "react-icons/ai"
import { FaHandshake } from "react-icons/fa"
import { MdBarChart, MdOutlineAttachMoney, MdStackedLineChart, MdVolunteerActivism } from "react-icons/md"
import { AppButton } from "src/components/AppComponents"
import { Account } from "src/types"
import { List } from "../styles"
import { PatronCard } from "./PatronCard"
import { Accent, CentralCol, ContentContainer, PatronGrid, Space, StatsCard, StatsContainer } from "./styles"
import { StyledDivider } from "./styles/Divider.styles"

type ContentProps = {
   patrons: Account[]
}

export const Content = ({patrons}: ContentProps) => {

   const renderPatrons = patrons.map(patron => <PatronCard account={patron}/>)

   return (
      <>         
         <StyledDivider><MdStackedLineChart size={50} /></StyledDivider>
         <CentralCol>
            <ContentContainer>
            <h2>Benefity przysługujące patronom:</h2>
            <List>
               <li>
                 Przypomnienia, dla grupy odbiorców, o zamieszczonych wydarzeniach
                 na grupie na Messenger, Facebook oraz stories.
               </li>
               <li>
                 Promocja zamieszczonych wydarzeń na grupie na Facebooku +
                 przypięcie w zakładce "Wyróżnione".
               </li>
               <li>Logo i krótki opis patrona w wyróżnionych stories.</li>
               <li>Logo na stronie projektu w sekcji "Wspierają nas" </li>
               <li>Ranga "Patrona" na platformie mapawiedzy.com</li>
            </List>
            </ContentContainer>
         </CentralCol>
         <StyledDivider><MdOutlineAttachMoney size={50} /></StyledDivider>
         <CentralCol>
            <ContentContainer>
               <h2>Płatne wydarzenie na Mapie Wiedzy?</h2>
               <p>Tak, to możliwe! Publikacja płatnego wydarzenia przysługuje darczyńcy za każde 12 zł przekazane na naszą zrzutkę!</p>
               <AppButton onClick={() => window.open('https://zrzutka.pl/tyzyd7/award/jyat4m', '_blank')}>
                  Dowiedz się więcej!
               </AppButton>
            </ContentContainer>
         </CentralCol>
         <StyledDivider><AiFillHeart size={50} /></StyledDivider>
         <CentralCol>
            <ContentContainer>
               <h2>Wspierają nas</h2>
               <PatronGrid>
                  {renderPatrons}
               </PatronGrid>
            </ContentContainer>
         </CentralCol>
         <Space />
      </>
   )
}
import { ReactNode } from 'react'
import { CloseOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { CloseButton, Title, Wrapper } from './styles'
import { AppNotificationType } from './types'

type AppNotificationProps = {
  type?: AppNotificationType
  title?: string | React.ReactNode | null
  icon?: ReactNode
  onClose?: () => void
  showClose?: boolean
  children: React.ReactNode
}

export const AppNotification: React.FC<AppNotificationProps> = ({
  type = 'success',
  title,
  icon,
  showClose = true,
  onClose,
  children,
}) => {
  return (
    <Wrapper $type={type}>
      {showClose && (
        <CloseButton
          type="text"
          onClick={() => {
            if (onClose && typeof onClose === 'function') {
              onClose()
              return
            }
            // hack to trigger outside document click
            document.dispatchEvent(
              new Event('mousedown', {
                bubbles: true,
                cancelable: false,
              })
            )
          }}
        >
          <CloseOutlined />
        </CloseButton>
      )}
      {icon || <ExclamationCircleFilled />}
      {title && <Title>{title}</Title>}
      {children}
    </Wrapper>
  )
}

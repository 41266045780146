import { ReactElement } from 'react'
import {
  CTAFacebook,
  CTAOther,
  CTASeeMore,
  CTASignUp,
  CTAWebsite,
} from 'src/components/CTAButtons'
import { CTAYoutube } from 'src/components/CTAButtons/CTAYoutube'
import { CTAButtonsTypes } from 'src/types'
import { DomainLabel } from './styles'

type CTAconfigType = {
  [key in CTAButtonsTypes]: {
    component: ReactElement
  }
}

export const SingleEventHeaderCTAs: React.FC<{
  type: CTAButtonsTypes
  link: string
  archived?: boolean
}> = ({ type, link, archived }) => {
  const isLinkRegex = /^(http|https):\/\//i
  const onClick = () => {
    const targetLink = isLinkRegex.test(link) ? link : `https://${link}`
    window.open(targetLink, '_blank')
  }

  const splittedLink = link.split('/')
  const domain = isLinkRegex.test(link) ? splittedLink[2] : splittedLink[1]

  const CTAconfig: CTAconfigType = {
    see_more: {
      component: <CTASeeMore disabled={archived} onClick={onClick} />,
    },
    sign_up: {
      component: <CTASignUp disabled={archived} onClick={onClick} />,
    },
    youtube: {
      component: <CTAYoutube disabled={archived} onClick={onClick} />,
    },
    facebook: {
      component: <CTAFacebook disabled={archived} onClick={onClick} />,
    },
    website: {
      component: <CTAWebsite disabled={archived} onClick={onClick} />,
    },
    other: {
      component: <CTAOther disabled={archived} onClick={onClick} />,
    },
  }

  return (
    <>
      {CTAconfig[type].component}
      <DomainLabel>{domain}</DomainLabel>
    </>
  )
}

import { Form, Formik } from 'formik'
import { SearchInput } from './Forms'
import { QueryContainer, StyledForm } from './styles/Query.styles'
import { TagsQuery } from './Forms/TagsQuery'
import { ModeQuery } from './Forms/ModeQuery'
import { QueryRow } from './Forms/styles'
import { AdditionalQuery } from './Forms/AdditionalQuery'

export const Query = () => {
  return (
    <QueryContainer>
      <Formik initialValues={{}} onSubmit={() => {}}>
        <StyledForm>
          <Form>
            <SearchInput />
            <TagsQuery />
            <QueryRow>
              <ModeQuery />
              <AdditionalQuery />
            </QueryRow>
          </Form>
        </StyledForm>
      </Formik>
    </QueryContainer>
  )
}

import { Event } from 'src/types'
import { AppButton, EventComponent } from 'src/components'
import { AccountEventsContainer } from './styles'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useActiveAccount, useAppDispatch, useAppSelector } from 'src/hooks'
import { getEventsByAuthorIdAction } from 'src/store'
import { formatQueryTyped } from 'src/utils'
import { useEffect, useState } from 'react'
import { AppLoading } from 'src/components/AppComponents/AppLoading'
import { debounce } from 'lodash'
import { t } from 'src/locale'

type AccountEventsProps = {
  events: Event[]
  activeTab?: 'public' | 'private'
  setLimit: React.Dispatch<React.SetStateAction<number>>
  limit: number
  adjustLimitBy: number
}

export const AccountEvents: React.FC<AccountEventsProps> = ({
  events,
  activeTab = 'public',
  limit,
  setLimit,
  adjustLimitBy,
}) => {
  const dispatch = useAppDispatch()
  const activeAccount = useActiveAccount()
  const accountData = useAppSelector(
    (state) => state.account.accountProfile.account
  )
  const total = useAppSelector((state) => state.events.accountEvents.total)
  const renderAccountEvents = events.map((event) => (
    <EventComponent key={event.eventId} event={event} />
  ))

  const adjustLimit = () => {
    if (accountData) {
      dispatch(
        getEventsByAuthorIdAction({
          authorId: accountData._id,
          query: formatQueryTyped({
            owner: activeAccount?.username === accountData.username,
            only: activeTab,
            limit: limit + adjustLimitBy,
          }),
        })
      )
    }
  }

  return (
    <AccountEventsContainer>
      {renderAccountEvents}
      {limit < total && (
        <AppButton
          onClick={async () => {
            await setLimit(limit + adjustLimitBy)
            adjustLimit()
          }}
        >
          {t('LoadMore')}
        </AppButton>
      )}
    </AccountEventsContainer>
  )
}

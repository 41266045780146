import styled from 'styled-components/macro'

export const MobileWrapper = styled.div`
  padding-bottom: 65px;

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    margin: 0px;
    padding: 0px;
  }
`

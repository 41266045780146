import { ExclamationCircleFilled } from '@ant-design/icons'
import { Input, InputProps } from 'formik-antd'
import styled from 'styled-components/macro'

export const InputLabel = styled.label`
  padding-left: 10px;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.textS} !important;
`

export const InputWrapper = styled.div<{
  value: string | null
  disabled?: boolean
  readOnly?: boolean
  $isError?: boolean
  $isRequired?: boolean
}>`
  position: relative;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.borderColor : theme.colors.purple.main};
  border-radius: ${({ theme }) => theme.border.radius};
  border-width: ${({ theme }) => theme.border.width.default};
  ${({ $isError, $isRequired }) =>
    ($isRequired || $isError) && 'border-right: none;'}
  overflow: hidden;
  color: ${({ theme }) => theme.colors.fontColor.primary} !important;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    background-color: ${({ theme, $isError, $isRequired, value }) =>
      ($isError && theme.colors.red.main) ||
      ($isRequired && value
        ? theme.colors.green.main
        : theme.colors.red.main) ||
      'unset'};
    height: 100%;
    width: ${({ $isRequired, disabled, $isError }) =>
      !$isError && (disabled || !$isRequired) ? 0 : '5px'};
    transition: width 0.2s;
  }
`

export const StyledAppInput = styled(Input)<
  InputProps & { $transparent?: boolean; $bordered?: boolean }
>`
  width: 100%;
  ${({ $transparent, theme, readOnly }) =>
    !$transparent &&
    `background-color: ${
      !readOnly
        ? theme.colors.background.tertiary
        : theme.colors.background.secondary
    };`}
  border: none;
  font-size: ${({ theme }) => theme.fontSize.textXM} !important;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  border-radius: 0;
  transition: 0.3s ease-out;
  color: ${({ theme }) => theme.colors.fontColor.primary} !important;
  .ant-input {
    background-color: ${({ theme }) => theme.colors.background.tertiary};
    color: ${({ theme }) => theme.colors.fontColor.primary} !important;
    font-size: ${({ theme }) => theme.fontSize.textXM} !important;
    padding-top: 10px;
    padding-left: 24px;
    &::placeholder {
      color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
  }

  ${({ readOnly }) =>
    readOnly &&
    `
  .ant-input-focused,
  &:focus,
  &:hover,
  &:active {
    box-shadow: none !important;
    outline: none;
    border: none;
  }
  `}

  &:focus,
  &:hover,
  &:active {
    &: not([disabled]) {
      border: none;
      background-color: ${({ theme }) => theme.colors.background.tertiary};
    }
  }

  &[disabled] {
    color: ${({ theme }) => theme.colors.fontColor.primary};
    background: ${({ theme }) => theme.colors.background.secondary};
  }

  &[readonly]:hover {
    cursor: auto;
  }

  &[disabled]:hover,
  &[readonly]:hover {
    border: none;
  }

  .ant-input-show-count-suffix {
    color: ${({ theme }) => theme.colors.fontColor.primary} !important;
    position: absolute;
    right: 5px;
  }
`

export const ErrorIcon = styled(ExclamationCircleFilled)`
  color: ${({ theme }) => theme.colors.red.main};
  margin-left: 10px;
  margin-right: 15px;
`

export const StyledInputGroup = styled.div`
  display: flex;
  place-items: center;
  background: ${({ theme }) => theme.colors.background.tertiary};
`

import { Event } from 'src/types'
import { DescriptionContainer } from './Description.styles'

type DescriptionProps = {
  event: Event
}

export const Description: React.FC<DescriptionProps> = ({ event }) => {
  const splittedDesc = event.description.split(' ')
  const isLinkRegex = /^(http|https):\/\//i
  const descWithLinks = splittedDesc.map((word) => {
    const singleWords = word.split('\n')
    const singleWordsWithLink = singleWords.map((single) => {
      if (isLinkRegex.test(single)) {
        return `<a href="${single}">${single}</a>`
      }
      return single
    })

    const final = singleWordsWithLink.join('<br>')

    return final
  })

  const renderedDesc = descWithLinks.join(' ')

  return (
    <DescriptionContainer>
      <div
        dangerouslySetInnerHTML={{
          __html: renderedDesc,
        }}
      />
    </DescriptionContainer>
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCheckIfAccountUsernameIsTakenService } from 'src/services/Account/getCheckIfAccountUsernameIsTakenService'
import { ApiError, OnError, OnSuccess } from 'src/types'

type getCheckIfAccountUsernameIsTakenArgs = {
  username: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const getCheckIfAccountUsernameIsTaken = createAsyncThunk<
  { isNameTaken: boolean },
  getCheckIfAccountUsernameIsTakenArgs,
  { rejectValue: ApiError }
>(
  'account/getCheckIfAccountUsernameIsTaken',
  async ({ username, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await getCheckIfAccountUsernameIsTakenService(
        { username },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import { Form, Formik } from 'formik'
import { t } from 'src/locale'
import { AppSearch } from '../AppComponents'

type SearchInputProps = {
  setSearch: React.Dispatch<React.SetStateAction<string>>
  sendRequest: ({
    otherLimit,
    otherSearch,
  }: {
    otherLimit?: number
    otherSearch?: string
  }) => void
  startLimit: number
}

export const SearchInput: React.FC<SearchInputProps> = ({
  setSearch,
  sendRequest,
  startLimit,
}) => {
  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearch(e.target.value.trim())
    sendRequest({
      otherSearch: e.target.value.trim(),
      otherLimit: startLimit,
    })
  }

  return (
    <Formik
      initialValues={{
        search: '',
      }}
      onSubmit={(values) => {
        setSearch(values.search.trim())
        sendRequest({
          otherSearch: values.search.trim(),
          otherLimit: startLimit,
        })
      }}
    >
      {({ submitForm }) => (
        <Form>
          <AppSearch
            name="search"
            onChange={onChange}
            placeholder={t('AccountsPagePlaceholder')}
            type="text"
            size="large"
            allowClear
          />
        </Form>
      )}
    </Formik>
  )
}

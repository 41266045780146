import { CTAButtons } from 'src/locale/types/components/CTAButtons'

export const CTAButtonsPL: CTAButtons = {
  CTASeeMore: 'Zobacz więcej',
  CTASignUp: 'Zapisz się',
  CTAOther: 'Link',
  CTAWebsite: 'Strona WWW',
  CTAFacebookUrlNotValid: 'Wprowadzone url nie prowadzi do strony Facebooka.',
  CTAYoutubeUrlNotValid: 'Wprowadzone url nie prowadzi do strony Youtube.',
  CTAType: 'Typ przycisku',
}

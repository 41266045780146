import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import {
  AppButton,
  EmptyContainer,
  EntryContainer,
  FormContainer,
} from 'src/components'
import { t } from 'src/locale'
import { InfoIcon } from './styles'

export const SignUpCompleted = () => {
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title>{t('VerifyYourEmail')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <EmptyContainer>
        <EntryContainer>
          <FormContainer width={800}>
            <InfoIcon />
            <h1>{t('VerifyYourEmail')}</h1>
            <p>{t('WeSendYouAnEmailToVerify')}</p>
            <h5>{t('DontSeeAnEmail')}</h5>
            <p>{t('InCaseOfEmailNotReceived')}</p>
            <AppButton type="primary" onClick={() => navigate('/signin')}>
              {t('SignInTitle')}
            </AppButton>
          </FormContainer>
        </EntryContainer>
      </EmptyContainer>
    </>
  )
}

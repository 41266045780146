import { useEffect, useState } from 'react'
import { notification } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  AppButton,
  EmptyContainer,
  EntryContainer,
  FormContainer,
} from 'src/components'
import { useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { logout } from 'src/store'
import { postVerifyEmail } from 'src/store/Account/actions/postVerifyEmail'
import { FailedIcon } from './styles/FailedIcon'
import { SuccessIcon } from '../../icons/SuccessIcon'
import { Helmet } from 'react-helmet'

export const VerifyEmail = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [errorPage, setErrorPage] = useState<boolean>(false)

  useEffect(() => {
    if (token === null) {
      navigate('/')
    } else {
      dispatch(
        postVerifyEmail({
          payload: {
            token,
          },
          onError: (error: any) => {
            setErrorPage(true)
            notification.error({
              message: String(error.response.data.message),
            })
          },
        })
      )
    }
  }, [token])

  const handleClick = () => {
    dispatch(logout())
    navigate('/signin')
  }
  return (
    <>
      <Helmet>
        <title>{errorPage ? t('Success') : 'Error!'}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <EmptyContainer>
        <EntryContainer>
          <FormContainer>
            {errorPage ? (
              <>
                <FailedIcon />
                <h1>Error!</h1>
                <p>{t('EmailVerificationFailed')}</p>
                <AppButton danger onClick={handleClick}>
                  {t('SignInTitle')}
                </AppButton>
              </>
            ) : (
              <>
                <SuccessIcon />
                <h1>{t('Success')}</h1>
                <p>{t('YourEmailIsVerified')}</p>
                <AppButton type="primary" onClick={handleClick}>
                  {t('SignInTitle')}
                </AppButton>
              </>
            )}
          </FormContainer>
        </EntryContainer>
      </EmptyContainer>
    </>
  )
}

import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const BlockContainer = styled.div`
  display: flex;
  place-items: center;
  flex-flow: row nowrap;
  gap: 10px;
`

export const BlockText = styled.div`
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  gap: 10px;
  max-width: 400px;
`

export const BlockImage = styled.div`
  display inherit;
  place-items: center;
  width: 75px;
  img {
    width: 75px; 
    height: 75px;
  }
`

export const BlockTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.heading5};
`

export const BlockContent = styled.div`
  color: ${({ theme }) => theme.colors.fontColor.secondary};
`

export const StyledLink = styled(Link)<{ external?: boolean }>`
  color: ${({ theme }) => theme.colors.purple.main};
  text-decoration: underline;
  :hover {
    color: ${({ theme }) => theme.colors.green.main};
    text-decoration: underline;
  }
`

import styled from 'styled-components'

export const QueryRow = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`

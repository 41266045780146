import { AppButton } from 'src/components/AppComponents'
import { t } from 'src/locale'
import { Account } from 'src/types'
import { ProfileBecomePatronContainer } from './styles'

type ProfileBecomePatronProps = {
  account: Partial<Account>
}
export const ProfileBecomePatron = ({ account }: ProfileBecomePatronProps) => {
  return (
    <ProfileBecomePatronContainer>
      <span>
        <b>{`${account.name}`}</b>
        {` ${t('BecomeAPatron')} `}{' '}
        <a href="/patron">{t('HowToBecomeAPatron')}</a>
      </span>
    </ProfileBecomePatronContainer>
  )
}

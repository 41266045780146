import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { SliceStatus, Account, RootState } from 'src/types'
import { getAccountsByQueryFULL } from './actions'

type FetchedAccountsSliceData = {
  status: SliceStatus
  total: number
}

export const fetchedAccountsAdapterFULL = createEntityAdapter<Account>({
  selectId: ({ _id }) => _id,
})

export const fetchedAccountsSliceFULL = createSlice({
  name: 'fetchedAccountsFULL',
  initialState:
    fetchedAccountsAdapterFULL.getInitialState<FetchedAccountsSliceData>({
      status: {
        isLoading: false,
        error: null,
      },
      total: 0,
    }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountsByQueryFULL.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(getAccountsByQueryFULL.fulfilled, (state, action) => {
      fetchedAccountsAdapterFULL.setAll(state, action.payload.data)
      state.status.isLoading = false
      state.status.error = null
    })
    builder.addCase(getAccountsByQueryFULL.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
  },
})

export const fetchedAccountsSelectorFULL =
  fetchedAccountsAdapterFULL.getSelectors<RootState>(
    (state) => state.account.fetchedAccountsFULL
  )

import styled from 'styled-components'

export const CTAContainer = styled.div`
  display: flex;
  justify-content: right;
  place-items: center;
  gap: 10px;
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    padding-right: 15px;
    gap: 20px;
  }
`

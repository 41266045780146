import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAccountByUsernameService } from 'src/services/Account/'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type GetAccountArgs = {
  username: string
  token?: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const getAccountByUsername = createAsyncThunk<
  Account,
  GetAccountArgs,
  { rejectValue: ApiError }
>(
  'account/getAccountByUsername',
  async (
    { token, username, onSuccess, onError },
    { rejectWithValue, signal }
  ) => {
    try {
      const result = await getAccountByUsernameService(
        { token, username },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

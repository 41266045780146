import { notification } from 'antd'
import { Formik } from 'formik'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'src/hooks/useAppDispatch'
import { t } from 'src/locale'
import { login } from 'src/store'
import { getActiveAccount } from 'src/store/Account/actions/getActiveAccount'
import { LoginData } from 'src/types'
import {
  AppButton,
  AppInput,
  AppInputPassword,
  FormikForm,
} from '../AppComponents'

export const SignInForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleSubmitError = (err: any) => {
    notification.error({
      message: String(err.response.data.message),
    })
  }

  const handleSubmitSuccess = () => {
    debounce(() => dispatch(getActiveAccount({})), 300)
    navigate('/', { replace: true })
    notification.success({
      message: t('LoginSuccess'),
    })
    window.location.reload()
  }

  const handleSubmit = (values: LoginData) => {
    dispatch(
      login({
        payload: values,
        onError: handleSubmitError,
        onSuccess: handleSubmitSuccess,
      })
    )
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, submitForm }) => (
        <FormikForm>
          <AppInput name="email" placeholder="E-mail" required type="email" />
          <AppInputPassword
            name="password"
            placeholder={t('PasswordInputText')}
            required
          />
          <AppButton htmlType="submit">{t('SignInTitle')}</AppButton>
        </FormikForm>
      )}
    </Formik>
  )
}

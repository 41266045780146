import { createSlice } from '@reduxjs/toolkit'
import { SliceStatus } from 'src/types'
import {
  deleteAccountByIdAction,
  deleteActiveAccountByIdAction,
} from './actions'
import { login } from './actions/login'

type AccountSliceData = {
  token: string | null
  isLoggedIn: boolean
  status: SliceStatus
}

const initialValues: AccountSliceData = {
  token: null,
  isLoggedIn: false,
  status: {
    isLoading: false,
    error: null,
  },
}

export const accountLoginSlice = createSlice({
  name: 'account/login',
  initialState: initialValues,
  reducers: {
    logout: (state) => {
      state.token = null
      localStorage.removeItem('token')
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.status.isLoading = false
      state.status.error = null
      state.token = action.payload.access_token
      localStorage.setItem('token', action.payload.access_token)
      state.isLoggedIn = true
    })
    builder.addCase(login.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })

    builder.addCase(deleteActiveAccountByIdAction.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(
      deleteActiveAccountByIdAction.fulfilled,
      (state, action) => {
        state.status.isLoading = false
        state.status.error = null
        state.isLoggedIn = true
      }
    )
    builder.addCase(deleteActiveAccountByIdAction.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
  },
})

export const isLoggedInSelector = (state: AccountSliceData) => state.isLoggedIn

import { useEffect, useState } from 'react'
import { ButtonProps, Button } from 'antd'
import styled from 'styled-components/macro'

type AppButtonProps = ButtonProps & {
  $clicked?: boolean
  $square?: boolean
  $active?: boolean
  $selected?: boolean
  $iconSize?: number
}

export const StyledButton = styled(Button)<AppButtonProps>`
  font-size: ${({ theme }) => theme.fontSize.textM};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  border: 0.5px solid ${({ theme }) => theme.colors.borderColor};
  padding: 7px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  box-shadow: none;
  border-radius: 5px;
  min-width: auto;
  && {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme, $clicked }) =>
      $clicked ? theme.colors.white : theme.colors.purple.main};
  }
  &&[disabled] {
    color: ${({ theme }) => theme.colors.disabled.color};
    border-color: ${({ theme, type }) =>
      type === 'text' ? 'transparent' : theme.colors.disabled.color};
    & {
      background: ${({ theme }) => theme.colors.disabled.background};
    }
  }
  && .anticon {
    font-size: 16px;
    line-height: 0;
  }

  &:hover {

   opacity: 0.8;
  }

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.fontColor};
    border-color: ${({ theme }) => theme.colors.purple.main};
  }

  &.ant-btn-primary:not(.ant-btn-dangerous) {
    border: none;
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.green.main};
    text-shadow: none;
    &:hover {
      opacity: 0.8;
    }
    &[disabled] {
      background: ${({ theme }) => theme.colors.disabled.background};
      box-shadow: none;
      color: ${({ theme }) => theme.colors.disabled.color};
    }
  }

  &&.ant-btn-ghost {
    color: ${({ theme }) => theme.colors.purple.main};
    background: transparent;
    border: 2px solid ${({ theme }) => theme.colors.purple.main};
    box-shadow: none;
  }

  &&.ant-btn-dangerous {
    color: ${({ theme }) => theme.colors.white};
    border-color: transparent;
    background: ${({ theme }) => theme.colors.red.main};
    &:hover {
      0.25px 0 0 ${({ theme }) => theme.colors.black},
      -0.25px 0 0 ${({ theme }) => theme.colors.black},
      0 0.25px 0 ${({ theme }) => theme.colors.black},
      0 -0.25px 0 ${({ theme }) => theme.colors.black};
    }
  }

  &.ant-btn-link {
    color: ${({ theme }) => theme.colors.purple.main};
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    text-decoration: underline;
  }

  &.ant-btn-text {
    color: ${({ theme }) => theme.colors.purple.main};
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }

  &&.ant-btn-lg {
    font-size: ${({ theme }) => theme.fontSize.textL};
    padding: 14px 40px;
    .anticon {
      font-size: 16px;
    }
  }

  &&.ant-btn-sm {
    font-size: ${({ theme }) => theme.fontSize.textS};
    padding: 5px 10px;
    .anticon {
      font-size: 13px;
    }
  }

  &&.ant-btn-link {
    border-color: transparent;
    box-shadow: none;

    &.ant-btn-lg {
      box-shadow: none;
      font-size: 16px;
      padding: 15px 24px;
    }
  }

  ${({ $square }) =>
    $square &&
    `
        width: 48px !important;
        height: 48px !important;
      `}

  ${({ theme, $selected }) =>
    $selected &&
    `
      &&& {
        background: ${theme.colors.purple.pressed};
        color: ${theme.colors.fontColor};
      }
      `}
`

export const AppButton: React.FC<AppButtonProps> = (props) => {
  const [clicked, setClicked] = useState(false)
  useEffect(() => {
    return () => setClicked(false)
  }, [])

  const handleClicked = (funcProps: any) => {
    if (funcProps.onClick) funcProps.onClick()
    setClicked(true)
    setTimeout(() => {
      setClicked(false)
    }, 500)
  }
  return (
    <StyledButton
      {...props}
      onClick={() => handleClicked(props)}
      $clicked={clicked}
    />
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { postVerifyEmailService } from 'src/services/Account/postVerifyEmailService'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type postVerifyEmailArgs = {
  payload: {
    token: string
  }
  onSuccess?: OnSuccess
  onError?: OnError
}

export const postVerifyEmail = createAsyncThunk<
  Account,
  postVerifyEmailArgs,
  { rejectValue: ApiError }
>(
  'account/verifyEmail',
  async ({ payload, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await postVerifyEmailService({ payload }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import { createPostService } from 'src/utils/services'

type SendVerificationEmailArgs = {
  payload: {
    token: string
  }
}

export const postSendVerificationEmailService = createPostService<
  SendVerificationEmailArgs,
  undefined
>({
  urlCreator: () => '/auth/verify-email-send',
})

import styled from 'styled-components'

export const TagsManagerWrapper = styled.div`
  display: flex;
  place-items: flex-start;
  flex-direction: column;
  gap: 10px;
`

export const TagsWrapper = styled.div`
  max-width: 100%;
  display: flex;
  place-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
`

export const TagWrapper = styled.div`
  display: flex;
  place-items: flex-start;
  gap: 10px;
  background: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 15px;
  padding: 0;
  width: fit-content;
`

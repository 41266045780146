export const Entry = {
  SignInTitle: 'Zaloguj się',
  SignUpStep1Title: 'Zarejestruj się',
  SignUpStep2Title: 'Powiedz nam coś o sobie',
  DoYouHaveAnAccount: 'Masz już konto?',
  EmailInputText: 'E-mail',
  ContinueButtonText: 'Kontynuuj',
  NameInputText: 'Imię',
  LastNameInputText: 'Nazwisko',
  UsernameInputText: 'Nazwa użytkownika',
  PasswordInputText: 'Hasło',
  ConfirmPasswordInputText: 'Potwierdź hasło',
  SignUpSubmissionButtonText: 'Zarejestruj się',
  GoBackButtonText: 'Wróć',
  DontHaveAnAccount: 'Nie masz konta?',
  LoginSuccess: 'Logowanie powiodło się!',
  RegisterSuccess: 'Twoje konto zostało utworzone!',
  Success: 'Sukces!',
  YourEmailIsVerified:
    'Twój adres e-mail został zweryfikowany! Naciśnij na przycisk poniżej, aby zalogować się w aplikacji.',
  EmailVerificationFailed:
    'Coś poszło nie tak! Naciśnij przycisk poniżej, aby się zalogować',
  ResendEmail: 'Wyślij ponownie',
  Close: 'Zamknij',
  YourEmailIsNotVerified: 'Twój e-mail nie został potwierdzony.',
  VerifyYourEmailByClicking:
    'Potwierdź swój e-mail klikając w link przesłany na Twojego e-maila.',
  DontSeeAnEmail: 'E-mail nie dotarł?',
  VerifyYourEmail: 'Potwierdź swój adres e-mail',
  WeSendYouAnEmailToVerify:
    'Na podany przez Ciebie email wysłaliśmy wiadomość z linkiem do weryfikacji. Kliknij w niego aby ukończyć rejestrację!',
  ResetPassword: 'Zresetuj hasło!',
  EnterYourEmailAddressAndWeWillSendYouALink:
    'Podaj swój adres email, a my wyślemy link, który umożliwi zresetowanie Twojego hasła.',
  SendMeAnEmail: 'Wyślij link!',
  WeSendYouAnEmailWithLinkToResetYourPassword:
    'Wysłaliśmy Ci e-mail z linkiem do zresetowania hasła!',
  EnterNewPasswordForYourAccount:
    'Podaj i powtórz nowe hasło do Twojego konta!',
  ResetPasswordSucceed:
    'Twoje hasło zostało zmienione! Naciśnij przycisk poniżej, aby zalogować się do konta przy użyciu nowego hasła!',
  Save: 'Zapisz',
  Cancel: 'Anuluj',
  UsernameValidation:
    "Nazwa użytkownika powinna zawierać tylko małe litery, cyfry 0-9 oraz znaki '-','_','.'.",
  NotValidUrl: 'Wprowadzony link nie jest poprawny.',
  UsernameIsTaken: 'Nazwa użytkownika jest zajęta.',
  TextIsTooLong: 'Wprowadzony tekst jest za długi.',
  ForgotYourPassword: 'Nie pamiętasz hasła?',
  Now: 'Teraz',
  IHaveReadAndAgree: 'Potwierdzam, że przeczytałem i zgadzam się z zapisami ',
  TermsAndCondions: 'Regulaminu',
  And: ' i ',
  PrivacyPolicy: 'Polityki Prywatności',
  WeHaveSentYouAnEmail: 'E-mail weryfikacyjny został wysłany ponownie.',
  InCaseOfEmailNotReceived:
    'Jeśli e-mail nie dotarł w ciągu 5 do 10 minut. Zaloguj się na naszej platformie i poproś o ponowne przesłanie e-maila poprzez kliknięcie w przycisk "Wyślij ponownie".',
}

import { Statistic } from 'antd'
import styled from 'styled-components'

export const SingleEventHeaderPriceTag = styled(Statistic)<{ $free?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.textM};
  text-transform: uppercase;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-bottom: 15px;
  gap: 3px;
  ${({theme, $free}) => $free && `
    background-color: ${theme.colors.green.main};
    color: ${theme.colors.black} !important;
    padding: 2px 5px;
    border-radius: 5px;
  `}
  .ant-statistic-content {
    ${({$free}) => $free ?`
    height:  '25px' ;
    display: grid;
    place-items: center;
    overflow:hidden;`: 'height: 30px;'}
  }
  .ant-statistic-title {
    color: ${({ theme }) => theme.colors.fontColor.primary} !important;
    text-transform: capitalize;
    margin-bottom: 0;
    height: 18px;
  }
  .ant-statistic-content-value {
    color: ${({ $free, theme }) =>
      $free ? theme.colors.black : theme.colors.red.main} !important;
    font-family: ${({ theme }) => theme.fontFamily.primary} !important;
    font-size: ${({ theme }) => theme.fontSize.textL};
    line-height: 30px;
  }
  .ant-statistic-content-suffix,
  .ant-statistic-content-value-decimal {
    color: ${({ $free, theme }) =>
      $free ? theme.colors.black : theme.colors.red.main} !important;
    font-family: ${({ theme }) => theme.fontFamily.primary} !important;
    line-height: 30px;
    font-size: ${({ theme }) => theme.fontSize.textS};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    .ant-statistic-content-value {
      font-size: ${({ theme }) => theme.fontSize.heading4};
    }
  }
`

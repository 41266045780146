import { Image } from 'src/types'
import {
  SingleEventImgContainer,
  SingleEventImg,
  SingleEventImgBox,
} from './styles'

type SingleEventImageProps = {
  image: string
  title: string
}

export const SingleEventImage: React.FC<SingleEventImageProps> = ({
  image,
  title,
}) => {
  return (
    <SingleEventImgContainer>
      <SingleEventImgBox>
        <SingleEventImg src={image} alt={title} />
      </SingleEventImgBox>
    </SingleEventImgContainer>
  )
}

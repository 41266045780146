import { createGetService } from 'src/utils/services'
import { EventsFeed } from 'src/types'
type GetEventsFeedServiceArgs = {
  query?: string
}

export const getEventsFeedService = createGetService<
  GetEventsFeedServiceArgs,
  EventsFeed
>({
  urlCreator: ({ query }) => `/events/feed/?${query || ''}`,
})

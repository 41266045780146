import { useEffect, useMemo, useState } from 'react'
import { AppTag, SmallHeader } from 'src/components'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import {
  categoriesTagsSelector,
  getTagsAction,
  modesTagsSelector,
} from 'src/store'
import { TagsManagerWrapper, TagsWrapper } from './TagsManager.styles'
import { TagEditForm } from './TagEditForm/TagEditForm'
import { TagOption } from './TagOption'
import { Tag, TagPayload } from 'src/types'
import { notification } from 'antd'
import { postTagAction } from 'src/store/Tags/actions/postTagAction'

export const TagsManager = () => {
  const dispatch = useAppDispatch()
  const [activeCatId, setActiveCatId] = useState<string>()
  const [activeTag, setActiveTag] = useState<string>()
  const [activeMode, setActiveMode] = useState<string>()

  const isPostLoading = useAppSelector((state) => state.tags.all.post.isLoading)

  const categories = useAppSelector(categoriesTagsSelector)
  const modes = useAppSelector(modesTagsSelector)

  useEffect(() => {
    dispatch(getTagsAction({}))
  }, [])

  useEffect(() => {
    if (!!categories[0] && !activeCatId) {
      setActiveCatId(categories[0]._id)
    }
  }, [categories])

  const activeCategory = useMemo(() => {
    const activeCat = categories.find((cat) => cat._id === activeCatId)

    return activeCat
  }, [activeCatId, categories])

  const handleTagPost = (values: TagPayload) => {
    const { parent, type, name, key } = values
    dispatch(
      postTagAction({
        payload: {
          parent: parent,
          type: type,
          name: name,
          key: key,
        },
        onSuccess: () => {
          dispatch(getTagsAction({}))
        },
        onError: (error: any) => {
          notification.error({
            message: error.message,
          })
        },
      })
    )
  }

  return (
    <TagsManagerWrapper>
      <SmallHeader>Kategorie</SmallHeader>
      <TagsWrapper>
        {categories.map((category) => (
          <TagOption
            key={category._id}
            tagProps={{
              type: 'category',
              isActive: activeCatId === category._id,
              name: category.name,
              onClick: () => {
                setActiveCatId(category._id)
              },
            }}
            tag={category}
          />
        ))}
        <TagEditForm
          type="category"
          onSubmit={handleTagPost}
          loading={isPostLoading}
        />
      </TagsWrapper>
      {activeCategory && (
        <>
          <SmallHeader>Tagi dla kategorii: {activeCategory?.name}</SmallHeader>
          <TagsWrapper>
            {activeCategory.children.map((category) => (
              <TagOption
                key={category._id}
                tagProps={{
                  type: 'tag',
                  name: category.name,
                  isActive: activeTag === category._id,
                  onClick: () => {
                    setActiveTag(category._id)
                  },
                }}
                tag={category}
                parent={activeCategory._id}
              />
            ))}
            <TagEditForm
              type="tag"
              parent={activeCategory._id}
              onSubmit={handleTagPost}
              loading={isPostLoading}
            />
          </TagsWrapper>
        </>
      )}
      <SmallHeader>Tryb</SmallHeader>
      <TagsWrapper>
        {modes.map((mode) => (
          <TagOption
            key={mode._id}
            tagProps={{
              type: 'mode',
              name: mode.name,
              isActive: activeMode === mode._id,
              onClick: () => {
                setActiveMode(mode._id)
              },
            }}
            tag={mode}
          />
        ))}
        <TagEditForm
          type="mode"
          onSubmit={handleTagPost}
          loading={isPostLoading}
        />
      </TagsWrapper>
    </TagsManagerWrapper>
  )
}

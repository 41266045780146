import { Form, Formik } from 'formik'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppButton } from 'src/components/AppComponents'
import { useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { getOneEventByCleanIdAction } from 'src/store'
import {
  EventPatch,
  patchOneEventByIdAction,
} from 'src/store/Events/actions/patchOneEventByIdAction'
import { Event, Term } from 'src/types'
import { editSingleEventValidationSchema } from './editSingleEventValidationSchema'
import { Details } from './forms/Details'
import { EventAdditionalOptions } from './forms/EventAdditionalOptions'
import { EventCTAButton } from './forms/EventCTAButton'
import { EventImage } from './forms/EventImage'
import { EventPrice } from './forms/EventPrice'
import { EventTerms } from './forms/EventTerms'
import { StyledForm } from './styles/EditSingleEventForm.styles'
import { handleFormikErrors } from 'src/utils'

type EditSingleEventFormProps = {
  event: Event
}

export type EditEvent = {
  title: string
  description: string
  image: string | null
  terms: Term[]
  cta: {
    type: 'youtube' | 'facebook' | 'sign_up' | 'see_more' | 'other' | 'website'
    link: string
  }
  privacy: 'public' | 'private' | 'link'
  mode: string
  free: boolean
  price: number
  categories: string[]
}

const SUPPORTED_TAG_TYPES = ['mode', 'tag']

export const EditSingleEventForm: React.FC<EditSingleEventFormProps> = ({
  event,
}) => {
  const navigate = useNavigate()
  const [imgUrl, setImgUrl] = useState<string | null>(null)
  const [isImgChanged, setIsImgChanged] = useState(false)

  useEffect(() => {
    if (event.image?.light_url) {
      setImgUrl(event?.image.light_url)
    }
  }, [event])

  const handleImageUrlChange = (value: string | null) => {
    setImgUrl(value)
    setIsImgChanged(true)
  }

  const dispatch = useAppDispatch()

  const initialTags =
    event.tags.filter((tag) => tag.type === 'tag').map(({ _id }) => _id) || []

  const initialModes =
    event.tags.filter((tag) => tag.type === 'mode').map(({ _id }) => _id) || []

  const restOfTags =
    event.tags
      .filter((tag) => !SUPPORTED_TAG_TYPES.includes(tag.type))
      .map(({ _id }) => _id) || []

  const initialValues: EditEvent = {
    title: event.title,
    description: event.description,
    image: event.image === null ? null : event.image?.light_url,
    terms: event.terms,
    cta: event.cta,
    privacy: event.privacy,
    mode: initialModes[0] ?? undefined,
    free: event.free,
    price: event.price,
    categories: initialTags,
  }

  return (
    <Formik
      onSubmit={(values: EditEvent) => {
        const eventValues = Object.values(initialValues)
        const valEntries = Object.entries(values)

        const changedValues = valEntries.filter(
          (item) => !eventValues.includes(item[1])
        )
        //TERMS
        if (!isEqual(initialValues.terms[0], values.terms[0])) {
          const index = changedValues.findIndex((item) => item[0] === 'terms')

          const updatedTerms = {
            ...(values.terms[0] as Term),
            updated: true,
          }
          changedValues[index] = ['terms', [updatedTerms]]
        }

        //@ts-ignore
        const updatedValues = Object.fromEntries(changedValues) as EditEvent

        const { categories, mode, image, ...eventsPatchData } = updatedValues

        const payloadImage =
          event?.image?.light_url !== imgUrl && isImgChanged
            ? { image: imgUrl }
            : {}

        const nestMode = values.mode ? [values.mode] : []

        const tagsCombined = [...values.categories, ...restOfTags, ...nestMode]

        dispatch(
          patchOneEventByIdAction({
            eventId: event._id,
            payload: {
              ...eventsPatchData,
              ...payloadImage,
              tags: tagsCombined,
            },
            onSuccess: () => {
              dispatch(
                getOneEventByCleanIdAction({
                  eventId: event.eventId,
                  onSuccess: () => {
                    navigate(`/events/${event.eventId}`)
                  },
                })
              )
            },
          })
        )
      }}
      initialValues={initialValues}
      validationSchema={editSingleEventValidationSchema}
    >
      {({ values, errors, submitForm, validateForm }) => (
        <StyledForm>
          <Form>
            <EventImage imgUrl={imgUrl} setImgUrl={handleImageUrlChange} />
            <Details errors={errors} />
            <EventTerms terms={event.terms} />
            <EventCTAButton values={values} errors={errors} />
            <EventPrice free={values.free} />
            <EventAdditionalOptions eventId={event._id} />
            <AppButton
              onClick={async () => {
                validateForm()
                handleFormikErrors(await validateForm())
                submitForm()
              }}
            >
              {t('SaveAndGoToEvent')}
            </AppButton>
          </Form>
        </StyledForm>
      )}
    </Formik>
  )
}

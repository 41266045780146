import { Drawer } from 'antd'
import styled from 'styled-components/macro'

export const AppDrawer = styled(Drawer)`
  position: absolute;

  .ant-drawer-content {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    border: ${({ theme }) => theme.border.width.default} solid
      ${({ theme }) => theme.colors.borderColor};
    border-top-left-radius: ${({ theme }) => theme.border.radiusXL};
    border-top-right-radius: ${({ theme }) => theme.border.radiusXL};
  }
`

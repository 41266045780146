import {
  AppInput,
  AppInputPassword,
  AppSwitch,
  VerticalInputContainer,
} from '../../../components'
import { t } from '../../../locale'
import { useSignUpForm } from '../useSignUpForm'
import {
  InputContainer,
  GoBackButton,
  SignUpButton,
  RulesCheckbox,
} from './index'
import { handleFormikErrors } from 'src/utils'

type TellUsMoreStepProps = {
  handlePreviousStep: () => void
}

export const TellUsMoreStep: React.FC<TellUsMoreStepProps> = ({
  handlePreviousStep,
}) => {
  const context = useSignUpForm()

  return (
    <>
      <h3>{t('SignUpStep2Title')}</h3>
      <InputContainer>
        <VerticalInputContainer>
          <AppInput
            name="firstName"
            placeholder="Jan"
            label={t('NameInputText')}
            required
            touched={context.touched.firstName}
            error={context.errors.firstName}
          />
        </VerticalInputContainer>
        <VerticalInputContainer>
          <AppInput
            name="lastName"
            placeholder="Kowalski"
            label={t('LastNameInputText')}
            required
            touched={context.touched.lastName}
            error={context.errors.lastName}
          />
        </VerticalInputContainer>
      </InputContainer>
      <VerticalInputContainer>
        <AppInput
          name="username"
          placeholder="jan.kowalski_23"
          label={t('UsernameInputText')}
          required
          error={context.errors.username}
          touched={context.touched.username}
        />
      </VerticalInputContainer>
      <VerticalInputContainer>
        <AppInputPassword
          name="password"
          label={t('PasswordInputText')}
          placeholder="*************"
          required
          touched={context.touched.password}
        />
      </VerticalInputContainer>
      <VerticalInputContainer>
        <AppInputPassword
          name="confirmPassword"
          label={t('ConfirmPasswordInputText')}
          placeholder="*************"
          required
          touched={context.touched.confirmPassword}
        />
      </VerticalInputContainer>
      <VerticalInputContainer></VerticalInputContainer>
      <RulesCheckbox value={context.values.rulesAreAccepted}>
        <AppSwitch
          name="rulesAreAccepted"
          // onChange={() => setRulAreAcce(!rulesAreAccepted)}
        />
        <div>
          {t('IHaveReadAndAgree')}
          <a
            target={'_blank'}
            href="https://docs.google.com/document/d/1vrx4U2EyFt5zeTG89WSihIXtt701SU4aO4ikhydgZ4k/edit?usp=sharing"
          >
            {t('TermsAndCondions')}
          </a>
          {t('And')}
          <a
            target={'_blank'}
            href="https://docs.google.com/document/d/169hvuTt6EQZv5pM21kxiwCWr3ZKdQf01aFPaoqKTrqc/edit?usp=sharing"
          >
            {t('PrivacyPolicy')}
          </a>
        </div>
      </RulesCheckbox>
      <SignUpButton
        disabled={context.isValid && !context.dirty}
        onClick={async () => {
          context.validateForm()
          handleFormikErrors(await context.validateForm())
          context.submitForm()
        }}
      >
        {t('SignUpSubmissionButtonText')}
      </SignUpButton>
      <GoBackButton type="ghost" onClick={handlePreviousStep}>
        {t('GoBackButtonText')}
      </GoBackButton>
    </>
  )
}

import { ReactElement } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { lightTheme, darkTheme } from '../themes'

export const ThemeMode: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const activeTheme = localStorage.getItem('theme')
  const currentMode = activeTheme === 'light' ? lightTheme : darkTheme
  return <ThemeProvider theme={currentMode}>{children}</ThemeProvider>
}

import { t } from 'src/locale'
import * as Yup from 'yup'

export const editAccountSchema = Yup.object().shape({
  name: Yup.string().required(t('required')),
  username: Yup.string()
    .min(4, t('tooShort4Chars'))
    .max(32, t('tooLongUsername'))
    .matches(/^[a-z0-9_.-]*$/, t('UsernameValidation'))
    .required(t('required')),
  bio: Yup.object({
    link: Yup.string().matches(
      /^(?:(http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/,
      t('NotValidUrl')
    ),
    text: Yup.string().max(150, t('TextIsTooLong')),
  }),
})

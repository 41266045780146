import { MdWarning } from 'react-icons/md'
import { AppCollapse } from 'src/components/AppComponents/AppCollapse'
import styled from 'styled-components'

export const NotificationHeaderBox = styled.div`
  background: ${({ theme }) => theme.colors.background.secondary} !important;

  color: ${({ theme }) => theme.colors.fontColor.primary};
  width: 100%;
  display: flex;
  justify-content: center;
  place-items: center;
  z-index: 1009;
`

export const NotificationTitle = styled.div`
  display: flex;
  justify-content: space-between;
  place-items: center;
  max-width: 800px;
  width: 100%;
  padding: 7px 0;
  flex-flow: column nowrap;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    flex-flow: row nowrap;
  }
`

export const Title = styled.div`
  display: flex;
  place-items: center;
  gap: 10px;
`

export const Button = styled.div`
  display: flex;
  place-items: center;
  gap: 10px;
`

export const Progress = styled.div`
  width: 125px;
`

export const WarningIcon = styled(MdWarning)`
  color: ${({ theme }) => theme.colors.red.main};
`

import React from 'react'
import { Account } from 'src/types'
import { StyledImage } from '../styles'

type NonEditableProfilePictureProps = {
  account?: Partial<Account>
}

export const NonEditableProfilePicture: React.FC<
  NonEditableProfilePictureProps
> = ({ account }) => {
  return (
    <StyledImage
      src={
        account?.avatar?.light_url
          ? account?.avatar?.light_url
          : `/${localStorage.getItem('theme') === 'light' ? 'light' : 'dark'}-temporary.webp`
      }
      className="image"
    />
  )
}

import { Skeleton } from 'antd'
import styled from 'styled-components'

export const EventSkeleton = styled(Skeleton.Button)`
  width: 100%;
  max-width: 800px;
  .ant-skeleton-button {
    width: 100% !important;
    background: ${({ theme }) => theme.colors.background.primary};
  }
  min-height: 120px;
`

export const EventSkeletonTitle = styled(Skeleton.Input)`
  width: 100%;
  max-width: 800px;
  .ant-skeleton-input {
    width: 200px;
    background: ${({ theme }) => theme.colors.background.primary};
  }
`

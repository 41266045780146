import { createAsyncThunk } from '@reduxjs/toolkit'
import { registerService } from 'src/services/Account/registerService'
import {
  OnError,
  OnSuccess,
  RegisterResp,
  RegisterData,
  ApiError,
} from 'src/types'

type RegisterArgs = {
  payload: RegisterData
  onSuccess?: (values: RegisterResp) => void
  onError?: OnError
}

export const register = createAsyncThunk<
  RegisterResp,
  RegisterArgs,
  { rejectValue: ApiError }
>(
  'account/register',
  async ({ payload, onError, onSuccess }, { rejectWithValue, signal }) => {
    try {
      const result = await registerService({ payload }, { signal })
      onSuccess?.(result)
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import { useActiveAccount, useScreen } from 'src/hooks'
import { HeaderContainer } from '../styles/HeaderContainer'
import { AppHeaderProfileButton } from '.'
import { Navbar } from 'src/components/Navbar'
import { AppHeaderProfileContainer } from './styles/AppHeaderProfileContainer'
import { BurgerMenu } from './styles'
import { HeaderBox, HeaderLogoAndFlags, HeaderLogoContainer } from '../styles'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { AppHeaderAddButton } from './AppHeaderAddButton'
import { Sidebar } from 'src/components/Navbar/Sidebar'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { ShortLogo } from 'src/components/Logo'
import { useNavigate } from 'react-router-dom'
import { LogoAndLanguageContainer } from '../styles/HeaderLanguageChange.styles'

export const AppHeader = () => {
  const navigate = useNavigate()
  const { isPc, isBigger } = useScreen()
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const activeAccount = useActiveAccount()

  return (
    <HeaderBox>
      <HeaderContainer>
        <LogoAndLanguageContainer>
          <HeaderLogoContainer onClick={() => navigate('/')}>
            <ShortLogo />
          </HeaderLogoContainer>
          <HeaderLogoAndFlags />
        </LogoAndLanguageContainer>
        {!isPc && <Navbar />}
        <AppHeaderProfileContainer>
          {!isPc && <AppHeaderAddButton />}
          <AppHeaderProfileButton
            firstName={activeAccount?.firstName}
            avatar={activeAccount?.avatar}
            username={activeAccount?.username}
          />
          <BurgerMenu onClick={() => setOpenSidebar(!openSidebar)}>
            {openSidebar ? (
              <AiOutlineClose size={25} />
            ) : (
              <AiOutlineMenu size={25} />
            )}
          </BurgerMenu>
        </AppHeaderProfileContainer>
        <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
      </HeaderContainer>
    </HeaderBox>
  )
}

import { createGetService } from 'src/utils/services'

type getCheckIfAccountEmailIsTakenServiceArgs = {
  email: string
}

type response = {
  isEmailTaken: boolean
}

export const getCheckIfAccountEmailIsTakenService = createGetService<
  getCheckIfAccountEmailIsTakenServiceArgs,
  response
>({
  urlCreator: ({ email }) => `/accounts/free-email/${email}`,
})

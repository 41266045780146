import styled from 'styled-components'

export const SingleEventContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  padding-bottom: 20px;
`

export const Space = styled.div`
  height: 40px;
`

import { Tag } from 'src/types'
import { TagPayload } from 'src/types'
import { createPatchService } from 'src/utils/services'

type PatchTagServiceResp = Tag
type PatchTagServiceArgs = {
  payload: Partial<TagPayload>
  tagId: string
}

export const patchTagService = createPatchService<
  PatchTagServiceArgs,
  PatchTagServiceResp
>({
  urlCreator: ({ tagId }) => `/tags/${tagId}/`,
})

import { AppButton } from 'src/components/AppComponents'
import styled from 'styled-components'

export const InputContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 20px;
`

export const GoBackButton = styled(AppButton)`
  border-color: transparent !important;
  margin-top: -4%;
`

export const SignUpButton = styled(AppButton)`
  width: 40%;
  margin-left: 30%;
`
export const RulesCheckbox = styled.div<{ value?: boolean }>`
  display: flex;
  place-items: center;
  gap: 10px;
  ${({ value, theme }) =>
    value
      ? `color: ${theme.colors.fontColor.primary};`
      : `color: ${theme.colors.fontColor.secondary};`}
`

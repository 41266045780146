import { createAsyncThunk } from '@reduxjs/toolkit'
import { patchTagService } from 'src/services/Tags'
import { ApiError, OnError, OnSuccess, Tag, TagPayload } from 'src/types'

type TagsArgs = {
  payload: Partial<TagPayload>
  tagId: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const patchTagAction = createAsyncThunk<
  Tag,
  TagsArgs,
  { rejectValue: ApiError }
>(
  'tags/patch',
  async (
    { payload, tagId, onSuccess, onError },
    { rejectWithValue, signal }
  ) => {
    try {
      const result = await patchTagService({ tagId, payload }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data)
    }
  }
)

import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

export const NavbarItemContentContainer = styled(NavLink)<{
  $disabled?: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.fontColor.primary};
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.purple.main};
  }
  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.3;
  `}
  cursor: ${({ $disabled }) => ($disabled ? `not-allowed` : 'pointer')};

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    flex-direction: row;
    font-size: 16px;
    gap: 5px;
  }
`

import { InputNumber, InputNumberProps } from 'formik-antd'
import styled from 'styled-components'

export const AppInputNumber = styled(InputNumber)<
  InputNumberProps & { $transparent?: boolean; $bordered?: boolean }
>`
  width: 100%;
  ${({ $transparent, theme, readOnly }) =>
    !$transparent &&
    `background-color: ${
      !readOnly
        ? theme.colors.background.tertiary
        : theme.colors.background.secondary
    };`}
  border: 1px solid ${({ theme }) => theme.colors.purple.main};
  font-size: ${({ theme }) => theme.fontSize.textXM} !important;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  border-radius: ${({ theme }) => theme.border.radius};
  transition: 0.3s ease-out;
  color: ${({ theme }) => theme.colors.fontColor.primary} !important;
  .ant-input {
    background-color: ${({ theme }) => theme.colors.background.tertiary};
    color: ${({ theme }) => theme.colors.fontColor.primary} !important;
    font-size: ${({ theme }) => theme.fontSize.textXM} !important;
    padding-top: 10px;
    padding-left: 24px;
    &::placeholder {
      color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
  }

  ${({ readOnly }) =>
    readOnly &&
    `
  .ant-input-focused,
  &:focus,
  &:hover,
  &:active {
    box-shadow: none !important;
    outline: none;
  }
  `}

  &:focus,
  &:hover,
  &:active {
    &: not([disabled]) {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.purple.main}14;
      border: 1px solid ${({ theme }) => theme.colors.purple.main};
      background-color: ${({ theme }) => theme.colors.background.tertiary};
    }
  }

  &[disabled] {
    color: ${({ theme }) => theme.colors.fontColor.primary};
    background: ${({ theme }) => theme.colors.background.secondary};
  }

  &[readonly]:hover {
    cursor: auto;
  }

  &[disabled]:hover,
  &[readonly]:hover {
    border: none;
  }
  .ant-input-number-handler-wrap {
    top: -2px;
    .ant-input-number-handler {
      border: none;
    }
    .ant-input-number-handler:hover {
      border: none;
    }
    .ant-input-number-handler-up {
      border-bottom: none;
      outline: none;
      box-shadow: none;
    }
    .ant-input-number-handler-down,
    .ant-input-number-handler-down:hover {
      border-top: none;
    }
    background: transparent;
    border: none;
    .anticon {
      color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
    }
  }
`

import { t } from 'src/locale'
import { SingleEventHeaderDatesContainer } from './styles'

type SingleEventHeaderDateProps = {
  from: string
  to: string
}

const weekdays = {
  day0: t('sunday'),
  day1: t('monday'),
  day2: t('tuesday'),
  day3: t('wednesday'),
  day4: t('thursday'),
  day5: t('friday'),
  day6: t('saturday'),
}

export const SingleEventHeaderDate: React.FC<SingleEventHeaderDateProps> = ({
  from,
  to,
}) => {
  //CURRENT DATE
  const DateNow = Date.now()
  const currentDay = `${new Date(from).getDate() < 10 ? '0' : ''}${new Date(
    from
  ).getDate()}`
  const currentDayName = Object.values(weekdays)[new Date(from).getDay()]
  const currentMonth = `${new Date(from).getMonth() + 1 < 10 ? '0' : ''}${
    new Date(from).getMonth() + 1
  }`
  const currentYear = new Date(from).getFullYear()

  //FROM
  const fromDay = `${new Date(from).getDate() < 10 ? '0' : ''}${new Date(
    from
  ).getDate()}`
  const fromDayName = Object.values(weekdays)[new Date(from).getDay()]
  const fromMonth = `${new Date(from).getMonth() + 1 < 10 ? '0' : ''}${
    new Date(from).getMonth() + 1
  }`
  const fromYear = new Date(from).getFullYear()
  const fromHour = `${new Date(from).getHours() < 10 ? '0' : ''}${new Date(
    from
  ).getHours()}`
  const fromMinutes = `${new Date(from).getMinutes() < 10 ? '0' : ''}${new Date(
    from
  ).getMinutes()}`
  const fromDate = `${fromDay}.${fromMonth}.${fromYear} (${fromDayName})`
  const fromTime = `${fromHour}:${fromMinutes}`
  const fromDateFullDate = `${fromDate} ${fromTime}`

  //TO
  const toDay = `${new Date(to).getDate() < 10 ? '0' : ''}${new Date(
    to
  ).getDate()}`
  const toDayName = Object.values(weekdays)[new Date(to).getDay()]
  const toMonth = `${new Date(to).getMonth() + 1 < 10 ? '0' : ''}${
    new Date(to).getMonth() + 1
  }`
  const toYear = new Date(to).getFullYear()
  const toHour = `${new Date(to).getHours() < 10 ? '0' : ''}${new Date(
    to
  ).getHours()}`
  const toMinutes = `${new Date(to).getMinutes() < 10 ? '0' : ''}${new Date(
    to
  ).getMinutes()}`
  const toDate = `${toDay}.${toMonth}.${toYear} (${toDayName})`
  const toTime = `${toHour}:${toMinutes}`
  const toDateFullDate = `${toDate} ${toTime}`

  const displayDate =
    fromDate === toDate
      ? `${fromDate} ${fromTime} - ${toTime}`
      : `${fromDateFullDate} - ${toDateFullDate}`

  return (
    <SingleEventHeaderDatesContainer>
      {displayDate}
    </SingleEventHeaderDatesContainer>
  )
}

import { useEffect, useState } from 'react'
import { Account } from 'src/types'
import { useTheme } from 'styled-components'
import { ProfileDetailsContent } from './ProfileDetailsContent'
import { ProfileDetailsContainer, ProfileName, ProfileUsername } from './styles'
import { Username } from '../Username'

type ProfileDetailsProps = {
  account?: Partial<Account>
}

export const ProfileDetails: React.FC<ProfileDetailsProps> = ({ account }) => {
  const [screenWidth, setScreenWidth] = useState<number>(0)
  const theme = useTheme()

  useEffect(() => {
    setScreenWidth(window.screen.width)
    window.addEventListener('resize', () => {
      setScreenWidth(window.screen.width)
    })
  }, [])

  const isTablet = screenWidth >= theme.mediaBreakpoints.numbers.tablet

  return (
    <ProfileDetailsContainer>
      <ProfileUsername>
        <h2>
          <Username
            isUserVerified={account?.isUserVerified}
            roles={account?.roles}
            username={account?.username || ''}
          />
        </h2>
        {!isTablet && <ProfileName>{account?.name}</ProfileName>}
      </ProfileUsername>
      {screenWidth && screenWidth > theme.mediaBreakpoints.numbers.mobile && (
        <ProfileDetailsContent screen={screenWidth} account={account} />
      )}
    </ProfileDetailsContainer>
  )
}

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { SliceStatus, RootState, Event } from 'src/types'
import { searchAllEventsAction } from './actions'

type eventsListSliceSliceData = {
  status: SliceStatus
  total: number
}

export const eventsListAdapter = createEntityAdapter<Event>({
  selectId: ({ _id }) => _id,
})

export const eventsListSlice = createSlice({
  name: 'eventsListSlice',
  initialState: eventsListAdapter.getInitialState<eventsListSliceSliceData>({
    status: {
      isLoading: false,
      error: null,
    },
    total: 0,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchAllEventsAction.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(searchAllEventsAction.fulfilled, (state, action) => {
      eventsListAdapter.setAll(state, action.payload.data)
      state.status.isLoading = false
      state.status.error = null
      state.total = action.payload.total
    })
    builder.addCase(searchAllEventsAction.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
      state.total = 0
    })
  },
})

export const eventsListSelector = eventsListAdapter.getSelectors<RootState>(
  (state) => state.events.eventsList
)

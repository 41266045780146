import { Account } from 'src/types'
import { createPostService } from 'src/utils/services'

type postResendVerifyEmailServiceByAdminArgs = {
  payload: { email: string }
}

export const postResendVerifyEmailServiceByAdmin = createPostService<
  postResendVerifyEmailServiceByAdminArgs,
  Account
>({
  urlCreator: () => '/auth/verify-email-resend-admin',
})

import { AppButton } from 'src/components/AppComponents'
import styled from 'styled-components'

export const TopButtonsContainer = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding: 0;
    padding-bottom: 16px;
  }
`
export const EventButton = styled(AppButton)`
  width: fit-content !important;
  gap: 5px;
  padding: 5px 10px !important;
`

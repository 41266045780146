import { Modal } from 'antd'
import { AiOutlineEye } from 'react-icons/ai'
import { MdArrowBack, MdModeEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { AppModal, AppPopconfirm } from 'src/components/AppComponents'
import { t } from 'src/locale'
import { EventButton, TopButtonsContainer } from './styles'
const { confirm } = Modal
type TopButtonsProps = {
  eventId?: string
}

export const TopButtons: React.FC<TopButtonsProps> = ({ eventId }) => {
  const navigate = useNavigate()

  return (
    <TopButtonsContainer>
      <AppPopconfirm
        title={t('EventModalConfirmTitle')}
        okText={t('Yes')}
        cancelText={t('No')}
        onConfirm={() => navigate(-1)}
        okButtonProps={{ danger: true }}
      >
        <EventButton>
          <MdArrowBack /> {t('GoBackButtonText')}
        </EventButton>
      </AppPopconfirm>
    </TopButtonsContainer>
  )
}

import { useFormikContext } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { categoriesTagsSelector, getTagsAction } from 'src/store'
import { StyledBadge, TagsSelectorWrapper, TagsWrapper } from '../styles'
import { SmallHeader } from '../../Typography'
import { AppTag } from '../../AppComponents'
import { Tag } from '../../../types'

type TagsSelectorProps = {
  name: string
  onChange?: (tags: string[]) => void
}

export const CategoryTagsSelector = ({ name, onChange }: TagsSelectorProps) => {
  const dispatch = useAppDispatch()
  const { values, setFieldValue } = useFormikContext<{
    [record: string]: string[]
  }>()
  const [activeTags, setActiveTags] = useState<string[]>(values[name])
  const [activeCatId, setActiveCatId] = useState<string>()

  const categories = useAppSelector(categoriesTagsSelector)

  const activeCategory = useMemo(() => {
    const activeCat = categories.find((cat: Tag) => cat._id === activeCatId)

    return activeCat
  }, [activeCatId, categories])

  useEffect(() => {
    dispatch(getTagsAction({}))
    setFieldValue(name, values[name])
  }, [])

  useEffect(() => {
    if (!!categories[0] && !activeCatId) {
      setActiveCatId(categories[0]._id)
    }
  }, [categories])

  useEffect(() => {
    setFieldValue(name, activeTags)
  }, [activeTags])

  const onCategorySelect = (id: string) => {
    setActiveCatId(id)
  }

  const onTagSelect = (tagId: string) => {
    if (activeTags.includes(tagId)) {
      const filteredTags = activeTags.filter((tag) => tag !== tagId)
      setActiveTags(filteredTags)

      onChange?.(filteredTags)
    } else {
      setActiveTags([...activeTags, tagId])
      onChange?.([...activeTags, tagId])
    }
  }

  return (
    <TagsSelectorWrapper>
      <SmallHeader>Kategorie</SmallHeader>
      <TagsWrapper>
        {categories.map((category: Tag) => {
          const filteredTags = category.children.filter((cat) =>
            activeTags.includes(cat._id)
          )

          const countedActiveTags = filteredTags.length

          return (
            <StyledBadge
              key={category.key}
              count={countedActiveTags}
              overflowCount={99}
              offset={[0, 0]}
            >
              <AppTag
                type="category"
                isActive={activeCatId === category._id}
                name={category.name}
                onClick={() => onCategorySelect(category._id)}
              />
            </StyledBadge>
          )
        })}
      </TagsWrapper>
      {activeCategory && (
        <>
          <SmallHeader>Tagi dla kategorii: {activeCategory?.name}</SmallHeader>
          <TagsWrapper>
            {activeCategory.children.map((tag: Tag) => (
              <AppTag
                key={tag._id}
                type="tag"
                isActive={activeTags?.includes(tag._id)}
                name={tag.name}
                onClick={() => onTagSelect(tag._id)}
              />
            ))}
          </TagsWrapper>
        </>
      )}
    </TagsSelectorWrapper>
  )
}

import styled from 'styled-components'

export const DetailsContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.secondary};

  width: 100%;
  padding-right: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
    max-width: 200px;
    border-right: 1px solid ${({ theme }) => theme.colors.borderColor};

    height: 100%;
  }
`

export const DetailsItem = styled.div`
  display: flex;
  place-items: flex-start;
  gap: 2px;
  margin-top: 5px;
  flex-flow: column nowrap;
  font-size: ${({ theme }) => theme.fontSize.textM};
  span {
    font-size: ${({ theme }) => theme.fontSize.textS};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    display: block;
  }
  div {
    display: flex;
    place-items: center;
    gap: 10px;
  }
`

import { Modal } from 'antd'
import styled from 'styled-components/macro'

export const AppModal = styled(Modal)`
  .ant-modal-content {
    padding-bottom: 0;
    background-color: ${({ theme }) => theme.colors.background.primary};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.borderColor};
    border-radius: ${({ theme }) => theme.border.radiusL};
    color: ${({ theme }) => theme.colors.white};

    .ant-modal-header {
      padding: 15px 30px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
      background: ${({ theme }) => theme.colors.background.secondary};
      border-radius: ${({ theme }) => theme.border.radiusL}
        ${({ theme }) => theme.border.radiusL} 0 0;

      .ant-modal-title {
        color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSize.heading5};
        font-weight: ${({ theme }) => theme.fontWeight.bold};
      }
    }

    .ant-modal-body {
      padding: 30px;
    }

    .ant-modal-footer {
      border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
    }

    button.ant-modal-close {
      right: 7px;
      top: 5px;

      .anticon {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.info.main};
      }
    }
  }
`

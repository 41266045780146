import { Event, Tag } from 'src/types'
import { flatTagsArray } from 'src/utils/Tags/flatTagsArray'
import { EventCategoriesWrapper } from './styles'
import { EventTag } from '../components'

type EventCategoriesProps = {
  tags: Tag[]
}

export const EventCategories = ({ tags }: EventCategoriesProps) => {
  const flatArr = flatTagsArray(
    tags.filter((tag) => ['tag', 'category'].includes(tag.type)) || []
  )

  return (
    <EventCategoriesWrapper>
      {flatArr.map((tag) => (
        <EventTag
          key={tag.key}
          $isCategory={tag.type === 'category'}
          $clickable
        >
          #{tag.name}
        </EventTag>
      ))}
    </EventCategoriesWrapper>
  )
}

import { useNavigate, useParams } from 'react-router-dom'
import {
  AppHeaderProfileButtonAvatar,
  AppHeaderProfileButtonContainer,
  ProfileContainer,
} from './styles'
import { Image } from 'src/types/Image'

type AppHeaderProfileButtonProps = {
  avatar?: Image
  firstName?: string
  username?: string
}

export const AppHeaderProfileButton: React.FC<AppHeaderProfileButtonProps> = ({
  avatar,
  firstName,
  username,
}) => {
  const navigate = useNavigate()

  const { username: paramsUsername } = useParams()

  const isActive = paramsUsername === username

  return (
    <>
      <AppHeaderProfileButtonContainer $active={isActive}>
        <ProfileContainer onClick={() => navigate(`/@${username}`)}>
          <AppHeaderProfileButtonAvatar $active={isActive}>
            <img src={avatar?.light_url || `/${localStorage.getItem('theme') === 'light' ? 'light' : 'dark'}-temporary.webp`} alt={firstName} />
          </AppHeaderProfileButtonAvatar>
          {firstName}
        </ProfileContainer>
      </AppHeaderProfileButtonContainer>
    </>
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPatronsByQueryService } from 'src/services'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type GetAccountsArgs = {
  query?: string
  onSuccess?: OnSuccess
  onError?: OnError
}
type getPatronsByQueryActionResp = {
  total: number
  data: Account[]
}

export const getPatronsByQueryAction = createAsyncThunk<
  getPatronsByQueryActionResp,
  GetAccountsArgs,
  { rejectValue: ApiError }
>(
  'account/getPatronsByQueryAction',
  async ({ query, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const results = getPatronsByQueryService({ query }, { signal })
      onSuccess?.()
      return results
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import { MenuProps } from 'antd'
import { IoMdCopy } from 'react-icons/io'
import { AppDropdown } from '../AppDropdown'
import { MdShare } from 'react-icons/md'
import { t } from 'src/locale'
import { copyTextToClipboard } from 'src/utils'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
} from 'react-share'
import { EventButton } from 'src/components/Events/SingleEvent/SingleEventButtons/styles'

export const ShareLinksDropdown = () => {
  const localURL = window.location.href

  const items: MenuProps['items'] = [
    {
      key: 'copyLink',
      label: t('CopyLink'),
      icon: <IoMdCopy size={18} />,
      onClick: (e) => {
        copyTextToClipboard(localURL)
      },
    },
    {
      key: 'FacebookLink',
      label: (
        <FacebookShareButton
          url={localURL}
          title="MAPA WIEDZY"
          className="share-button"
        >
          Facebook
        </FacebookShareButton>
      ),
      icon: <FacebookIcon size={18} round />,
    },
    {
      key: 'TweeterLink',
      label: (
        <TwitterShareButton url={localURL} title="MAPA WIEDZY">
          Twitter
        </TwitterShareButton>
      ),
      icon: <TwitterIcon size={18} round />,
    },
    {
      key: 'WhatsappLink',
      label: (
        <WhatsappShareButton url={localURL} title="MAPA WIEDZY">
          Whatsapp
        </WhatsappShareButton>
      ),
      icon: <WhatsappIcon size={18} round />,
    },
  ]
  return (
    <AppDropdown
      menu={{ items }}
      trigger={['click']}
      placement="bottomLeft"
      arrow
    >
      <EventButton style={{ opacity: '1.0' }}>
        <MdShare />
        {t('ShareLink')}
      </EventButton>
    </AppDropdown>
  )
}

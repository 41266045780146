import { common } from './common'

const background = {
  primary: '#181133',
  secondary: '#241A4D',
  tertiary: '#302366',
}

const fontColor = {
  primary: common.white,
  secondary: '#9090B0',
}

const green = {
  main: '#00FFAA',
}

export const dark = {
  background,
  fontColor,
  borderColor: '#3C2B80',
  green,
  success: green,
}

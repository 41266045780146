import e from "express";
import styled from "styled-components";

const mobileBreakpoint = '530px'

//Default components
export const Container = styled.div`
   display: flex;
   place-items: center;
   width: 100%;
   max-width: 800px;
`

export const CentralCol = styled.div`
   width: 100%;
   display: flex;
   place-items: center;
   flex-flow: column nowrap;
`

export const StatsContainer = styled(Container)`
   flex-flow: column nowrap;
   justify-content: space-between;
   padding: 0px 20px;
   gap: 20px;
   @media screen and (min-width: ${mobileBreakpoint}) {
      flex-flow: row nowrap;
      place-items: flex-start;
      gap: 0px;
   }

`

export const StatsCard = styled.div`
   span {
      font-size: ${({theme}) => theme.fontSize.heading1};
      text-align: center;
      line-height: 40px;
   }
   label {
      font-size: ${({theme}) => theme.fontSize.textXM};
      text-align: center;
      color: ${({theme}) => theme.colors.fontColor.secondary};
   }
   display: flex;
   flex-flow: column nowrap;
   place-items: center;
   max-width: 170px;
`

export const ContentContainer = styled(Container)`
   flex-flow: column nowrap;
   gap: 20px;
   h2 {
      font-size: ${({theme}) => theme.fontSize.heading2};
      font-weight: ${({theme}) => theme.fontWeight.bold};
      text-align: center;
      width: 100%;
   }
   p {
      margin-bottom: 0;
      text-align: left;
      font-size: ${({theme}) => theme.fontSize.textL};
   }
   padding: 10px;

   @media screen and (min-width: 800px){
      padding: 0;
   }
`
export const Accent = styled.span<{$cap?: boolean, color?: 'red' | 'blue' | 'yellow' | 'green' | 'purple' }>`
   ${({$cap}) => $cap && `text-transform: uppercase;`}
   font-weight: ${({theme}) => theme.fontWeight.bold};
   color: ${({color, theme}) => {
      switch(color) {
         case 'red':
            return theme.colors.red.main
            
         case 'blue':
            return theme.colors.blue.main
            
         case 'yellow':
            return theme.colors.yellow.main

         case 'green':
            return theme.colors.green.main

         case 'purple':
            return theme.colors.purple.main
         default:
            return theme.colors.fontColor
      }
   }}
`

export const Space = styled.div`
   padding-bottom: 50px;
`

export const PatronGrid = styled.div`
   display: grid;
   grid-template-columns: auto;
   gap: 10px;
   justify-content: center;
   @media screen and (min-width: 530px){
      grid-template-columns: auto auto;
   }
   @media screen and (min-width: 780px){
      grid-template-columns: auto auto auto;
   }
`
import { Popconfirm, PopconfirmProps } from 'antd'
import styled from 'styled-components'
import { AppButton, StyledButton } from '../AppButtons'

const AppPopconfirmContainer = styled.div`
   .ant-popover {
      font-family: ${({ theme }) => theme.fontFamily.primary} !important;
   }
   .ant-popover-inner {
      background-color: ${({ theme }) => theme.colors.background.secondary};
      border: 1px solid ${({ theme }) => theme.colors.borderColor};
   }
   .ant-popover-inner-content, .ant-popover-message {
      color: ${({ theme }) => theme.colors.fontColor.primary} !important; 
   }
   .ant-popover-message-icon svg {
      color: ${({ theme }) => theme.colors.red.main} !important;
      font-size: ${({ theme }) => theme.fontSize.heading4};
   }
   .ant-popover-arrow-content {
      background-color: ${({ theme }) => theme.colors.background.secondary};
      padding: 5px;
      &::before {
         background-color: ${({ theme }) => theme.colors.borderColor};
      }
   }
   .ant-popover-buttons {
      display: flex;
      place-items: center;
      justify-content: flex-end;



      .ant-btn {
         font-size: ${({ theme }) => theme.fontSize.textM};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  border: 0.5px solid ${({ theme }) => theme.colors.borderColor};
  padding: 7px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  box-shadow: none;
  border-radius: 5px;
  min-width: auto;
  && {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.purple.main};
  }
  &:hover {
   color: ${({ theme }) => theme.colors.fontColor};
   border-color: ${({ theme }) => theme.colors.purple.main};
 }

 &.ant-btn-primary:not(.ant-btn-dangerous) {
   border: none;
   color: ${({ theme }) => theme.colors.black};
   background: ${({ theme }) => theme.colors.green.main};
   text-shadow: none;
   &:hover {
     opacity: 0.8;
   }
   &[disabled] {
     background: ${({ theme }) => theme.colors.disabled.background};
     box-shadow: none;
     color: ${({ theme }) => theme.colors.disabled.color};
   }
 }

 &.ant-btn-background-ghost {
   color: ${({ theme }) => theme.colors.purple.main};
   background: transparent;
   border: 2px solid ${({ theme }) => theme.colors.purple.main};
   box-shadow: none;
 }

 &.ant-btn-dangerous {
   color: ${({ theme }) => theme.colors.white};
   border-color: transparent;
   background: ${({ theme }) => theme.colors.red.main};
   &:hover {
     0.25px 0 0 ${({ theme }) => theme.colors.black},
     -0.25px 0 0 ${({ theme }) => theme.colors.black},
     0 0.25px 0 ${({ theme }) => theme.colors.black},
     0 -0.25px 0 ${({ theme }) => theme.colors.black};
   }
 }

      }
   }
`

type AppPopconfirmProps = PopconfirmProps

export const AppPopconfirm: React.FC<AppPopconfirmProps> = ({
  ...inputProps
}) => (
  <AppPopconfirmContainer>
    <Popconfirm
      {...inputProps}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
    />
  </AppPopconfirmContainer>
)

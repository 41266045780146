import { TabPaneProps } from 'antd'
import { t } from 'src/locale'
import { MdLock, MdPublic } from 'react-icons/md'
import {
  ProfileEventsTabLabel,
  ProfileEventsTabs,
} from './styles/ProfileEvents.styles'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { getEventsByAuthorIdAction } from 'src/store'
import { formatQueryTyped } from 'src/utils'
import { useEffect, useState } from 'react'
import { EventsPublic } from './EventsPublic'
import { EventsPrivate } from './EventsPrivate'

type ProfileEventsProps = {
  isOwner: boolean
}

export const ProfileEvents: React.FC<ProfileEventsProps> = ({ isOwner }) => {
  const startLimit = 100
  const adjustLimitBy = 100

  const [limit, setLimit] = useState<number>(startLimit)
  const dispatch = useAppDispatch()
  const accountData = useAppSelector(
    (state) => state.account.accountProfile.account
  )

  useEffect(() => {
    if (accountData) {
      dispatch(
        getEventsByAuthorIdAction({
          authorId: accountData._id,
          query: formatQueryTyped({
            owner: isOwner,
            only: 'public',
            limit: startLimit,
          }),
        })
      )
    }
  }, [])

  const onChange = (activeKey: string) => {
    setLimit(startLimit)
    if (activeKey === '1' && accountData) {
      dispatch(
        getEventsByAuthorIdAction({
          authorId: accountData._id,
          query: formatQueryTyped({
            owner: isOwner,
            only: 'public',
            limit: startLimit,
          }),
        })
      )
    } else if (activeKey === '2' && accountData) {
      dispatch(
        getEventsByAuthorIdAction({
          authorId: accountData._id,
          query: formatQueryTyped({
            owner: isOwner,
            only: 'private',
            limit: startLimit,
          }),
        })
      )
    }
  }

  const tabs: (Omit<TabPaneProps, 'tab'> & {
    key: string
    label: React.ReactNode
  })[] = [
    {
      label: (
        <ProfileEventsTabLabel>
          <MdPublic size={20} /> {t('Public')}
        </ProfileEventsTabLabel>
      ),
      children: (
        <EventsPublic
          limit={limit}
          setLimit={setLimit}
          adjustLimitBy={adjustLimitBy}
          isOwner={isOwner}
        />
      ),
      key: '1',
    },
    {
      label: (
        <ProfileEventsTabLabel>
          <MdLock size={20} /> {t('Private')}
        </ProfileEventsTabLabel>
      ),
      children: (
        <EventsPrivate
          limit={limit}
          setLimit={setLimit}
          adjustLimitBy={adjustLimitBy}
          isOwner={isOwner}
        />
      ),
      key: '2',
    },
  ]

  return isOwner ? (
    <ProfileEventsTabs defaultActiveKey="1" items={tabs} onChange={onChange} />
  ) : (
    <EventsPublic
      limit={limit}
      setLimit={setLimit}
      adjustLimitBy={adjustLimitBy}
      isOwner={isOwner}
    />
  )
}

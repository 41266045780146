import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
*{
   min-height: 0;
   min-width: 0;
 }

 body {
     font-family: ${({ theme }) => theme.fontFamily.primary};
     font-size: ${({ theme }) => theme.fontSize.textM};
     font-weight: ${({ theme }) => theme.fontWeight.light};
     color: ${({ theme }) => theme.colors.fontColor.primary} !important;
     background-color: ${({ theme }) => theme.colors.background.primary};
     overflow-y: scroll;
 }
 h1 { font-size: ${({ theme }) => theme.fontSize.heading1}; }
 h2 { font-size: ${({ theme }) => theme.fontSize.heading2}; }
 h3 { font-size: ${({ theme }) => theme.fontSize.heading3}; }
 h4 { font-size: ${({ theme }) => theme.fontSize.heading4}; }
 h5 { font-size: ${({ theme }) => theme.fontSize.heading5}; }
 h1, h2, h3, h4,h5 {
     margin-bottom: 0;
     font-weight: ${({ theme }) => theme.fontWeight.bold};
     color: ${({ theme }) => theme.colors.fontColor.primary};
 }

 a {
  text-decoration: none;
  color: ${({ theme }) => theme.colors.fontColor.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.textM};

  &:hover {
    color: ${({ theme }) => theme.colors.fontColor.primary};
    text-decoration: none;
 }
}

.img-crop-modal{
 .ant-modal-content {
    padding-bottom: 0;
    background-color: ${({ theme }) => theme.colors.background.primary};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.borderColor};
    border-radius: ${({ theme }) => theme.border.radiusL};
    color: ${({ theme }) => theme.colors.white};

    .ant-modal-header {
      padding: 15px 30px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
      background: ${({ theme }) => theme.colors.background.secondary};
      border-radius: ${({ theme }) => theme.border.radiusL}
        ${({ theme }) => theme.border.radiusL} 0 0;

      .ant-modal-title {
        color: ${({ theme }) => theme.colors.white};
        font-size: ${({ theme }) => theme.fontSize.heading5};
        font-weight: ${({ theme }) => theme.fontWeight.bold};
      }
    }

    .ant-modal-body {
      padding: 30px;
      .ant-slider {
        .ant-slider-rail {
            background: ${({ theme }) => theme.colors.borderColor};
        }
        .ant-slider-track {
            background-color: ${({ theme }) => theme.colors.purple.main};
        }
        .ant-slider-handle {
            border: 2px solid ${({ theme }) => theme.colors.borderColor};
            background-color: ${({ theme }) => theme.colors.purple.main};
        }
      }
    }

    .ant-modal-footer {
      border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .ant-btn {
        font-size: ${({ theme }) => theme.fontSize.textM};
        font-weight: ${({ theme }) => theme.fontWeight.bold};
        border: 0.5px solid ${({ theme }) => theme.colors.borderColor};
        padding: 7px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        box-shadow: none;
        border-radius: 5px;
        min-width: auto;

        &.ant-btn-primary {
          color: ${({ theme }) => theme.colors.white} ;
          background: ${({ theme }) => theme.colors.purple.main} ;
        }

        &.ant-btn-default {
          color: ${({ theme }) => theme.colors.purple.main} ;
          background: transparent;
          border: 2px solid ${({ theme }) => theme.colors.purple.main};
        }
      }
    }

    button.ant-modal-close {
      right: 7px;
      top: 5px;

      .anticon {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.info.main};
      }
    }
  }
}
`

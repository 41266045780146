import { ReactElement } from 'react'
import { ButtonBoxContainer, Left, Right } from './ButtonBox.styles'

type ButtonBoxProps = {
  label?: string
  comment?: string
  button?: ReactElement
  danger?: boolean
}

export const ButtonBox: React.FC<ButtonBoxProps> = ({
  label,
  comment,
  button,
  danger,
}) => (
  <ButtonBoxContainer $danger={danger}>
    <Left $danger={danger}>
      <span>{label}</span>
      <p>{comment}</p>
    </Left>
    <Right>{button}</Right>
  </ButtonBoxContainer>
)

import { useFormikContext } from 'formik'
import { Input, TextAreaProps } from 'formik-antd'
import { get, omit } from 'lodash'
import styled from 'styled-components/macro'
import { AppNotification, AppNotificationPopover } from '../AppNotification'
import { ErrorIcon } from './AppInput/AppInput.styles'
import { MessageType } from './types'

export const InputLabel = styled.label`
  padding-left: 10px;
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.textS} !important;
`

export const StyledAppTextArea = styled(Input.TextArea).attrs(
  (
    props: TextAreaProps & {
      $isError?: boolean
    }
  ) => ({
    $isError: props.$isError || false,
  })
)`
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.borderColor : theme.colors.purple.main};
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 4px 11px;
  font-size: ${({ theme }) => theme.fontSize.textXM};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  &.ant-input-textarea .ant-input {
    background-color: ${({ theme }) => theme.colors.background.tertiary};
    border: none;
    height: 100px;
    padding: 0;
    color: ${({ theme }) => theme.colors.fontColor.primary} !important;
  }
  &.ant-input-textarea::after {
    margin-top: 7px;
    color: ${({ theme }) => theme.colors.fontColor.primary} !important;
    margin-right: -10px;
  }

  color: ${({ theme }) => theme.colors.fontColor.primary} !important;

  &::placeholder {
    font-style: italic;

    color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.background.tertiary};
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled ? theme.colors.borderColor : theme.colors.purple.main};
    ${({ $isError, required, disabled, theme, value }) =>
      (required || $isError) &&
      `border-right: ${
        !$isError && (disabled || !required) ? 0 : '5px'
      } solid ${
        !$isError && value && required
          ? theme.colors.green.main
          : theme.colors.red.main
      };
     transition: width 0.2s;
    `}
  }

  &.ant-input-disabled {
    resize: none;
  }

  ${({ $isError, required, disabled, theme, value }) =>
    (required || $isError) &&
    `border-right: ${!$isError && (disabled || !required) ? 0 : '5px'} solid ${
      !$isError && value && required
        ? theme.colors.green.main
        : theme.colors.red.main
    } !important;
     transition: width 0.2s;
    `}

  &:not(&.ant-input-disabled) {
    &:focus,
    &:hover,
    &:active {
      outline: 0;
      background-color: ${({ theme }) => theme.colors.background.tertiary};
      border: 1px solid
        ${({ theme, disabled }) =>
          disabled ? theme.colors.borderColor : theme.colors.purple.main};
    }
  }
`

type AppTextAreaProps = TextAreaProps & {
  touched?: boolean
  $isError?: boolean
  error?: string
  label?: string
}

export const AppTextArea: React.FC<AppTextAreaProps> = (props) => {
  const { name, touched, label, error } = props
  const { values } = useFormikContext<any>()

  const inputProps = omit(props, ['error'])

  const value = get(values, name)
  const isError = Boolean(error)

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledAppTextArea
        {...inputProps}
        value={value}
        disabled={props.disabled}
        $isError={isError}
      />
      {isError && touched && (
        <AppNotificationPopover
          trigger="hover"
          content={
            <AppNotification type="error" showClose={false}>
              {error}
            </AppNotification>
          }
        >
          <ErrorIcon />
        </AppNotificationPopover>
      )}
    </div>
  )
}

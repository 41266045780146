import { AiFillHeart } from "react-icons/ai"
import { MdBarChart, MdStackedLineChart, MdVolunteerActivism } from "react-icons/md"
import { Accent, CentralCol, ContentContainer, Space, StatsCard, StatsContainer } from "./styles"
import { StyledDivider } from "./styles/Divider.styles"

export const Content = () => {
   return (
      <>
         <StyledDivider><MdBarChart size={50} /></StyledDivider>
         <CentralCol>
            <StatsContainer>
               <StatsCard>
                  <span>6500+</span>
                  <label>Osób skorzystało z platformy</label>
               </StatsCard>

               <StatsCard>
                  <span>100+</span>
                  <label>Darmowych wydarzeń</label>
               </StatsCard>

               <StatsCard>
                  <span>100 000+</span>
                  <label>Odbiorców kampani edukacyjnej</label>
               </StatsCard>
            </StatsContainer>
         </CentralCol>
         <StyledDivider><MdStackedLineChart size={50} /></StyledDivider>
         <CentralCol>
            <ContentContainer>
            <h2>SZUKASZ WIEDZY? - Z NAMI SKORZYSTASZ!</h2>
            <ul>
               <li>
                  Dzięki obszernej bazie wydarzeń <Accent $cap color="yellow">OSZCZĘDZISZ CZAS</Accent>. Zamiast poszukiwać wydarzeń na własną rękę, <Accent>uzyskasz dostęp do obszernej i zweryfikowanej bazy danych.</Accent> 
               </li>
               <li>
                  Zdobędziesz <Accent $cap color="blue">NOWE DOŚWIADCZENIA ORAZ ZNAJOMOŚCI</Accent> dzieląc się wiedzą na licznych grupach stworzonych przez nasz projekt. 
               </li>
               <li>
                  <Accent $cap color="green">ZAOSZCZĘDZISZ PIENIĄDZE</Accent>, korzystając z najlepszych okazji oraz wielu darmowych wydarzeń.
               </li>
               <li>
                  Dowiesz się <Accent $cap color="purple">JAK SIĘ UCZYSZ i SKĄD SIĘ UCZYĆ.</Accent>
               </li>
               <li>
                  <Accent $cap color="red">POPRAWISZ SWOJE SKUPIENIE</Accent> podczas nauki, dobierając najlepsze metody i narzędzia.
               </li>
            </ul>
            </ContentContainer>
         </CentralCol>
         <StyledDivider><MdVolunteerActivism size={50} /></StyledDivider>
         <CentralCol>
            <ContentContainer>
               <h2>POMAGAMY, ŁĄCZYMY, ODCIĄŻAMY.</h2>
               <p>Jesteśmy projektem społecznym, który wspiera uczniów w ich drodze przez polski system szkolnictwa. </p>
               <p>Celem Mapy Wiedzy jest stworzenie bezpiecznego miejsca w internecie, które w jednym miejscu zbierze informacje o sprawdzonych źródłach wiedzy, wesprze uczniów w zdobywaniu nowych umiejętności oraz w przygotowaniach do kluczowych egzaminów. Z tego powodu prowadzimy autorską platformę z wydarzeniami, współpracujemy z czołowymi firmami branży edukacyjnej, a także prowadzimy kampanię w social media, na temat skutecznych metod nauki.</p>
            </ContentContainer>
         </CentralCol>
         <StyledDivider><AiFillHeart size={50} /></StyledDivider>
         <CentralCol>
            <ContentContainer>
               <h2>NASZA MOTYWACJA</h2>
               <p>Jesteśmy tegorocznymi maturzystami, więc doskonale rozumiemy problemy polskiego systemu edukacji. Nasz projekt kierujemy do uczniów, którzy, tak jak my, codziennie doświadczają skutków reformy edukacji, nauczania zdalnego czy zetknięcie się z nową formułą matury. Widzimy, jaki wpływ na młodych ludzi mają te zmiany, liczne niewiadome i wszechobecny chaos, który panuje w całej oświacie. Właśnie z tego powodu postanowiliśmy podjąć działa, których rezultatem będzie realna pomoc uczniom.</p>
            </ContentContainer>
         </CentralCol>
         <Space />
      </>
   )
}
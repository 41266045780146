import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { EventFeed, EventsFeed, RootState, SliceStatus } from 'src/types'
import { getEventsFeedAction } from './actions'

type InProgressEventsFeedSliceData = {
  get: SliceStatus
}

export const inProgressEventsFeedAdapter = createEntityAdapter<EventFeed>({
  selectId: ({ _id }) => _id,
})

export const inProgressEventsFeedSlice = createSlice({
  name: 'inProgressEventsFeedSlice',
  initialState:
    inProgressEventsFeedAdapter.getInitialState<InProgressEventsFeedSliceData>({
      get: {
        isLoading: false,
        error: null,
      },
    }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventsFeedAction.pending, (state) => {
      state.get.isLoading = true
    })
    builder.addCase(
      getEventsFeedAction.fulfilled,
      (state, action: PayloadAction<EventsFeed>) => {
        inProgressEventsFeedAdapter.setAll(
          state,
          action.payload.inProgressEvents
        )
        state.get.isLoading = false
        state.get.error = null
      }
    )
    builder.addCase(getEventsFeedAction.rejected, (state, action) => {
      state.get.isLoading = false
      state.get.error = action.payload
    })
  },
})

export const inProgressEventsFeedSelector =
  inProgressEventsFeedAdapter.getSelectors<RootState>(
    (state) => state.events.feed.inProgress
  )

export const inProgressEventsFeedStateSelector = createSelector(
  (state: RootState) => state.events.feed.inProgress.get,
  (get) => ({
    get,
  })
)

import { Form, Formik } from 'formik'
import { useState } from 'react'
import { MdAdd, MdClose, MdSend } from 'react-icons/md'
import { AppInput, AppTag } from 'src/components/AppComponents'
import { TagPayload, TagType } from 'src/types'
import { TagsWrapper } from '../TagsManager.styles'
import { AiOutlineClear } from 'react-icons/ai'

type TagEditFormProps = {
  parent?: string | null
  type: TagType
  name?: string
  keyString?: string
  openIcon?: React.ReactNode
  onSubmit: (values: TagPayload) => void
  loading?: boolean
  disabled?: boolean
}

export const TagEditForm = ({
  parent = null,
  type = 'category',
  name = '',
  keyString = '',
  openIcon = <MdAdd size={15} />,
  onSubmit,
  loading,
  disabled,
}: TagEditFormProps) => {
  const [open, setOpen] = useState(false)

  const initialValues: Omit<TagPayload, 'parent' | 'type'> = {
    name,
    key: keyString,
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit({
          ...values,
          parent,
          type,
        })
        setOpen(false)
      }}
    >
      {({ submitForm, resetForm }) => (
        <Form>
          <TagsWrapper>
            {open && (
              <>
                <AppInput name="name" placeholder="Nazwa" />
                <AppInput name="key" placeholder="Klucz" />
                <AppTag
                  name={<AiOutlineClear />}
                  type={type}
                  prefix=" "
                  isActive={false}
                  onClick={() => resetForm()}
                />
                <AppTag
                  name={<MdSend />}
                  type={disabled ? 'archived' : type}
                  prefix=" "
                  isActive={true}
                  onClick={
                    disabled
                      ? undefined
                      : () => {
                          submitForm().then(() => resetForm())
                        }
                  }
                  loading={loading}
                />
              </>
            )}
            <AppTag
              type={type}
              isActive={false}
              name={open ? <MdClose size={15} /> : openIcon}
              prefix=" "
              onClick={() => {
                if (open) {
                  resetForm()
                }
                setOpen(!open)
              }}
            />
          </TagsWrapper>
        </Form>
      )}
    </Formik>
  )
}

import { createSlice } from '@reduxjs/toolkit'
import { SliceStatus, Account } from 'src/types'
import {
  deleteActiveAccountByIdAction,
  getCheckIfAccountUsernameIsTaken,
  getCheckIfAccountEmailIsTaken,
} from './actions'
import { deleteAccountByIdAction } from './actions/deleteAccountByIdAction'
import { getAccountByUsername } from './actions/getAccountByUsername'

type AccountProfileSliceData = {
  account?: Account
  status: SliceStatus
  showEditWindow: boolean
  isNameTaken: boolean
  isEmailTaken: boolean
}

const initialState: AccountProfileSliceData = {
  account: undefined,
  status: {
    isLoading: false,
    error: null,
  },
  showEditWindow: false,
  isNameTaken: false,
  isEmailTaken: false,
}

export const accountProfileSlice = createSlice({
  name: 'accountProfile',
  initialState,
  reducers: {
    toggleEditWindow: (state) => {
      state.showEditWindow = !state.showEditWindow
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountByUsername.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(getAccountByUsername.fulfilled, (state, action) => {
      state.status.isLoading = false
      state.status.error = null
      state.account = action.payload
    })
    builder.addCase(getAccountByUsername.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
    builder.addCase(
      getCheckIfAccountUsernameIsTaken.fulfilled,
      (state, action) => {
        state.isNameTaken = action.payload.isNameTaken
      }
    )
    builder.addCase(
      getCheckIfAccountEmailIsTaken.fulfilled,
      (state, action) => {
        state.isEmailTaken = action.payload.isEmailTaken
      }
    )
    builder.addCase(
      deleteActiveAccountByIdAction.fulfilled,
      (state, action) => {
        state.status.isLoading = false
        state.status.error = null
        state.account = undefined
        state.showEditWindow = false
      }
    )
  },
})

import styled from 'styled-components'

export const SortConatiner = styled.div`
  padding-right: 20px;
  display: flex;
  place-items: center;
  justify-content: right;
  font-size: ${({ theme }) => theme.fontSize.textXM} !important;
  gap: 5px;
`

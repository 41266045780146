import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCheckIfAccountEmailIsTakenService } from 'src/services/Account/getCheckIfAccountEmailIsTakenService'
import { ApiError, OnError, OnSuccess } from 'src/types'

type getCheckIfAccountEmailIsTakenArgs = {
  email: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const getCheckIfAccountEmailIsTaken = createAsyncThunk<
  { isEmailTaken: boolean },
  getCheckIfAccountEmailIsTakenArgs,
  { rejectValue: ApiError }
>(
  'account/getCheckIfAccountEmailIsTaken',
  async ({ email, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await getCheckIfAccountEmailIsTakenService(
        { email },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import {
  Box,
  Text,
  Divider,
  RightCol,
  Title,
  TitleBox,
  CentralCol,
  ButtonsBox,
} from './styles'
import { AppButton } from 'src/components/AppComponents'
import { useNavigate } from 'react-router-dom'

export const Welcome = () => {

  return (
    <CentralCol>
      <Box>
        <TitleBox>
          <Title>Zostań Patronem Projektu przyszłości!</Title>
        </TitleBox>
        <Text>
          Wesprzyj nasz projekt comiesięczną dobrowolną darowizną i pomóż nam
          zmieniać świat na lepsze!
        </Text>
        <ButtonsBox>
          <AppButton type="primary" onClick={() =>
            window.open('https://zrzutka.pl/tyzyd7/award/u8agmj', '_blank')}>
            Chcę zostać patronem
          </AppButton>
        </ButtonsBox>
      </Box>
    </CentralCol>
  )
}

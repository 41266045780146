import { common } from './common'

const background = {
  secondary: '#FFFFFF',
  tertiary: '#FAFAFA',
  primary: '#F6F6F6',
}

const fontColor = {
  primary: common.black,
  secondary: '#9090B0',
}

const green = {
  main: '#1ED760',
}

export const light = {
  background,
  fontColor,
  borderColor: '#C3C3C3',
  green,
  success: green,
}


import { Welcome, Explore, Content } from 'src/components/Landing'


export const About = () => {
  return (
    <div>
      <Welcome />
      <Explore />
      <Content />
    </div>
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteOneAccountByIdService } from 'src/services'
import { ApiError, OnError, OnSuccess } from 'src/types'

type deleteActiveAccountByIdActionArgs = {
  accountId: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const deleteActiveAccountByIdAction = createAsyncThunk<
  undefined,
  deleteActiveAccountByIdActionArgs,
  { rejectValue: ApiError }
>(
  'account/deleteActiveAccountById',
  async ({ accountId, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await deleteOneAccountByIdService(
        { accountId },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

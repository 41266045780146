import styled from 'styled-components'

export const CategoriesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  padding: 10px 0;
`

export const CategoriesList = styled.div`
  display: -webkit-box;
  gap: 10px;
  place-items: center;
  overflow: auto;
  white-space: nowrap;
  max-width: 100%;
  width: fit-content;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 5px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`

export const AllButtonBox = styled.div<{ active?: boolean }>`
  display: flex;
  place-items: center;
  gap: 5px;
  ${({ active, theme }) =>
    active
      ? `background: ${theme.colors.background.secondary}; 
   padding: 5px 10px;`
      : 'padding: 5px 0px;'}
  border-radius: 15px;
`

export const AllButtonButton = styled.div`
  width: 50px;
  height: 50px;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
`

import styled from 'styled-components'

export const ProfileBecomePatronContainer = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.green.main};
  display: flex;
  place-items: center;
  font-size: ${({ theme }) => theme.fontSize.textS} !important;
  a {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.green.main};
    :hover {
      color: ${({ theme }) => theme.colors.purple.main};
    }
  }
  b {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`

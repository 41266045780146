import { useNavigate } from "react-router-dom"
import { Bottom, Footer, FooterContainer, List, Top } from "./styles"

export const LandingFooter = () => {

   const navigate = useNavigate()

   return (
      <FooterContainer>
         <Footer>
            <Top>
               <List>
                  <span>Ogólne</span>   
                  <ul>
                     <li onClick={() => navigate('/')}>Platforma</li>
                     <li onClick={() => navigate('/signup')}>Zarejestruj się!</li>
                     <li onClick={() => navigate('/signin')}>Zaloguj się!</li>
                     <li onClick={() => window.open('https://docs.google.com/document/d/1i95p6S8xesaZfG8ZovHxhfFMIkX7rSPaiLf6pRmQTYw/edit', '_blank')}>Jak działa platforma?</li>
                  </ul>
               </List> 
               <List>
                  <span>Social media</span>   
                  <ul>
                     <li onClick={() => window.open('https://fb.me/mapa.wiedzy', '_blank')} >Facebook</li>
                     <li onClick={() => window.open('https://instagram.com', '_blank')} >Instagram</li>
                     <li onClick={() => window.open('https://tiktok.com/@mapa.wiedzy', '_blank')} >Tik Tok</li>
                     <li onClick={() => window.open('https://linkedin.com/company/mapa-wiedzy-pl', '_blank')} >Linked In</li>
                     <li onClick={() => window.open('https://facebook.com/groups/przystanekmatura', '_blank')} >Grupa maturalna na FB</li>
                  </ul>
               </List>  
               <List>
                  <span>Dla Patronów</span>   
                  <ul>
                     <li onClick={() => navigate('/patron')} >Zostań patronem!</li>
                     <li onClick={() => window.open('https://zrzutka.pl/tyzyd7', '_blank')} >Wesprzyj nasz projekt!</li>
                     <li onClick={() => window.open('https://zrzutka.pl/tyzyd7/award/jyat4m', '_blank')} >Dodaj płatne wydarzenie!</li>
                  </ul>
               </List>  
            </Top>
            <Bottom>
            <img src={`/${localStorage.getItem("theme") === 'light' ? 'light' : 'dark'}-mapawiedzy-logo.svg`} alt="Mapa Wiedzy" />
              <span>&copy; 2023 Mapa Wiedzy <br /> Wszelkie prawa zastrzeżone</span>
            </Bottom>
         </Footer>
      </FooterContainer>
   )
}
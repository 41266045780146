import { CameraOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadProps, UploadFile, notification, message } from 'antd'
import ImgCrop from 'antd-img-crop'
import { UploadChangeParam, RcFile } from 'antd/lib/upload'
import React, { useEffect, useState } from 'react'
import { AppLoading } from 'src/components/AppComponents/AppLoading'
import { AppTooltip } from 'src/components/AppComponents/AppTooltip'
import { useAppDispatch } from 'src/hooks/useAppDispatch'
import { AppTooltipContainer, AvatarUpload } from './styles'
import { ProfilePictureContainer, StyledImage } from '../styles'
import { useActiveAccount } from 'src/hooks'
import { patchOneAccountData } from 'src/store/Account/actions/patchOneAccountData'
import { baseUrl } from 'src/api/baseUrl'

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const EditableProfilePicture = () => {
  const [loading, setLoading] = useState(false)
  const [imgUrl, setImgUrl] = useState<string | undefined>(`/${localStorage.getItem('theme') === 'light' ? 'light' : 'dark'}-temporary.webp`)
  const dispatch = useAppDispatch()
  const activeAccount = useActiveAccount()

  useEffect(() => {
    if (activeAccount?.avatar?.light_url) {
      setImgUrl(activeAccount?.avatar.light_url)
    }
  }, [activeAccount])

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImgUrl(url)
        handleSubmit(url)
      })
    }
  }

  const handleSubmit = (image: string) => {
    if (activeAccount?._id) {
      const payload = {
        avatar: image,
      }

      dispatch(
        patchOneAccountData({
          payload,
          accountId: activeAccount._id,
          onSuccess: handleSuccess,
          onError: (err) => handleError(err),
        })
      )
    }
  }

  const handleSuccess = () => {
    notification.success({
      message: 'Successfuly updated your profile picture!',
    })
  }

  const handleError = (err: any) => {
    notification.error({
      message: String(err.response.data.message),
    })
  }

  return (
    <ProfilePictureContainer>
      <AppTooltipContainer>
        <AppTooltip placement="right" title="Change image">
          <ImgCrop shape="round" rotate>
            <AvatarUpload
              action={`${baseUrl}/images/mockup?access_token=${localStorage.getItem(
                'token'
              )}&api_key=${process.env.REACT_APP_API_KEY}`}
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              onChange={handleChange}
            >
              <StyledImage src={imgUrl} className="image" />
              <div className="overlay">
                <CameraOutlined className="icon" />
              </div>
            </AvatarUpload>
          </ImgCrop>
        </AppTooltip>
      </AppTooltipContainer>
    </ProfilePictureContainer>
  )
}

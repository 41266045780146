import { Block1, Block1Content, Block1Details, Block2, Block2Content, Block3Patron, Block3TikTok, Block3TikTokContent, ExploreContainer, ExploreGrid, ExploreSubGrid } from './styles'
import { Block } from '../components'
import { useNavigate } from 'react-router-dom'
import { AppButton } from 'src/components/AppComponents'
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa'

export const Explore = () => {
  const navigate = useNavigate()

  const contents = [
    {
      image: <img src="/free.webp" />,
      title: 'Korzystanie z platformy całkowicie ZA DARMO',
    },
    {
      image: <img src="/location.webp" />,
      title: 'Całe info w jednym miejscu!',
    },
    {
      image: <img src="/time.webp" />,
      title: 'Szybki dostęp do najlepszych wydarzeń!',
    },
    {
      image: <img src="/brain.webp" />,
      title: 'My zbieramy informacje, Ty się tylko uczysz!',
    },
  ]

  const renderBlocks = contents.map((item) => (
    <Block
      title={item.title}
      image={item.image}
    />
  ))

  return (
    <ExploreContainer>
      <ExploreGrid>
        <Block1 onClick={() => navigate('/')}>
          <Block1Content>
            <img src="/mapawiedzy-pin.svg" alt="Mapa Wiedzy - platforma" />
            <Block1Details>
            <h2>mapawiedzy.com</h2>
            <p> Cała wiedza {'\n'}w jednym miejscu</p>
            <AppButton>
              Sprawdź TERAZ
            </AppButton>
            </Block1Details>
            
          </Block1Content>
        </Block1>
        <ExploreSubGrid>
          <Block2 onClick={() => window.open('https://facebook.com/groups/przystanekmatura')}>
            <Block2Content>
              <FaFacebook size={60} />
              <h2>Grupa maturalna</h2>
              <p>Przypomnienia, wydarzenia, notatki.</p>
            </Block2Content>
          </Block2>
          <Block3TikTok onClick={() => window.open('https://tiktok.com/@mapa.wiedzy')}>
            <Block3TikTokContent>
              <FaTiktok size={50} />
              <p>
              Obserwuj nas <br/>
              na Tik Toku
              </p>
            </Block3TikTokContent>
          </Block3TikTok>
        </ExploreSubGrid>
        <ExploreSubGrid>
          <Block3Patron onClick={() => navigate('/patron')}> 
            <h2>Zostań patronem!</h2>
            <p>
            Wesprzyj projekt przyszłości 
            i dotrzyj do szerszego grona 
            odbiorców.
            </p>
          </Block3Patron>
          <Block2 onClick={() => window.open('https://instagram.com/mapa.wiedzy')}>
            <Block2Content>
              <FaInstagram size={60} />
              <h2>@mapa.wiedzy</h2>
              <p>Przypomnienia, wydarzenia,
kampania edukacyjna.</p>
            </Block2Content>
          </Block2>
        </ExploreSubGrid>
      </ExploreGrid>
    </ExploreContainer>
  )
}

import { Switch } from 'formik-antd'
import styled from 'styled-components'

export const AppSwitch = styled(Switch)<{ $brighterBg?: boolean }>`
  &.ant-switch:not(.ant-switch-checked) {
    background-color: ${({ theme, $brighterBg }) =>
      $brighterBg
        ? theme.colors.background.secondary
        : theme.colors.background.primary} !important;
    box-shadow: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
  }
  &.ant-switch.ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.purple.main} !important;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.purple.main}52;
  }
`

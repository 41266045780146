import { EventsPage } from 'src/locale/types/components/EventsPage'

export const EventsPagePL: EventsPage = {
  OnlyFreeEvents: 'Tylko darmowe',
  MoreFilters: 'Więcej filtrów',
  SortBy: 'Sortuj po',
  ByHighestPrice: 'cenie malejąco',
  ByLowestPrice: 'cenie rosnąco',
  ByNearestDate: 'dacie od najbliższej',
  EventsMatchingCriteriaNotFound:
    'Nie znaleziono wydarzeń spełniających powyższe kryteria,',
  All: 'Wszystkie',
}

import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'
import styled, { useTheme } from 'styled-components/macro'

const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    & {
      color: ${({ theme }) => theme.colors.fontColor.primary};
    }
  }

  .ant-tooltip-arrow-content {
    width: 8px;
    height: 8px;
  }
`

export const AppTooltip: React.FC<TooltipProps> = (props) => {
  const activeTheme = useTheme()
  return (
    <StyledTooltip
      {...props}
      color={activeTheme.colors.background.tertiary}
      getPopupContainer={(triggerNode) => triggerNode}
    />
  )
}

export const Profile = {
  ProfileEditButtonText: 'Edytuj profil',
  Name: 'Imię',
  Username: 'Nazwa użytownika',
  Website: 'Link',
  Bio: 'Opis',
  Save: 'Zapisz',
  Cancel: 'Anuluj',
  EditProfile: 'Edytuj profil:',
  ChangedProfileInfo: 'Pomyślnie zaktualizowano informacje!',
  ShareLink: 'Udostępnij',
  CopyLink: 'Kopiuj link',
  ThisAccountHasNotAddedAnyEventsYet:
    'Ten użytkownik nie dodał jeszcze żadnych wydarzeń.',
  YouHaventAddedAnyEventsYet:
    'Nie dodano jeszcze żadnych wydarzeń. Zrób to teraz klikając w przycisk poniżej.',
  AreYouSureYouWantToAddNewEvent: 'Czy na pewno chcesz dodać nowe wydarzenie?',
  YouDoNotHaveAnyPublicEvents: 'Nie posiadasz żadnych publicznych wydarzeń',
  BecomeAPatron: 'jest oficjalnym patronem Mapy Wiedzy.',
  HowToBecomeAPatron: 'Jak zostać patronem?',
  Patron: 'Zostań patronem!',
}

import styled from 'styled-components'

export const SingleEventBodyContainer = styled.div`
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  max-width: 800px;
  gap: 15px;
  width: 100%;
`

import { createPostService } from 'src/utils/services'

type postResetPasswordServiceArgs = {
  payload: {
    token: string
    password: string
  }
}

export const postResetPasswordService = createPostService<
  postResetPasswordServiceArgs,
  undefined
>({
  urlCreator: () => '/auth/reset-password',
})

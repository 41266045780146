import styled from 'styled-components/macro'

export const SearchResultContainer = styled.div<{ $patron?: boolean }>`
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.textM};
  transition: 0.3s;
  border-radius: ${({ theme }) => theme.border.radius};
  border: 1px solid
    ${({ theme, $patron }) =>
      $patron ? theme.colors.green.main : theme.colors.background.secondary};
  background: ${({ $patron, theme }) =>
    $patron
      ? theme.colors.background.secondary
      : theme.colors.background.primary};
  &:hover {
    ${({ $patron, theme }) =>
      $patron
        ? `background: ${theme.colors.background.tertiary};`
        : `background: ${theme.colors.background.secondary};`}
    transition: 0.3s;
  }
  width: 100%;
  ${({ theme, $patron }) =>
    $patron &&
    `

  ::before {
    position: absolute;
    top: 0px;
    right: 0px;
    content: 'PATRON';
    font-size: ${theme.fontSize.textS};
    padding: 0px 3px;
    color: ${theme.colors.black};
    background: ${theme.colors.green.main};
    border-bottom-left-radius: 5px;
  }
  
  `}
`

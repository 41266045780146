import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { EventFeed, EventsFeed, RootState, SliceStatus } from 'src/types'
import { getEventsFeedAction } from './actions'

type TodaysEventsFeedSliceData = {
  get: SliceStatus
}

export const todaysEventsFeedAdapter = createEntityAdapter<EventFeed>({
  selectId: ({ _id }) => _id,
})

export const todaysEventsFeedSlice = createSlice({
  name: 'todaysEventsFeedSlice',
  initialState:
    todaysEventsFeedAdapter.getInitialState<TodaysEventsFeedSliceData>({
      get: {
        isLoading: false,
        error: null,
      },
    }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventsFeedAction.pending, (state) => {
      state.get.isLoading = true
    })
    builder.addCase(
      getEventsFeedAction.fulfilled,
      (state, action: PayloadAction<EventsFeed>) => {
        todaysEventsFeedAdapter.setAll(state, action.payload.todaysEvents)
        state.get.isLoading = false
        state.get.error = null
      }
    )
    builder.addCase(getEventsFeedAction.rejected, (state, action) => {
      state.get.isLoading = false
      state.get.error = action.payload
    })
  },
})

export const todaysEventsFeedSelector =
  todaysEventsFeedAdapter.getSelectors<RootState>(
    (state) => state.events.feed.todays
  )

export const todaysEventsFeedStateSelector = createSelector(
  (state: RootState) => state.events.feed.todays.get,
  (get) => ({
    get,
  })
)

import { createSlice } from '@reduxjs/toolkit'
import { SliceStatus } from 'src/types'
import { register } from './actions'

type AccountRegisterData = {
  status: SliceStatus
}

const initialValues: AccountRegisterData = {
  status: {
    isLoading: false,
    error: null,
  },
}

export const accountRegisterSlice = createSlice({
  name: 'account/register',
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(register.fulfilled, (state, action) => {
      state.status.isLoading = false
      state.status.error = null
    })
    builder.addCase(register.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
  },
})

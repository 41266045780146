import { ReactElement } from 'react'
import { LandingHeader } from 'src/components'
import { LandingFooter } from 'src/components/Footers/LandingFooter'

type LandingProps = {
  children: ReactElement
}

export const Landing = ({ children }: LandingProps) => (
  <>
    <LandingHeader />
    {children}
    <LandingFooter />
  </>
)

import { Formik } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { t } from 'src/locale'
import * as Yup from 'yup'
import { AppButton, AppInputPassword, FormikForm } from '../AppComponents'

type ResetPasswordNewFormProps = {
  handleSubmit: (values: { token: string; password: string }) => void
}

const ResetPasswordNewValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, t('tooShortPassword'))
    .matches(/[0-9]/, t('passRequiresNumber'))
    .matches(/[a-z]/, t('passRequiresLowercase'))
    .matches(/[A-Z]/, t('passRequiresUppercase'))
    .matches(/[^\w]/, t('passRequiresSymbol'))
    .required(t('required')),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], t('passDoNotMatch'))
    .required(t('required')),
})

export const ResetPasswordNewForm: React.FC<ResetPasswordNewFormProps> = ({
  handleSubmit,
}) => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const onSubmit = (values: { password: string; confirmPassword: string }) => {
    const payload = {
      token: token as string,
      password: values.password,
    }

    if (typeof token === 'string') handleSubmit(payload)
  }

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={onSubmit}
        validationSchema={ResetPasswordNewValidationSchema}
      >
        {({ isValid, dirty }) => (
          <FormikForm>
            <AppInputPassword
              name="password"
              placeholder={t('PasswordInputText')}
              required
              type="password"
            />
            <AppInputPassword
              name="confirmPassword"
              placeholder={t('ConfirmPasswordInputText')}
              required
              type="password"
            />
            <AppButton htmlType="submit" disabled={!isValid && !dirty}>
              {t('ResetPassword')}
            </AppButton>
          </FormikForm>
        )}
      </Formik>
    </>
  )
}

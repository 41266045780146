import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { RootState, SliceStatus, Tag } from 'src/types'
import { deleteTagAction, getTagsAction } from './actions'
import { postTagAction } from './actions/postTagAction'
import { patchTagAction } from './actions/patchTagAction'

type InitialStateType = {
  get: SliceStatus
  post: SliceStatus
  patch: SliceStatus
  delete: SliceStatus
  categories: Tag[]
  modes: Tag[]
}

const initialState: InitialStateType = {
  get: {
    isLoading: false,
    error: null,
  },
  post: {
    isLoading: false,
    error: null,
  },
  patch: {
    isLoading: false,
    error: null,
  },
  delete: {
    isLoading: false,
    error: null,
  },
  categories: [],
  modes: [],
}

const tagsAdapter = createEntityAdapter<Tag>({
  selectId: ({ _id }) => _id,
})

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: tagsAdapter.getInitialState<InitialStateType>(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTagsAction.pending, (state) => {
      state.get.isLoading = true
      state.get.error = null
    })
    builder.addCase(
      getTagsAction.fulfilled,
      (state, actions: PayloadAction<Tag[]>) => {
        tagsAdapter.setAll(state, actions.payload)
        state.get.isLoading = false
        state.get.error = null
        state.categories = actions.payload.filter(
          (tag) => tag.type === 'category'
        )
        state.modes = actions.payload.filter((tag) => tag.type === 'mode')
      }
    )
    builder.addCase(getTagsAction.rejected, (state, actions) => {
      state.get.isLoading = false
      state.get.error = actions.payload
    })
    builder.addCase(postTagAction.pending, (state) => {
      state.post.isLoading = true
      state.post.error = null
    })
    builder.addCase(postTagAction.fulfilled, (state) => {
      state.post.isLoading = false
      state.post.error = null
    })
    builder.addCase(postTagAction.rejected, (state, actions) => {
      state.post.isLoading = false
      state.post.error = actions.payload
    })
    builder.addCase(patchTagAction.pending, (state) => {
      state.patch.isLoading = true
      state.patch.error = null
    })
    builder.addCase(patchTagAction.fulfilled, (state) => {
      state.patch.isLoading = false
      state.patch.error = null
    })
    builder.addCase(patchTagAction.rejected, (state, actions) => {
      state.patch.isLoading = false
      state.patch.error = actions.payload
    })
    builder.addCase(deleteTagAction.pending, (state) => {
      state.delete.isLoading = true
      state.delete.error = null
    })
    builder.addCase(deleteTagAction.fulfilled, (state) => {
      state.delete.isLoading = false
      state.delete.error = null
    })
    builder.addCase(deleteTagAction.rejected, (state, actions) => {
      state.delete.isLoading = false
      state.delete.error = actions.payload
    })
  },
})

export const categoriesTagsSelector = createSelector(
  (state: RootState) => state.tags.all.categories,
  (categories) => categories
)

export const modesTagsSelector = createSelector(
  (state: RootState) => state.tags.all.modes,
  (modes) => modes
)

import { useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { AppButton } from 'src/components/AppComponents'
import { ShortLogo } from 'src/components/Logo'
import { LandingNavbar } from 'src/components/Navbar/LandingNavbar'
import { LandingSidebar } from 'src/components/Navbar/LandingSidebar'
import { useScreen } from 'src/hooks'
import { BurgerMenu } from '../AppHeader/styles'
import { HeaderBox, HeaderContainer, HeaderLogoAndFlags, HeaderLogoContainer } from '../styles'
import { LogoAndLanguageContainer } from '../styles/HeaderLanguageChange.styles'
import { CTAContainer } from './styles'

export const LandingHeader = () => {
  const navigate = useNavigate()
  const { isPc } = useScreen()
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)

  const goToApp = () => {
    navigate('/')
  }

  return (
    <HeaderBox>
      <HeaderContainer>
        <LogoAndLanguageContainer>
          <HeaderLogoContainer onClick={() => navigate('/about-us')}>
            <ShortLogo />
          </HeaderLogoContainer>
          <HeaderLogoAndFlags />
        </LogoAndLanguageContainer>
        {!isPc && <LandingNavbar />}
        <CTAContainer>
          <AppButton onClick={goToApp}>Platforma</AppButton>
          {isPc && <BurgerMenu onClick={() => setOpenSidebar(!openSidebar)}>
            {openSidebar ? (
              <AiOutlineClose size={25} />
            ) : (
              <AiOutlineMenu size={25} />
            )}
          </BurgerMenu>}
        </CTAContainer>
        <LandingSidebar open={openSidebar} setOpen={setOpenSidebar} />
      </HeaderContainer>
    </HeaderBox>
  )
}

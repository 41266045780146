import styled from 'styled-components'

export const SingleEventDetailsContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 15px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  align-content: stretch;
  height: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    flex-flow: row nowrap;
  }
`

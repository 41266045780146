import { Tabs } from 'antd'
import styled from 'styled-components'

export const AppTabs = styled(Tabs)`
  &.ant-tabs {
    width: 100%;
    color: ${({ theme }) => theme.colors.fontColor.primary};
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor} ;
  .ant-tabs-nav::before {
    border-bottom: 1px solid ${({ theme }) =>
      theme.colors.borderColor} !important;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      background: ${({ theme }) => theme.colors.background.secondary};
      padding: 5px;
      color: ${({ theme }) => theme.colors.purple.main};
      border-radius: 5px;
    }
  }

  .ant-tabs-tab:hover {
    color: ${({ theme }) => theme.colors.purple.main};
  }
  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.purple.main};
  }
`

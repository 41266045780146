import { Account } from 'src/types'
import { createPatchService } from 'src/utils/services'

type patchOneAccountDataServiceArgs = {
  accountId: string
  payload: {
    name?: string
    username?: string
    bio?: {
      text?: string
      link?: string
    }
    avatar?: string
    firstName?: string
    lastName?: string
  }
}

export const patchOneAccountDataService = createPatchService<
  patchOneAccountDataServiceArgs,
  Account
>({
  urlCreator: ({ accountId }) => `/accounts/${accountId}`,
})

import { Formik } from 'formik'
import { useAppDispatch } from 'src/hooks'
import { getAccountsByQueryFULL } from 'src/store'
import { formatQueryTyped } from 'src/utils'
import { StyledAppInput } from './styles'
import { AiOutlineSearch } from 'react-icons/ai'
import { useEffect } from 'react'

export const SearchInput = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      getAccountsByQueryFULL({
        query: formatQueryTyped({
          arg: '',
          adminInfo: true,
          limit: '1000',
        }),
      })
    )
  }, [])

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      getAccountsByQueryFULL({
        query: formatQueryTyped({
          arg: e.target.value.trim(),
          adminInfo: true,
          limit: '1000',
        }),
      })
    )
  }

  return (
    <Formik
      initialValues={{
        search: '',
      }}
      onSubmit={() => {}}
    >
      <>
        <StyledAppInput
          prefix={<AiOutlineSearch size={20} />}
          onChange={onChange}
          name="search"
          placeholder="Wyszukaj po username lub email"
          type="text"
        />
      </>
    </Formik>
  )
}

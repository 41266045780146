import { Spin, SpinProps } from 'antd'
import { StyledLoadingIcon } from './AppLoading.styles'

type AppLaodingProps = SpinProps & {
  size?: number
}

export const AppLoading: React.FC<AppLaodingProps> = (props) => {
  const { size, ...spinProps } = props

  return (
    <Spin {...spinProps} indicator={<StyledLoadingIcon size={size} spin />} />
  )
}

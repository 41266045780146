import React, { ReactElement } from 'react'
import { Guest } from './Guest'
import { Main } from './Main'

type LayoutProps = {
  children: ReactElement
  isLoggedIn?: boolean
}

export const Layout: React.FC<LayoutProps> = ({ children, isLoggedIn }) => {
  return isLoggedIn ? <Main>{children}</Main> : <Guest>{children}</Guest>
}

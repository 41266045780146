import styled from 'styled-components'

export const DescriptionContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: ${({ theme }) => theme.fontSize.textXM};
  white-space: pre-wrap;
  a {
    color: ${({ theme }) => theme.colors.green.main};
    transition: 0.3s;
    :hover {
      color: ${({ theme }) => theme.colors.purple.main};
      transition: 0.3s;
    }
  }
  word-break: break-word;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.textXM};
    padding-left: 15px;
    max-width: 550px;
  }
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    font-size: ${({ theme }) => theme.fontSize.textM};
  }
`

import { AiFillYoutube } from 'react-icons/ai'
import { CTAButton } from 'src/types'
import styled from 'styled-components'
import { AppButton } from '../AppComponents'

const StyledYoutubeCTAButton = styled(AppButton)`
  && {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme, $clicked }) =>
      $clicked ? theme.colors.white : `#FF0000`};
  }
`

export const CTAYoutube: React.FC<CTAButton> = ({ onClick, disabled }) => {
  return (
    <StyledYoutubeCTAButton
      disabled={disabled}
      icon={<AiFillYoutube size={18} style={{ marginRight: '5px' }} />}
      onClick={onClick}
    >
      YouTube
    </StyledYoutubeCTAButton>
  )
}

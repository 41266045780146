import { createAsyncThunk } from '@reduxjs/toolkit'
import { postTagService } from 'src/services/Tags'
import { ApiError, OnError, OnSuccess, Tag, TagPayload } from 'src/types'

type TagsArgs = {
  payload: TagPayload
  onSuccess?: OnSuccess
  onError?: OnError
}

export const postTagAction = createAsyncThunk<
  Tag,
  TagsArgs,
  { rejectValue: ApiError }
>(
  'tags/post',
  async ({ payload, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await postTagService({ payload }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data)
    }
  }
)

import { Event } from 'src/types'
import { Description } from './Description'
import { Details } from './Details'
import { SingleEventDetailsContainer } from './SingleEventDetails.styles'

type SingleEventDetails = {
  event: Event
}

export const SingleEventDetails: React.FC<SingleEventDetails> = ({ event }) => {
  return (
    <SingleEventDetailsContainer>
      <Details event={event} />
      <Description event={event} />
    </SingleEventDetailsContainer>
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTagsService } from 'src/services/Tags/getTagsService'
import { ApiError, OnError, OnSuccess, Tag } from 'src/types'

type TagsArgs = {
  onSuccess?: OnSuccess
  onError?: OnError
}

export const getTagsAction = createAsyncThunk<
  Tag[],
  TagsArgs,
  { rejectValue: ApiError }
>('tags', async ({ onSuccess, onError }, { rejectWithValue, signal }) => {
  try {
    const result = await getTagsService({}, { signal })
    onSuccess?.()
    return result
  } catch (error: any) {
    onError?.(error)
    return rejectWithValue(error.response.data)
  }
})

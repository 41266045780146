import styled from 'styled-components'

export const GoBackContainer = styled.div`
  width: 100%;
  padding: 10px 50px;
  display: flex;
  place-items: center;
  justify-content: space-around;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: ${({ theme }) => theme.border.radius};

  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding: 10px 30px;
    justify-content: space-between;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.mobile}) {
    padding: 10px 20px;
    flex-direction: column;
  }
`

import { PatronPage } from 'src/pages'
import { About } from 'src/pages/Landing/About'
import { RoutesConfig } from 'src/types'

export const landingConfig: RoutesConfig = {
  about: {
    path: '/about-us',
    PageComponent: About,
  },
  patron: {
    PageComponent: PatronPage,
    path: '/patron',
  },
}

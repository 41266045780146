import styled from 'styled-components'

export const SearchInputContainer = styled.div`
  width: 100%;
  margin: 20px;
  max-width: 500px;
  padding: 0 20px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding: 0;
  }
`

import { useEffect, useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { AppButton } from 'src/components/AppComponents'
import { ShortLogo } from 'src/components/Logo'
import { Navbar } from 'src/components/Navbar'
import { Sidebar } from 'src/components/Navbar/Sidebar'
import { useScreen } from 'src/hooks'
import { t } from 'src/locale'
import { useTheme } from 'styled-components'
import { BurgerMenu } from '../AppHeader/styles'
import {
  HeaderBox,
  HeaderContainer,
  HeaderLogoAndFlags,
  HeaderLogoContainer,
} from '../styles'
import { LogoAndLanguageContainer } from '../styles/HeaderLanguageChange.styles'
import { GuestHeaderButtons } from './GuestHeader.styles'

export const GuestHeader = () => {
  const navigate = useNavigate()
  const { isTablet, isPc } = useScreen()
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)

  return (
    <HeaderBox>
      <HeaderContainer>
        <LogoAndLanguageContainer>
          <HeaderLogoContainer onClick={() => navigate('/')}>
            <ShortLogo />
          </HeaderLogoContainer>
          <HeaderLogoAndFlags />
        </LogoAndLanguageContainer>
        {!isPc && <Navbar />}
        {!isTablet && (
          <GuestHeaderButtons>
            <AppButton type="primary" onClick={() => navigate('/signup')}>
              {t('SignUpStep1Title')}
            </AppButton>
            <AppButton type="primary" onClick={() => navigate('/signin')}>
              {t('SignInTitle')}
            </AppButton>
            <BurgerMenu onClick={() => setOpenSidebar(!openSidebar)}>
              {openSidebar ? (
                <AiOutlineClose size={25} />
              ) : (
                <AiOutlineMenu size={25} />
              )}
            </BurgerMenu>
          </GuestHeaderButtons>
        )}
        {isTablet && (
          <GuestHeaderButtons>
            <AppButton type="primary" onClick={() => navigate('/signin')}>
              {t('SignInTitle')}
            </AppButton>
            <BurgerMenu onClick={() => setOpenSidebar(!openSidebar)}>
              {openSidebar ? (
                <AiOutlineClose size={25} />
              ) : (
                <AiOutlineMenu size={25} />
              )}
            </BurgerMenu>
          </GuestHeaderButtons>
        )}
        <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
      </HeaderContainer>
    </HeaderBox>
  )
}

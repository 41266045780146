import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { SliceStatus, RootState, Event } from 'src/types'
import { getEventsByAuthorIdAction } from './actions'

type accountEventsSliceData = {
  status: SliceStatus
  total: number
}

export const accountEventsAdapter = createEntityAdapter<Event>({
  selectId: ({ _id }) => _id,
})

export const accountEventsSlice = createSlice({
  name: 'accountEvents',
  initialState: accountEventsAdapter.getInitialState<accountEventsSliceData>({
    total: 0,
    status: {
      isLoading: false,
      error: null,
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventsByAuthorIdAction.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(getEventsByAuthorIdAction.fulfilled, (state, action) => {
      accountEventsAdapter.removeAll(state)
      accountEventsAdapter.setAll(state, action.payload.data)
      state.total = action.payload.total
      state.status.isLoading = false
      state.status.error = null
    })
    builder.addCase(getEventsByAuthorIdAction.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
  },
})

export const accountEventsSelector =
  accountEventsAdapter.getSelectors<RootState>(
    (state) => state.events.accountEvents
  )

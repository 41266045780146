import { createAsyncThunk } from '@reduxjs/toolkit'
import { postResetPasswordService } from 'src/services'
import { ApiError, OnError, OnStart, OnSuccess } from 'src/types'

type postResetPasswordArgs = {
  payload: {
    token: string
    password: string
  }
  onStart?: OnStart
  onSuccess?: OnSuccess
  onError?: OnError
}

export const postResetPassword = createAsyncThunk<
  undefined,
  postResetPasswordArgs,
  { rejectValue: ApiError }
>(
  'account/verifyEmail',
  async (
    { payload, onSuccess, onError, onStart },
    { rejectWithValue, signal }
  ) => {
    try {
      onStart?.()
      const result = await postResetPasswordService({ payload }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

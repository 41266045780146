import { createAsyncThunk } from '@reduxjs/toolkit'
import { patchOneEventByIdService } from 'src/services'
import { ApiError, Event, OnError, OnSuccess } from 'src/types'

export type EventPatch = Omit<Partial<Event>, 'image' | 'tags'> & {
  image?: string | null
  tags?: string[]
}

type patchOneEventByIdActionArgs = {
  eventId: string
  payload: EventPatch
  onSuccess?: OnSuccess
  onError?: OnError
}

export const patchOneEventByIdAction = createAsyncThunk<
  Event,
  patchOneEventByIdActionArgs,
  { rejectValue: ApiError }
>(
  'event/patchOne',
  async (
    { payload, onSuccess, onError, eventId },
    { rejectWithValue, signal }
  ) => {
    try {
      const result = await patchOneEventByIdService(
        { payload, eventId },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import { debounce } from 'lodash'
import { AppSearch } from 'src/components/AppComponents'
import { useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { setSearchQuery } from 'src/store'
export const SearchInput = () => {
  const dispatch = useAppDispatch()

  const handleChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchQuery(e.target.value))
  }, 300)

  return (
    <AppSearch
      name="arg"
      placeholder={t('AccountsPagePlaceholder')}
      size="large"
      allowClear
      onChange={handleChange}
    />
  )
}

import { Tooltip } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppButton } from 'src/components/AppComponents'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { getPatronsByQueryAction } from 'src/store'
import { patronsSelector } from 'src/store/Account/patronsSlice'
import { formatQueryTyped } from 'src/utils'
import { Content } from './Content'
import {
  List,
  PatronImg,
  PatronLayoutBox,
  PatronLeft,
  PatronRight,
  PatronsGrid,
  PatronsList,
} from './styles'
import { Welcome } from './Welcome'

export const PatronLayout = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      getPatronsByQueryAction({
        query: formatQueryTyped({
          role: 'patron',
          full: true
        }),
      })
    )
  }, [])

  const patrons = useAppSelector(patronsSelector.selectAll)

  return (
    <>
      <Welcome />
      <Content patrons={patrons}/>
    </>
  )
}

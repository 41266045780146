import { useParams } from 'react-router-dom'
import {
  ProfileButton,
  ProfileButtonContainer,
  ProfileContainer,
} from 'src/components/Profile/ProfileCard'
import {
  AppButton,
  EditProfileModal,
  MetaTags,
  ProfileCard,
  ShareLinksDropdown,
} from 'src/components'
import { useEffect } from 'react'
import { useActiveAccount, useAppDispatch, useAppSelector } from 'src/hooks'
import { getAccountByUsername } from 'src/store/Account/actions/getAccountByUsername'
import { AppLoading } from 'src/components/AppComponents/AppLoading'
import { ProfileEvents } from 'src/components/Profile/AccountEvents/ProfileEvents'
import { MdArrowBack } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { t } from 'src/locale'
import { toggleEditWindow } from 'src/store'

export const Profile = () => {
  const { username } = useParams()
  const dispatch = useAppDispatch()
  const activeAccount = useActiveAccount()
  const accountData = useAppSelector(
    (state) => state.account.accountProfile.account
  )

  useEffect(() => {
    if (username)
      dispatch(
        getAccountByUsername({
          username: username,
        })
      )
  }, [username])

  const isAdmin = activeAccount?.roles?.includes('admin') ? true : false

  const isOwner = activeAccount?._id === accountData?._id

  const { isLoading } = useAppSelector(
    (state) => state.account.accountProfile.status
  )

  const navigate = useNavigate()

  const openEditWindow = () => {
    navigate('/settings')
  }

  const openEditWindowAsAdmin = () => {
    dispatch(toggleEditWindow())
  }

  const renderedMetaTitle = `${accountData?.name.substring(0, 44).trim()}${
    accountData?.name && accountData?.name.length > 40 ? '...' : ''
  }`
  const renderedMetaDesc = accountData?.bio?.text
    ? `${accountData?.bio?.text.substring(0, 156).replace('\n', ' ').trim()}${
        accountData?.bio?.text && accountData?.bio?.text.length > 156
          ? '...'
          : ''
      }`
    : t('AccountsPageParagraph')
  const renderedMetaUrl = `https://mapawiedzy.com/@${username}/`
  const renderedMetaImage = `${
    accountData?.avatar?.light_url || `https://mapawiedzy.com/${localStorage.getItem('theme') === 'light' ? 'light' : 'dark'}-temporary.webp`
  }`

  return (
    <>
      <MetaTags
        title={renderedMetaTitle}
        desc={renderedMetaDesc}
        url={renderedMetaUrl}
        image={renderedMetaImage}
      />
      <ProfileContainer>
        {isLoading && <AppLoading />}
        {!isLoading && !accountData && <h3>Account not found</h3>}
        {!isLoading && accountData && (
          <>
            <ProfileButtonContainer>
              <ProfileButton onClick={() => navigate(-1)}>
                <MdArrowBack /> {t('GoBackButtonText')}
              </ProfileButton>
              {!(isOwner || isAdmin) && <ShareLinksDropdown />}
              {isOwner && activeAccount?.isEmailVerified && (
                <AppButton type="ghost" onClick={openEditWindow}>
                  {t('ProfileEditButtonText')}
                </AppButton>
              )}
              {isAdmin && !isOwner && (
                <AppButton type="ghost" onClick={openEditWindowAsAdmin}>
                  {t('ProfileEditButtonText')}
                </AppButton>
              )}
            </ProfileButtonContainer>
            <EditProfileModal account={accountData} />
            <ProfileCard account={accountData} />
            <ProfileEvents isOwner={isOwner || isAdmin} />
          </>
        )}
      </ProfileContainer>
    </>
  )
}

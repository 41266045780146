import styled from 'styled-components'

export const QueryContainer = styled.div``

export const StyledForm = styled.div`
  max-width: 800px;
  align-self: center;
  margin: auto;
  padding: 0 20px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding: 0;
  }
  form {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
  }
`

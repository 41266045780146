import { createAsyncThunk } from '@reduxjs/toolkit'
import { patchOneAccountDataService } from 'src/services'
import { Account, ApiError, OnError, OnSuccess } from 'src/types'

type patchOneAccountDataArgs = {
  payload: {
    name?: string
    username?: string
    bio?: {
      link: string
      text: string
    }
    avatar?: string
    isUserVerified?: boolean
    isEmailVerified?: boolean
    firstName?: string
    lastName?: string
  }
  accountId: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const patchOneAccountData = createAsyncThunk<
  Account,
  patchOneAccountDataArgs,
  { rejectValue: ApiError }
>(
  'account/patchOne',
  async (
    { payload, onSuccess, onError, accountId },
    { rejectWithValue, signal }
  ) => {
    try {
      const result = await patchOneAccountDataService(
        { payload, accountId },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

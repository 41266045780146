import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAccountByUsernameService,
  getAccountsByQueryService,
} from 'src/services/Account/'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type GetAccountsArgs = {
  query?: string
  token?: string
  onSuccess?: OnSuccess
  onError?: OnError
}
type getAccountsByQueryResp = {
  total: number
  data: Account[]
}

export const getAccountsByQuery = createAsyncThunk<
  getAccountsByQueryResp,
  GetAccountsArgs,
  { rejectValue: ApiError }
>(
  'account/getAccountsByQuery',
  async ({ token, query, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const results = getAccountsByQueryService({ token, query }, { signal })
      onSuccess?.()
      return results
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import styled from 'styled-components'

export const SingleEventHeaderDatesContainer = styled.div`
  color: ${({ theme }) => theme.colors.red.main};
  font-size: ${({ theme }) => theme.fontSize.textXM};
  text-transform: uppercase;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.textM};
  }
`

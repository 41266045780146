import styled from 'styled-components'

export const EventTag = styled.div<{
  $isCategory?: boolean
  $clickable?: boolean
}>`
  font-size: ${({ theme }) => theme.fontSize.textXM};
  color: ${({ theme, $isCategory }) =>
    $isCategory ? theme.colors.info.main : theme.colors.danger.main};
  line-height: 15px;
  text-decoration: none;
  transition: 0.2s;

  ${({ $clickable }) =>
    $clickable &&
    `
      cursor: pointer; 
      &:hover {
        text-decoration: underline;
        transition: 0.2s;
      }
  `}
`

import { Formik } from 'formik'
import styled from 'styled-components'
import { AppButton, AppInput, FormContainer } from '../components'

const Spaces = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Ui = () => {
  return (
    <>
      <AppButton>Default</AppButton>
      <AppButton type="primary">Primary</AppButton>
      <AppButton danger>Danger</AppButton>
      <AppButton disabled>Disabled</AppButton>
      <AppButton type="link">Link</AppButton>
      <AppButton type="text"> Text</AppButton>
      <AppButton type="ghost"> Ghost</AppButton>
      <FormContainer>
        <Formik
          initialValues={{ test: '', test_req: '', test_disabled: '' }}
          onSubmit={() => alert('wow')}
        >
          <Spaces>
            <AppInput name="test" placeholder="Normal input" />
            <AppInput name="test_req" placeholder="Required input" required />
            <AppInput
              name="test_disabled"
              placeholder="Disabled input"
              disabled
            />
            <AppInput
              name="test_disabled"
              placeholder="Readonly input"
              readOnly
            />
          </Spaces>
        </Formik>
      </FormContainer>
    </>
  )
}

import { createDeleteService } from 'src/utils/services'
type deleteOneEventByIdServiceArgs = {
  eventId: string
}

export const deleteOneEventByIdService = createDeleteService<
  deleteOneEventByIdServiceArgs,
  undefined
>({
  urlCreator: ({ eventId }) => `/events/${eventId}/`,
})

import styled from 'styled-components/macro'

export const DrawerItemContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding: 5px;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.textM};
  transition: 0.3s;
  &:hover {
    background: ${({ theme }) => theme.colors.background.secondary};
    border-radius: 5px;
    transition: 0.3s;
  }
`

import { AppTabs } from 'src/components/AppComponents'
import styled from 'styled-components'

export const ProfileEventsTabs = styled(AppTabs)`
  max-width: 800px;
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-tabpane:not(.ant-tabs-tabpane-hidden) {
    display: flex;
    flex-flow: column nowrap;
    place-items: center;
    padding-bottom: 15px;
  }
`

export const ProfileEventsTabLabel = styled.div`
  display: flex;
  place-items: center;
  gap: 5px;
`

import moment from 'moment'
import { CategoryTag } from 'src/components/Category/CategoryTag'
import { Event } from 'src/types'
import { getEventStatus } from 'src/utils'
import { SingleEventHeaderCTAs } from './SingleEventHeaderCTAs'
import { SingleEventHeaderDate } from './SingleEventHeaderDates'
import { SingleEventHeaderPrice } from './SingleEventHeaderPrice'
import { SingleEventHeaderProfile } from './SingleEventHeaderProfile'
import {
  EventHeaderContainer,
  EventHeaderContainerLeft,
  EventHeaderContainerRight,
  EventHeaderTitle,
} from './styles'
import { SingleEventHeaderCategories } from './SingleEventHeaderCategories'

type SingleEventHeaderProps = {
  event: Event | null
}

/*
  Stylings
  if day, month and year are the same as in event === today -> purple
  if nearestEnding > currentTime > nearestStart -> At the moment -> green
  if currentTime > nearestEnding -> archived -> gray 
*/

export const SingleEventHeader: React.FC<SingleEventHeaderProps> = ({
  event,
}) => {
  const { isToday, isInProgress } = getEventStatus(event?.terms[0])

  return (
    <EventHeaderContainer
      isInProgress={isInProgress}
      isToday={isToday}
      archived={event?.archived}
    >
      <EventHeaderContainerLeft>
        {event?.terms && event?.terms.length > 0 && (
          <SingleEventHeaderDate
            from={event?.terms[0].from || ''}
            to={event?.terms[0].to || ''}
          />
        )}

        <EventHeaderTitle>{event?.title}</EventHeaderTitle>
        {event?.author && (
          <SingleEventHeaderProfile
            username={event.author.username}
            avatar={event.author.avatar}
            isUserVerified={event.author.isUserVerified}
            roles={event.author.roles}
            name={event.author.name}
          />
        )}
        <SingleEventHeaderCategories event={event} />
      </EventHeaderContainerLeft>
      <EventHeaderContainerRight>
        <SingleEventHeaderPrice
          price={event?.price || 0}
          free={event?.free || false}
        />
        {event?.cta.link && (
          <SingleEventHeaderCTAs
            type={event?.cta.type || 'other'}
            link={event?.cta.link}
            archived={event.archived}
          />
        )}
      </EventHeaderContainerRight>
    </EventHeaderContainer>
  )
}

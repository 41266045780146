import { StepsProvider } from 'react-step-builder'
import styled from 'styled-components'
import { AppInput } from '..'

export const VerticalInputContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`

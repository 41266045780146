import { ReactElement, useEffect, useState } from 'react'
import { t } from 'src/locale'
import {
  StyledAddButton,
  MobileFooterContainer,
  DrawerContentContainer,
} from './styles'
import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome'
import { AiFillTag } from '@react-icons/all-files/ai/AiFillTag'
import { FaMapMarkedAlt } from '@react-icons/all-files/fa/FaMapMarkedAlt'
import { StyledNavbarItem } from './styles/StyledNavbarItem'
import { useTokenBoolean } from 'src/hooks'
import { AppDrawer } from 'src/components/AppComponents/AppDrawer'
import { DrawerItem } from './DrawerItem'
import { AiOutlineCalendar } from 'react-icons/ai'
import { MdPeopleAlt, MdWavingHand } from 'react-icons/md'
import { notification } from 'antd'
import { postOneEventByIdAction } from 'src/store'
import { useActiveAccount, useAppDispatch } from 'src/hooks'
import { useNavigate } from 'react-router-dom'

type NavbarPaths = {
  [key: string]: {
    path: string
    disabled?: boolean
    label: string
    icon: ReactElement
    isAdd?: boolean
  }
}

export const MobileFooter = () => {
  const isLoggedIn = useTokenBoolean()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight)
  const dispatch = useAppDispatch()
  const activeAccount = useActiveAccount()
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenHeight(window.innerHeight)
    })
  }, [])

  const paths: NavbarPaths = {
    home: {
      path: '/',
      label: t('home'),
      icon: <AiFillHome size={28} />,
    },
    explore: {
      path: '/accounts/',
      label: t('accounts'),
      icon: <MdPeopleAlt size={28} />,
    },
    add: {
      path: '/add/',
      label: t('add'),
      icon: <></>,
      isAdd: true,
    },
    events: {
      path: '/about-us/',
      label: t('AboutUs'),
      icon: <MdWavingHand size={24} />,
    },
    map: {
      path: '/map/',
      label: t('map'),
      icon: <FaMapMarkedAlt size={28} />,
      disabled: true,
    },
  }

  const onPlusClick = () => {
    if (activeAccount?.isEmailVerified) {
      setIsDrawerOpen(!isDrawerOpen)
    } else if (!activeAccount?.isEmailVerified) {
      notification.error({
        message: t('YourEmailIsNotVerified'),
      })
    }
  }

  const filteredPaths = Object.values(paths).filter(
    (item) => isLoggedIn || (!isLoggedIn && !item.isAdd)
  )

  const renderPaths = Object.values(filteredPaths).map((item) => {
    if (item.isAdd)
      return (
        <StyledAddButton key={item.label} onClick={onPlusClick}>
          +
        </StyledAddButton>
      )
    return (
      <StyledNavbarItem
        key={item.label}
        path={item.path}
        label={item.label}
        icon={item.icon}
        disabled={item.disabled}
      />
    )
  })

  const postOneEvent = () => {
    setIsDrawerOpen(!isDrawerOpen)
    if (activeAccount?._id) {
      dispatch(
        postOneEventByIdAction({
          payload: {
            author: activeAccount._id,
          },
          onSuccess: (result) => {
            navigate(`/events/${result.eventId}/edit`)
          },
          onError: (err: any) => {
            notification.error({
              message: String(err.response.data.message),
            })
          },
        })
      )
    }
  }

  return (
    <>
      <MobileFooterContainer>{renderPaths}</MobileFooterContainer>
      <AppDrawer
        placement="bottom"
        zIndex={9000}
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
        closable={false}
        height={120}
      >
        <DrawerContentContainer>
          {/* to make the items lead to somewhere just add the 'to' component with the desired path */}
          {/* <DrawerItem
            title={t('AddPostTitle')}
            content={t('AddPostContent')}
            icon={<AiOutlineAlignLeft size={24} />}
          /> */}
          <a
            onClick={() => postOneEvent()}
            style={{
              textDecoration: 'none',
              color: 'white',
            }}
          >
            <DrawerItem
              title={t('AddEventTitle')}
              content={t('AddEventContent')}
              icon={<AiOutlineCalendar size={24} />}
            />
          </a>
        </DrawerContentContainer>
      </AppDrawer>
    </>
  )
}

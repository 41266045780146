import { useState } from 'react'
import { notification } from 'antd'
import {
  EmptyContainer,
  EntryContainer,
  FormContainer,
  ResetPasswordForm,
} from 'src/components'
import { AppLoading } from 'src/components/AppComponents/AppLoading'
import { useAppDispatch } from 'src/hooks'
import { SuccessIcon } from 'src/icons'
import { t } from 'src/locale'
import { postResetPasswordEmail } from 'src/store/Account/actions/postResetPasswordEmail'
import { Helmet } from 'react-helmet'

export const ResetPassword = () => {
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useAppDispatch()

  const handleSubmit = (values: { email: string }) => {
    dispatch(
      postResetPasswordEmail({
        payload: values,
        onStart: () => {
          setLoading(true)
        },
        onSuccess: () => {
          setLoading(false)
          setSuccess(true)
        },
        onError: (error: any) => {
          setLoading(false)
          notification.error({
            message: String(error.response.data.message),
          })
        },
      })
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('ResetPassword')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <EmptyContainer>
        <EntryContainer>
          <FormContainer>
            {!success && !loading && (
              <>
                <h1>{t('ResetPassword')}</h1>
                <p>{t('EnterYourEmailAddressAndWeWillSendYouALink')}</p>
                <ResetPasswordForm handleSubmit={handleSubmit} />
              </>
            )}
            {success && !loading && (
              <>
                <SuccessIcon />
                <h1>{t('Success')}</h1>
                <p>{t('WeSendYouAnEmailWithLinkToResetYourPassword')}</p>
              </>
            )}
            {loading && <AppLoading />}
          </FormContainer>
        </EntryContainer>
      </EmptyContainer>
    </>
  )
}

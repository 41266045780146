import { Tag } from 'src/types'

export const flatTagsArray = (tags: Tag[], withoutTags?: boolean) => {
  const tagsParents = tags
    .map((tag) => tag.parent)
    .filter((parent) => parent !== null) as Tag[]
  const filteredParents = tagsParents.filter(
    (value, index, self) => index === self.findIndex((t) => t._id === value._id)
  )

  return withoutTags ? filteredParents : [...filteredParents, ...tags]
}

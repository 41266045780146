import ImgCrop from 'antd-img-crop'
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/lib/upload'
import { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { baseUrl } from 'src/api/baseUrl'
import { AppButton } from 'src/components/AppComponents'
import { useActiveAccount, useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { Image } from 'src/types'
import { SingleEventImage } from '../../SingleEvent'
import {
  EventImageUpload,
  EventImageUploadBar,
  EventImageUploadConfig,
  EventImageUploadedContainer,
} from '../styles/EditSingleEventForm.styles'

type EventImageProps = {
  imgUrl: string | null
  setImgUrl: (value: string | null) => void
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

export const EventImage: React.FC<EventImageProps> = ({
  imgUrl,
  setImgUrl,
}) => {
  const [loading, setLoading] = useState(false)

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImgUrl(url)
      })
    }
  }

  if (imgUrl && imgUrl !== null) {
    return (
      <EventImageUploadedContainer>
        <SingleEventImage image={imgUrl} title="Uploaded image" />
        <EventImageUploadBar>
          <ImgCrop aspect={16 / 9} rotate grid fillColor="#181133">
            <EventImageUploadConfig
              name="image"
              action={`${baseUrl}/images/mockup?access_token=${localStorage.getItem(
                'token'
              )}&api_key=${process.env.REACT_APP_API_KEY}`}
              showUploadList={false}
              onChange={handleChange}
            >
              <AppButton>{t('ChangeImage')}</AppButton>
            </EventImageUploadConfig>
          </ImgCrop>
          <AppButton danger onClick={() => setImgUrl(null)}>
            {t('Delete')}
          </AppButton>
        </EventImageUploadBar>
      </EventImageUploadedContainer>
    )
  }

  return (
    <ImgCrop aspect={16 / 9} rotate grid fillColor="#181133">
      <EventImageUploadConfig
        name="image"
        action={`${baseUrl}/images/mockup?access_token=${localStorage.getItem(
          'token'
        )}&api_key=${process.env.REACT_APP_API_KEY}`}
        showUploadList={false}
        onChange={handleChange}
      >
        <EventImageUpload>
          <AiOutlinePlus size={30} />
          {t('AddEventImage')}
        </EventImageUpload>
      </EventImageUploadConfig>
    </ImgCrop>
  )
}

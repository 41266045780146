import { notification } from 'antd'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { StepsProvider } from 'react-step-builder'
import { useAppDispatch } from 'src/hooks/useAppDispatch'
import { t } from 'src/locale'
import { register } from 'src/store/Account/actions/register'
import { SignUpFormValidationSchema } from './SignUpFormValidationSchema'
import { SignUpSteps } from './SignUpSteps'
import { FormikForm } from '../AppComponents'
import { SignUpFormValues } from './index'
import { RegisterResp } from 'src/types'
import { postSendVerificationEmail } from 'src/store'

export const SignUpForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const initialValues: SignUpFormValues = {
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    confirmPassword: '',
    rulesAreAccepted: false,
  }

  const handleSubmitError = (err: any) => {
    notification.error({
      message: String(err.response.data.message),
    })
  }

  const handleSubmitSuccess = (values: RegisterResp) => {
    notification.success({
      message: t('RegisterSuccess'),
    })
    dispatch(
      postSendVerificationEmail({
        payload: {
          token: values.emailToken,
        },
        onSuccess: () => {
          notification.success({
            message: t('WeHaveSentYouAnEmail'),
          })
          setTimeout(() => {
            navigate('/signup/completed')
          }, 300)
        },
        onError: () => {
          notification.error({
            message: t('VerificationEmailWasNotSend'),
          })
        },
      })
    )
  }

  const handleSubmit = (values: SignUpFormValues) => {
    const submitValues = { ...values }
    delete submitValues['confirmPassword']
    dispatch(
      register({
        payload: submitValues,
        onSuccess: handleSubmitSuccess,
        onError: handleSubmitError,
      })
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignUpFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, submitForm }) => (
        <FormikForm>
          <StepsProvider>
            <SignUpSteps />
          </StepsProvider>
        </FormikForm>
      )}
    </Formik>
  )
}

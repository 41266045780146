import { notification } from 'antd'
import { Form, Formik, FormikValues } from 'formik'
import { t } from 'src/locale'
import { Account } from 'src/types'
import { handleFormikErrors } from 'src/utils'
import { AppButton, AppInput, InputGrid } from '../../AppComponents'
import { SettingsContainer, SettingsTitle } from '../SettingsContainer.styles'
import { StyledForm } from './styles'
import { accountFormValidationSchema } from './accountFormValidationSchema'
import { debounce, isEqual } from 'lodash'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { getActiveAccount, getCheckIfAccountUsernameIsTaken } from 'src/store'
import { AppTextArea } from 'src/components/AppComponents/AppInputs/AppTextArea'
import { patchOneAccountData } from 'src/store/Account/actions/patchOneAccountData'
import { useNavigate } from 'react-router-dom'

type AccountFormProps = {
  account: Account
}

export const AccountForm = ({ account }: AccountFormProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isNameTaken = useAppSelector(
    (state) => state.account.accountProfile.isNameTaken
  )
  const initialValues = {
    name: account.name,
    username: account.username,
    bio: {
      text: account.bio?.text || '',
      link: account.bio?.link || '',
    },
  }

  const checkIfTheNameIsTaken = debounce((e) => {
    if (!e.target.value) return
    dispatch(
      getCheckIfAccountUsernameIsTaken({
        username: e.target.value,
      })
    )
  }, 300)

  const handleSuccess = () => {
    notification.success({
      message: t('ChangedProfileInfo'),
    })
    dispatch(getActiveAccount({}))
  }

  const handleError = (err: any) => {
    notification.error({
      message: String(err.response.data.message),
    })
  }

  const onSubmit = (values: FormikValues) => {
    dispatch(
      patchOneAccountData({
        payload: values,
        accountId: account?._id,
        onSuccess: handleSuccess,
        onError: (err) => handleError(err),
      })
    )
  }

  const usernameError = (error: string | undefined, value: string) => {
    if (error) return error

    if (isNameTaken && account.username !== value) return t('UsernameIsTaken')

    return ''
  }

  return (
    <SettingsContainer>
      <SettingsTitle>
        <h2>{t('AccountDetails')}</h2>
        <AppButton
          type="ghost"
          onClick={() => {
            navigate(`/@${account.username}`)
          }}
        >
          {t('GoToProfile')}
        </AppButton>
      </SettingsTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={accountFormValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, submitForm, validateForm }) => (
          <StyledForm>
            <InputGrid>
              <AppInput
                name="name"
                placeholder="np. John Kowalski (nauczyciel), Mapa Wiedzy"
                label={t('AccountName')}
                required
                error={errors.name}
              />
              <AppInput
                name="username"
                placeholder="john_kowalski.123"
                label={t('Username')}
                required
                error={usernameError(errors.username, values.username)}
                onChange={checkIfTheNameIsTaken}
              />
              <AppInput
                name="bio.link"
                placeholder="https://website.com/"
                label={t('Website')}
                type="url"
                error={errors.bio?.link}
              />
              <AppTextArea
                name="bio.text"
                placeholder="Bio...."
                label={t('Bio')}
                bordered={false}
                error={errors.bio?.text}
                maxLength={150}
                showCount
              />
            </InputGrid>
            <AppButton
              onClick={async () => {
                handleFormikErrors(await validateForm())
                submitForm()
              }}
              disabled={isEqual(values, initialValues)}
            >
              {t('Save')}
            </AppButton>
          </StyledForm>
        )}
      </Formik>
    </SettingsContainer>
  )
}

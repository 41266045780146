import { Tag } from 'src/types'
import { TagPayload } from 'src/types'
import { createPostService } from 'src/utils/services'

type PostTagServiceResp = Tag
type PostTagServiceArgs = {
  payload: TagPayload
}

export const postTagService = createPostService<
  PostTagServiceArgs,
  PostTagServiceResp
>({
  urlCreator: () => `/tags/`,
})

import { Badge } from 'antd'
import styled from 'styled-components'

export const CategoryButtonBox = styled.div<{ active?: boolean }>`
  cursor: pointer;
  display: flex;
  place-items: center;
  gap: 5px;
  ${({ active, theme }) =>
    active
      ? `background: ${theme.colors.background.secondary}; 
  padding: 5px 10px;`
      : 'padding: 5px 0px;'}
  border-radius: 15px;
`
export const StyledBadge = styled(Badge)`
  color: ${({theme}) => theme.colors.fontColor.primary};
  .ant-badge-count{
    background: ${({theme}) => theme.colors.red.main};
    color: ${({theme}) => theme.colors.white};
    border: none;
    font-size: ${({theme}) => theme.fontSize.textS};
  }
`
import { ReactElement } from 'react'
import { MdLaptop, MdLocationOn } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import {
  SingleEventHeaderDate,
  SingleEventHeaderProfile,
} from 'src/components/Events/SingleEvent/SingleEventHeader'
import { t } from 'src/locale'
import { Event } from 'src/types'
import { getEventStatus } from 'src/utils'
import { EventCategories } from './EventCategories'
import {
  BottomRight,
  EventComponentContainer,
  EventComponentDetails,
  EventComponentImage,
  EventComponentImg,
  EventComponentMode,
  EventComponentPrice,
  EventComponentTitle,
  EventData,
} from './styles'

type EventComponentProps = {
  event: Event
}

type PrivacyListType = {
  [key: string]: {
    icon: ReactElement
    content: string
  }
}

export const EventComponent: React.FC<EventComponentProps> = ({ event }) => {
  const navigate = useNavigate()

  const onClick = () => {
    navigate(`/events/${event.eventId}`)
  }

  const mode: PrivacyListType = {
    stationary: {
      icon: <MdLocationOn size={23} />,
      content: t('Stationary'),
    },
    hybrid: {
      icon: (
        <>
          <MdLocationOn size={23} />
          <MdLaptop size={23} />
        </>
      ),
      content: t('Hybrid'),
    },
    remote: {
      icon: <MdLaptop size={23} />,
      content: t('Remote'),
    },
  }

  const modeTag = event.tags.filter((tag) => tag.type === 'mode')[0]

  const { isToday, isInProgress } = getEventStatus(event?.terms[0])

  return (
    <EventComponentContainer onClick={onClick}>
      <EventComponentImage
        isInProgress={isInProgress}
        isToday={isToday}
        archived={event?.archived}
      >
        <EventComponentImg
          alt={event.title}
          src={
            event.image
              ? event.image?.light_url
              : `/${
                  localStorage.getItem('theme') === 'light' ? 'light' : 'dark'
                }-169placeholder.webp`
          }
        />
      </EventComponentImage>
      <EventComponentDetails>
        {event.terms[0] ? (
          <SingleEventHeaderDate
            from={event.terms[0].from}
            to={event.terms[0].to}
          />
        ) : (
          t('Draft')
        )}
        <EventComponentTitle>
          <h2>{event.title}</h2>
        </EventComponentTitle>
        <EventData>
          <SingleEventHeaderProfile
            username={event.author.username}
            avatar={event.author.avatar}
            isUserVerified={event.author.isUserVerified}
            roles={event.author.roles}
            name={event.author.name}
          />
          <EventCategories tags={event.tags} />
        </EventData>
        <BottomRight>
          <EventComponentMode>
            {modeTag && mode[modeTag.key].icon}
            <EventComponentPrice
              $free={event.free}
              value={event.free ? t('Free') : event.price}
              precision={2}
              suffix={event.free ? '' : 'zł'}
            />
          </EventComponentMode>
        </BottomRight>
      </EventComponentDetails>
    </EventComponentContainer>
  )
}

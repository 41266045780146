import { Account } from 'src/types'
import { createGetService } from 'src/utils/services'

type getAccountsByQueryServiceArgs = {
  token?: string
  query?: string
  full?: boolean
}
type getAccountsByQueryServiceFULLResp = {
  total: number
  data: Account[]
}

export const getAccountsByQueryServiceFULL = createGetService<
  getAccountsByQueryServiceArgs,
  getAccountsByQueryServiceFULLResp
>({
  urlCreator: ({ query = '' }) => `/accounts/search?full=true&${query}`,
})

import { Badge } from 'antd'
import styled from 'styled-components'

export const TagsSelectorWrapper = styled.div`
  display: flex;
  place-items: flex-start;
  flex-direction: column;
  gap: 10px;
`

export const TagsWrapper = styled.div`
  display: flex;
  place-items: flex-start;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
`

export const TagWrapper = styled.div`
  display: flex;
  place-items: flex-start;
  gap: 10px;
  background: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 15px;
  padding: 0;
  width: fit-content;
`

export const StyledBadge = styled(Badge)`
  color: ${({ theme }) => theme.colors.fontColor.primary};
  .ant-badge-count {
    background: ${({ theme }) => theme.colors.red.main};
    color: ${({ theme }) => theme.colors.white};
    border: none;
    font-size: ${({ theme }) => theme.fontSize.textXS};
  }
`

/*

TRANSLATION MODULE

1. Recognize the user language -> if there is avaible translation loadit else load en
2. Get translation item function
3. Switch language module

*/

import { pl } from './pl'
import { getBrowserLocales } from '../utils'
import { Locale } from './types'

const loadModule = () => pl

export const recognizeUserLanguge = () => {
  const language = getBrowserLocales()
  localStorage.setItem('lang', language || 'pl')
  document.documentElement.setAttribute('lang', language || 'pl')
}

export const activeLanguage = localStorage.getItem('forced-lang')
  ? localStorage.getItem('forced-lang')
  : localStorage.getItem('lang')

export const TRANSLATION = loadModule()

const translationKeys = Object.keys(pl)

export const t = (key: keyof Locale) => {
  return String(TRANSLATION[key as keyof typeof TRANSLATION])
}

export const switchLanguageTo = (lang: string) => {
  localStorage.setItem('forced-lang', lang)
  // eslint-disable-next-line no-restricted-globals
  location.reload()
}

export const switchLanguageToAuto = () => {
  localStorage.removeItem('forced-lang')
}

import styled from 'styled-components'

export const InputBlock = styled.div<{
  $row?: boolean
  $twoColumns?: boolean
  danger?: boolean
}>`
  ${({ $twoColumns, $row, theme }) =>
    $twoColumns
      ? `
    display: grid;
    grid-template-columns: 100%;
    
    @media screen and (min-width: ${theme.mediaBreakpoints.tablet}) {
     grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
    }
   `
      : `
    display: flex;
 
   place-items: center;
   flex-flow: ${$row ? 'row' : 'column'} nowrap;
   
   `};
  align-items: stretch;
  gap: 15px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid
    ${({ danger, theme }) =>
      danger ? theme.colors.red.main : theme.colors.borderColor};
  color: ${({ theme }) => theme.colors.fontColor.primary};
`

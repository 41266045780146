import { t } from '../../../locale'
import { AppInput, AppButton } from '../../AppComponents'
import { useSignUpForm } from '../useSignUpForm'

type ProvideEmailStepProps = {
  handleNextStep: () => void
}

export const ProvideEmailStep: React.FC<ProvideEmailStepProps> = ({
  handleNextStep,
}) => {
  const ctx = useSignUpForm()

  return (
    <>
      <h3>{t('SignUpStep1Title')}</h3>
      <AppInput
        name="email"
        required
        type="email"
        label={t('EmailInputText')}
        placeholder="example@mapawiedzy.com"
        error={ctx.errors.email}
      />
      <AppButton
        disabled={
          ctx.values.email.match(
            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          )
            ? false
            : true
        }
        onClick={handleNextStep}
      >
        {t('ContinueButtonText')}
      </AppButton>
    </>
  )
}

import { createGetService } from 'src/utils/services'
import { Event } from 'src/types'
type getEventsByAuthorIdServiceArgs = {
  authorId: string
  query?: string
}

type getEventsByAuthorIdServiceResp = {
  total: number
  data: Event[]
}

export const getEventsByAuthorIdService = createGetService<
  getEventsByAuthorIdServiceArgs,
  getEventsByAuthorIdServiceResp
>({
  urlCreator: ({ authorId, query }) =>
    `/events/author/${authorId}?${query || ''}`,
})

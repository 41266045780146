import { useParams } from 'react-router-dom'
import { useActiveAccount } from 'src/hooks'
import { Account } from 'src/types'
import { EditableProfilePicture } from './ProfilePicture/EditableProfilePicture'
import { NonEditableProfilePicture } from './ProfilePicture/NonEditableProfilePicture'

type ProfilePictureProps = {
  account?: Partial<Account>
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({ account }) => {
  const { username: paramsUsername } = useParams()
  const activeAccount = useActiveAccount()

  const isActive = paramsUsername === activeAccount?.username
  const isEmailVerified = activeAccount?.isEmailVerified
  return (
    <>
      {isActive && isEmailVerified ? (
        <EditableProfilePicture />
      ) : (
        <NonEditableProfilePicture account={account} />
      )}
    </>
  )
}

import { ReactElement } from 'react'
import { Event } from 'src/types'
import { DetailsItem, DetailsContainer } from './Details.styles'
import {
  MdLaptop,
  MdLocationOn,
  MdOutlineAlarm,
  MdPublic,
} from 'react-icons/md'
import { AiOutlineEyeInvisible, AiOutlineLink } from 'react-icons/ai'
import { t } from 'src/locale'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

type DetailsProps = {
  event: Event
}

type detailsListType = {
  [key: string]: {
    icon: ReactElement
    content: ReactElement
    label: string
  }
}
type PrivacyListType = {
  [key: string]: {
    icon: ReactElement
    content: string
  }
}

export const Details: React.FC<DetailsProps> = ({ event }) => {
  const areTerms = event.terms && event.terms.length > 0
  const timeFrom = dayjs(areTerms ? event.terms[0].from : undefined)
  const timeTo = dayjs(areTerms ? event.terms[0].to : undefined)

  dayjs.extend(duration)
  const difference = dayjs.duration(timeTo.diff(timeFrom))
  const minutes = difference.format('m')
  const hours = difference.format('H')
  const days = difference.format('D')

  const timeDuration = `${days !== '0' ? days + 'd ' : ''}${
    hours !== '0' ? hours + 'h ' : ''
  }${minutes !== '0' ? minutes + 'm' : ''}`

  const modeTag = event.tags.find((tag) => tag.type === 'mode')

  const privacy: PrivacyListType = {
    public: {
      icon: <MdPublic size={23} />,
      content: t('Public'),
    },
    private: {
      icon: <AiOutlineEyeInvisible size={23} />,
      content: t('Private'),
    },
    link: {
      icon: <AiOutlineLink size={23} />,
      content: t('PrivacyOnlyWithTheLink'),
    },
  }
  const mode: PrivacyListType = {
    stationary: {
      icon: <MdLocationOn size={23} />,
      content: t('Stationary'),
    },
    hybrid: {
      icon: (
        <>
          <MdLocationOn size={23} />
          <MdLaptop size={23} />
        </>
      ),
      content: t('Hybrid'),
    },
    remote: {
      icon: <MdLaptop size={23} />,
      content: t('Remote'),
    },
  }

  const detailsList: detailsListType = {
    time: {
      label: t('Duration'),
      icon: <MdOutlineAlarm size={23} />,
      content: <div>{areTerms ? timeDuration : 'HH:mm'}</div>,
    },
    privacy: {
      label: t('Privacy'),
      icon: privacy[event.privacy].icon,
      content: <>{privacy[event.privacy].content}</>,
    },
    mode: {
      label: t('EventMode'),
      content: <>{modeTag?.key && mode[modeTag?.key].content}</>,
      icon: <>{modeTag?.key && mode[modeTag?.key].icon}</>,
    },
  }

  const renderedDetails = Object.values(detailsList).map((item) => (
    <DetailsItem key={item.label}>
      <span>{item.label}:</span>
      <div>
        {item.icon}
        {item.content}
      </div>
    </DetailsItem>
  ))

  return <DetailsContainer>{renderedDetails}</DetailsContainer>
}

import styled from 'styled-components'

export const HeaderBox = styled.div`
  padding: 0;
  position: sticky;
  width: 100%;
  z-index: 1009;
  top: 0px;
  @media screen and (min-width: ${({ theme }) =>
      `${theme.mediaBreakpoints.numbers.pc + 1}px`}) {
    padding: 20px;
    position: static;
  }
`

import { useFormikContext } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import {
  AppTag,
  SmallHeader,
  StyledBadge,
  TagsSelectorWrapper,
  TagsWrapper,
} from 'src/components'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { useEventsFeed } from 'src/hooks/EventsFeed'
import {
  categoriesTagsSelector,
  eventsFeedQuerySelector,
  getTagsAction,
  setTagsQuery,
} from 'src/store'
import { Tag } from 'src/types'
import { flatTagsArray } from 'src/utils/Tags/flatTagsArray'

type TagsSelectorProps = {
  onChange?: (tags: string[]) => void
}

export const TagsQuery = ({ onChange }: TagsSelectorProps) => {
  const dispatch = useAppDispatch()
  const [activeTags, setActiveTags] = useState<string[]>([])
  const [activeCatId, setActiveCatId] = useState<string>()
  const { tags } = useEventsFeed()
  const { tags: queryTags } = useAppSelector(eventsFeedQuerySelector)

  const categories = useAppSelector(categoriesTagsSelector)

  const filteredCategories = categories.filter((tag) => {
    const some = tag.children.some((child) => tags.includes(child._id))
    return some
  })

  const activeCategory = useMemo(() => {
    const activeCat = categories.find((cat: Tag) => cat._id === activeCatId)
    activeCat?.children.filter((tag) => tags.includes(tag._id))

    return activeCat
  }, [activeCatId, categories])

  useEffect(() => {
    dispatch(getTagsAction({}))
  }, [])

  useEffect(() => {
    if (!!categories[0] && !activeCatId) {
      setActiveCatId(categories[0]._id)
    }
  }, [categories])

  const onCategorySelect = (id: string) => {
    setActiveCatId(id)
  }

  const onTagSelect = (tagId: string) => {
    if (activeTags.includes(tagId)) {
      const filteredTags = activeTags.filter((tag) => tag !== tagId)
      setActiveTags(filteredTags)
      const filteredQueryTags = (queryTags || []).filter((id) => id !== tagId)
      dispatch(setTagsQuery(filteredQueryTags))
    } else {
      setActiveTags([...activeTags, tagId])

      dispatch(setTagsQuery([...(queryTags || []), tagId]))
    }
  }

  return (
    <TagsSelectorWrapper>
      <SmallHeader>Kategorie</SmallHeader>
      <TagsWrapper>
        {filteredCategories.map((category: Tag) => {
          const filteredTags = category.children.filter((cat) =>
            activeTags.includes(cat._id)
          )

          const countedActiveTags = filteredTags.length

          return (
            <StyledBadge
              key={category.key}
              count={countedActiveTags}
              overflowCount={99}
              offset={[0, 0]}
            >
              <AppTag
                type="category"
                isActive={activeCatId === category._id}
                name={category.name}
                onClick={() => onCategorySelect(category._id)}
              />
            </StyledBadge>
          )
        })}
      </TagsWrapper>
      {activeCategory && (
        <>
          <SmallHeader>Tagi dla kategorii: {activeCategory?.name}</SmallHeader>
          <TagsWrapper>
            {activeCategory.children
              .filter((tag) => tags.includes(tag._id))
              .map((tag: Tag) => (
                <AppTag
                  key={tag._id}
                  type="tag"
                  isActive={activeTags?.includes(tag._id)}
                  name={tag.name}
                  onClick={() => onTagSelect(tag._id)}
                />
              ))}
          </TagsWrapper>
        </>
      )}
    </TagsSelectorWrapper>
  )
}

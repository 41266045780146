import { GoBackContainer } from './styles'

type GoBackProps = {
  question: string
  button: React.ReactNode
}

export const GoBack: React.FC<GoBackProps> = ({ question, button }) => {
  return (
    <GoBackContainer>
      <h5>{question}</h5>
      {button}
    </GoBackContainer>
  )
}

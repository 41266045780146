import { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { routesConfig } from './config'
import { landingConfig } from './config/landingConfig'
import { useTokenBoolean, useAppDispatch, useActiveAccount } from './hooks'
import { Landing } from './layouts'
import { Layout } from './layouts/Layout'
import { recognizeUserLanguge } from './locale'
import {
  DeletedAccount,
  ResetPassword,
  ResetPasswordNew,
  SignUp,
  Ui,
} from './pages/'
import { E404 } from './pages/E404'
import { SignIn } from './pages/SignIn/SignIn'
import { SignUpCompleted } from './pages/SignUp/SignUpCompleted'
import { VerifyEmail } from './pages/VerifyEmail'
import { getActiveAccount, logout } from './store/'
import { GlobalStyle } from './themes'

function App() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isLoggedIn = useTokenBoolean()

  useEffect(() => {
    const currentTheme = localStorage.getItem('theme')
    if(!currentTheme) {
      localStorage.setItem('theme', 'dark')
      document.documentElement.setAttribute('theme', 'dark')
    }

    if (isLoggedIn) {
      dispatch(
        getActiveAccount({
          onError: (error) => {
            dispatch(logout())
            if (error.response?.status === 401) return navigate('/signin')
          },
        })
      )
    }
    recognizeUserLanguge()
  }, [])

  const renderRoutes = Object.entries(routesConfig).map(([key, entity]) => (
    <Route
      path={entity.path}
      key={key}
      element={
        <Layout isLoggedIn={isLoggedIn}>
          <entity.PageComponent />
        </Layout>
      }
    />
  ))

  const renderLandingRoutes = Object.entries(landingConfig).map(
    ([key, entity]) => (
      <Route
        path={entity.path}
        key={key}
        element={
          <Landing>
            <entity.PageComponent />
          </Landing>
        }
      />
    )
  )

  return (
    <div className="App">
      <GlobalStyle />
      <Routes>
        {/* Authentication routes without any external layout */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/signup/completed" element={<SignUpCompleted />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/new" element={<ResetPasswordNew />} />
        {renderRoutes}
        {renderLandingRoutes}
        <Route path="/ui" element={<Ui />} />
        <Route path="/account/deleted" element={<DeletedAccount />} />
        <Route path="/404" element={<E404 />} />
        <Route path="*" element={<E404 />} />
      </Routes>
    </div>
  )
}

export default App

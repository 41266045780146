import { MdArrowBack, MdModeEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { ShareLinksDropdown } from 'src/components/AppComponents'
import { useActiveAccount } from 'src/hooks'
import { t } from 'src/locale'
import { EventButton, SingleEventButtonsContainer } from './styles'
import { EventModTools } from '../../'
import { Event } from 'src/types'

type SingleEventButtonsProps = {
  authorId?: string
  eventId?: number
  event: Event | null
}

export const SingleEventButtons: React.FC<SingleEventButtonsProps> = ({
  authorId,
  eventId,
  event,
}) => {
  const activeAccount = useActiveAccount()
  const navigate = useNavigate()

  const isOwner =
    activeAccount?._id === authorId || activeAccount?.roles?.includes('admin')

  return (
    <SingleEventButtonsContainer>
      <EventButton onClick={() => navigate(-1)}>
        <MdArrowBack /> {t('GoBackButtonText')}
      </EventButton>
      {(activeAccount?.roles?.includes('mod') ||
        activeAccount?.roles?.includes('admin')) && (
        <EventModTools event={event} />
      )}
      {!isOwner && <ShareLinksDropdown />}
      {isOwner && (
        <EventButton
          type="ghost"
          onClick={() => navigate(`/events/${eventId}/edit`)}
        >
          <MdModeEdit /> {t('Edit')}
        </EventButton>
      )}
    </SingleEventButtonsContainer>
  )
}

import { MdVerified } from 'react-icons/md'
import { Account, AccountRole } from 'src/types'
import { PatronTag } from './PatronTag/PatronTag'

type UsernameProps = {
  xs?: boolean
  isUserVerified?: boolean
  roles?: AccountRole[]
  username: string
}

export const Username = ({
  isUserVerified,
  roles,
  username,
  xs,
}: UsernameProps) => {
  const isVerified = isUserVerified && <MdVerified />
  const isPatron = roles?.includes('patron') && <PatronTag xs={xs} />
  return (
    <>
      <span>@{username}</span> {isVerified} {isPatron}
    </>
  )
}

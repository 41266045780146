import axios, { AxiosResponseTransformer } from 'axios'
import { api } from '../../api'
import { ApiServiceHelpers } from '../../types'

import { createServiceParams } from '.'

export const createGetService =
  <
    ServiceArgs extends {
      params?: Record<string, string | number>
      transformResponse?: AxiosResponseTransformer
      [key: string]: any
    } = {
      [key: string]: any
    },
    ServiceResponse = unknown
  >({
    urlCreator,
  }: {
    urlCreator: (
      args: Omit<ServiceArgs, 'params' | 'transformResponse'>
    ) => string
  }) =>
  async (
    { params: serviceParams, transformResponse, ...args }: ServiceArgs,
    { signal }: ApiServiceHelpers
  ): Promise<ServiceResponse> => {
    const source = axios.CancelToken.source()

    signal.addEventListener('abort', () => source.cancel)

    const params = createServiceParams(serviceParams)

    const response = await api.request<ServiceResponse>({
      url: urlCreator(args),
      cancelToken: source.token,
      params,
      transformResponse,
    })

    return response.data
  }

import { Formik } from 'formik'
import {
  AppButton,
  AppInput,
  AppModal,
  FormikForm,
} from 'src/components/AppComponents'
import { AppTextArea } from 'src/components/AppComponents/AppInputs/AppTextArea'
import { t } from 'src/locale'
import { ModalFooter } from './styles'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { patchOneAccountData } from 'src/store/Account/actions/patchOneAccountData'
import { AccountDetails } from 'src/types'
import { useEditWindow } from 'src/hooks/Account/useEditWindow'
import {
  getAccountByUsername,
  getCheckIfAccountUsernameIsTaken,
  toggleEditWindow,
} from 'src/store'
import { editAccountSchema } from './editAccountSchema'
import { debounce, isEqual } from 'lodash'
import { notification } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

type EditProfileModalProps = {
  account: AccountDetails
}

export const EditProfileModal: React.FC<EditProfileModalProps> = ({
  account,
}) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const visibility = useEditWindow()
  const { username } = useParams()
  const isNameTaken = useAppSelector(
    (state) => state.account.accountProfile.isNameTaken
  )

  const initialValues = {
    name: account.name || '',
    username: account.username || '',
    bio: account.bio || {
      text: '',
      link: '',
    },
  }

  const closeEditWindow = () => {
    dispatch(toggleEditWindow())
    if (username) {
      dispatch(
        getAccountByUsername({
          username: username,
        })
      )
    }
  }

  const checkIfTheNameIsTaken = debounce((e) => {
    if (!e.target.value) return
    dispatch(
      getCheckIfAccountUsernameIsTaken({
        username: e.target.value,
      })
    )
  }, 300)

  const handleSuccess = () => {
    dispatch(toggleEditWindow())
    notification.success({
      message: t('ChangedProfileInfo'),
    })
    navigate(`/@${account.username}`)
  }

  const handleError = (err: any) => {
    notification.error({
      message: String(err.response.data.message),
    })
  }

  const handleSubmit = (values: {
    name: string
    username: string
    bio: { text: string; link: string }
  }) => {
    if (account?._id) {
      dispatch(
        patchOneAccountData({
          payload: values,
          accountId: account?._id,
          onSuccess: handleSuccess,
          onError: (err) => handleError(err),
        })
      )
    }
  }

  const usernameError = (error: string | undefined, value: string) => {
    if (error) return error

    if (isNameTaken && account.username !== value) return t('UsernameIsTaken')

    return ''
  }

  const ifSomethingChange = (values: any) => {
    return isEqual(values, account)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={editAccountSchema}
    >
      {({ submitForm, touched, errors, isValid, values }) => (
        <AppModal
          open={visibility}
          title={`${t('ProfileEditButtonText')}: @${account.username}`}
          footer={
            <ModalFooter>
              <AppButton
                htmlType="submit"
                disabled={!isValid && !ifSomethingChange(values)}
                onClick={submitForm}
              >
                {t('Save')}
              </AppButton>
              <AppButton type="ghost" onClick={closeEditWindow}>
                {t('Cancel')}
              </AppButton>
            </ModalFooter>
          }
          closable={false}
        >
          <FormikForm>
            <AppInput
              name="name"
              placeholder={t('Name')}
              label={t('Name')}
              required
              error={errors.name}
            />
            <AppInput
              name="username"
              placeholder={t('Username')}
              label={t('Username')}
              required
              error={usernameError(errors.username, values.username)}
              onChange={checkIfTheNameIsTaken}
            />
            <AppInput
              name="bio.link"
              placeholder={t('Website')}
              label={t('Website')}
              type="url"
              error={errors.bio?.link}
            />
            <AppTextArea
              name="bio.text"
              placeholder={t('Bio')}
              label={t('Bio')}
              bordered={false}
              error={errors.bio?.text}
              maxLength={150}
              showCount
            />
          </FormikForm>
        </AppModal>
      )}
    </Formik>
  )
}

import {
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'
import { EventFeed, EventsFeed, RootState, SliceStatus } from 'src/types'
import { getEventsFeedAction } from './actions'

type EventsFeedSliceInitialState = {
  tags: string[]
  count: number
  get: SliceStatus
}

const initialState: EventsFeedSliceInitialState = {
  tags: [],
  count: 0,
  get: {
    isLoading: false,
    error: null,
  },
}
export const eventsFeedAdapter = createEntityAdapter<EventFeed>({
  selectId: ({ _id }) => _id,
})

export const eventsFeedSlice = createSlice({
  name: 'eventsFeedSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventsFeedAction.pending, (state) => {
      state.get.isLoading = true
    })
    builder.addCase(
      getEventsFeedAction.fulfilled,
      (state, action: PayloadAction<EventsFeed>) => {
        state.get.isLoading = false
        state.get.error = null
        state.count = action.payload.count
        state.tags = action.payload.tags
      }
    )
    builder.addCase(getEventsFeedAction.rejected, (state, action) => {
      state.get.isLoading = false
      state.get.error = action.payload
      state.count = 0
      state.tags = []
    })
  },
})

export const eventsFeedStateSelector = createSelector(
  (state: RootState) => state.events.feed.data.count,
  (state: RootState) => state.events.feed.data.tags,
  (state: RootState) => state.events.feed.data.get,
  (count, tags, get) => ({
    count,
    tags,
    get,
  })
)

export const Settings = {
  settings: 'Ustawienia',
  deleteAccount: 'Usuń konto',
  changePassword: 'Zmień hasło',
  confirm: 'Potwierdź',
  cancel: 'Anuluj',
  confirmAccountDelete: 'Jesteś pewien, że chcesz usunąć konto?',
  successfullyDeleted: 'Twoje konto zostało pomyślnie usunięte!',
  changePasswordComment: 'Naciśnij na przycisk po prawej i zmień swoje hasło.',
  deleteAccountComment:
    'Chcesz usunąć konto? Jeśli tak, to naciśnij przycisk po prawej, ale pamiętaj, od tego nie ma już powrotu... 🥺💔',
  Edit: 'Edytuj',
  PersonalInformation: 'Dane użytkownika',
  AccountDetails: 'Dane konta',
  AccountName: 'Nazwa konta',
  GoToProfile: 'Przejdź do profilu',
  ChangeEmail: 'Zmień e-mail',
  ChangedEmail: 'E-mail został zmieniony',
  VerificationEmailWasNotSend:
    'ERROR! E-mail weryfikacyjny nie został wysłany!',
  EmailIsTaken: 'Podany e-mail jest już w użyciu.',
}

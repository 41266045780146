import { ReactElement } from 'react'
import { MobileFooter } from 'src/components/Footers/MobileFooter'
import { GuestHeader } from '../../components'
import { MobileWrapper } from '../Main'

type GuestProps = {
  children: ReactElement
}

export const Guest: React.FC<GuestProps> = ({ children }) => {
  return (
    <>
      <GuestHeader />
      <MobileWrapper>{children}</MobileWrapper>
      <MobileFooter />
    </>
  )
}

import { useEffect } from 'react'
import { AppLoading } from 'src/components/AppComponents/AppLoading'
import { useActiveAccount, useAppDispatch, useAppSelector } from 'src/hooks'
import { t } from 'src/locale'
import { accountEventsSelector, getEventsByAuthorIdAction } from 'src/store'
import { formatQueryTyped } from 'src/utils'
import { AccountEvents } from './AccountEvents'
import { YouHaventAddedAnyEventsYet } from './YouHaventAddedAnyEventsYet'

type EventsPrivateProps = {
  setLimit: React.Dispatch<React.SetStateAction<number>>
  limit: number
  adjustLimitBy: number
  isOwner?: boolean
}

export const EventsPrivate: React.FC<EventsPrivateProps> = ({
  limit,
  setLimit,
  adjustLimitBy,
  isOwner,
}) => {
  const events = useAppSelector(accountEventsSelector.selectAll)

  const { isLoading: areAccountEventsLoading } = useAppSelector(
    (state) => state.events.accountEvents.status
  )

  const ifItsOwner = isOwner ? (
    <YouHaventAddedAnyEventsYet />
  ) : (
    <>{t('ThisAccountHasNotAddedAnyEventsYet')}</>
  )

  const ifItsNotEmpty =
    events.length > 0 ? (
      <AccountEvents
        limit={limit}
        setLimit={setLimit}
        events={events}
        activeTab="private"
        adjustLimitBy={adjustLimitBy}
      />
    ) : (
      ifItsOwner
    )

  return areAccountEventsLoading ? <AppLoading /> : ifItsNotEmpty
}

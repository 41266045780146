import { createEntityAdapter, createSlice, Slice } from '@reduxjs/toolkit'
import { SliceStatus, Account, RootState } from 'src/types'
import { getAccountsByQuery } from './actions'

type FetchedAccountsSliceData = {
  status: SliceStatus
  total: number
}

export const fetchedAccountsAdapter = createEntityAdapter<Account>({
  selectId: ({ _id }) => _id,
})

export const fetchedAccountsSlice = createSlice({
  name: 'fetchedAccounts',
  initialState:
    fetchedAccountsAdapter.getInitialState<FetchedAccountsSliceData>({
      status: {
        isLoading: false,
        error: null,
      },
      total: 0,
    }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountsByQuery.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(getAccountsByQuery.fulfilled, (state, action) => {
      fetchedAccountsAdapter.setAll(state, action.payload.data)
      state.status.isLoading = false
      state.status.error = null
      state.total = action.payload.total
    })
    builder.addCase(getAccountsByQuery.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
  },
})

export const fetchedAccountsSelector =
  fetchedAccountsAdapter.getSelectors<RootState>(
    (state) => state.account.fetchedAccounts
  )

import { LoginData, Token } from 'src/types'
import { createPostService } from 'src/utils/services'

type LoginServiceArgs = {
  payload: LoginData
}

export const loginService = createPostService<LoginServiceArgs, Token>({
  urlCreator: () => '/auth/login/',
})

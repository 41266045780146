import axios from 'axios'
import { baseUrl } from './baseUrl'

export const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('token')

  return token
    ? {
        ...config,
        headers: { ...config.headers, access_token: `${token}` },
      }
    : config
}, Promise.reject)

api.interceptors.request.use((config: any) => {
  const api_key = process.env.REACT_APP_API_KEY

  return api_key
    ? {
        ...config,
        headers: { ...config.headers, api_key: `${api_key}` },
      }
    : config
}, Promise.reject)

export default api

import { createDeleteService } from 'src/utils/services'

type DeleteTagServiceArgs = {
  tagId: string
}

export const deleteTagService = createDeleteService<
  DeleteTagServiceArgs,
  undefined
>({
  urlCreator: ({ tagId }) => `/tags/${tagId}/`,
})

import { Helmet } from 'react-helmet'

type MetaTagsProps = {
  title: string
  desc: string
  url: string
  image: string
}

export const MetaTags = ({ title, desc, url, image }: MetaTagsProps) => {
  return (
    <Helmet>
      <title>{title} | mapawiedzy.com</title>
      <meta name="title" content={`${title} | mapawiedzy.com`} />
      <meta name="description" content={desc} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={`${title} | mapawiedzy.com`} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={`${title} | mapawiedzy.com`} />
      <meta property="twitter:description" content={desc} />
      <meta property="twitter:image" content={image} />
      <link rel="canonical" href={url} />
    </Helmet>
  )
}

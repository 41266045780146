import styled from 'styled-components'

export const AccountEventsContainer = styled.div`
  width: calc(100% - 20px);
  padding: 0 10px;
  display: flex;
  flex-flow: column nowrap;
  place-items: stretch;
  gap: 10px;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    max-width: 800px;
    padding: 0;
  }
`

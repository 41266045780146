import { Drawer } from 'antd'
import styled from 'styled-components'

export const SidebarDrawer = styled(Drawer)<{ biggerSpace?: boolean }>`
  height: calc(100vh - 60px);
  margin-top: 60px;
  margin-right: 0px;
  position: fixed !important;
  z-index: 1008;
  .ant-drawer-content-wrapper {
    width: 100% !important;
    .ant-drawer-content {
      background: ${({ theme }) => theme.colors.background.primary} !important;
      border-radius: ${({ theme }) => theme.border.radius};
      .ant-drawer-body {
        background: ${({ theme }) =>
          theme.colors.background.secondary} !important;
        padding: 10px;
      }
    }
  }
  @media screen and (min-width: ${({ theme }) =>
      `${theme.mediaBreakpoints.numbers.pc + 1}px`}) {
    height: calc(
      100vh - ${({ biggerSpace }) => (biggerSpace ? '170px' : '130px')}
    );
    margin-top: ${({ biggerSpace }) => (biggerSpace ? '150px' : '110px')};
    margin-right: 20px;
    .ant-drawer-content-wrapper {
      width: 250px !important;
      .ant-drawer-content {
        background: ${({ theme }) =>
          theme.colors.background.secondary} !important;
      }
    }
  }
`
export const SidebarItem = styled.div<{ $green?: boolean }>`
  display: flex;
  place-items: center;
  gap: 5px;
  width: 100%;
  padding: 5px 10px;
  color: ${({ theme, $green }) =>
    $green ? theme.colors.black : theme.colors.fontColor};
  border-radius: ${({ theme }) => theme.border.radius};
  background: ${({ theme, $green }) =>
    $green ? theme.colors.green.main : theme.colors.background.secondary};
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transition: 0.3s;
    background: ${({ theme, $green }) =>
      $green
        ? `${theme.colors.green.main}4d`
        : theme.colors.background.secondary};
  }
`

export const SidebarContainer = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 5px;
`
export const LogoutButton = styled(SidebarItem)`
  background: ${({ theme }) => theme.colors.red.main};
  &:hover {
    opacity: 0.7;
    background: ${({ theme }) => theme.colors.red.main};
  }
`
export const Socials = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  width: 100%;
  span {
    font-size: ${({ theme }) => theme.fontSize.textXS};
    color: ${({ theme }) => theme.colors.fontColor.secondary};
  }
`
export const SocialsGrid = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
`
export const SocialIconButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: none;
  display: flex;
  place-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  :hover {
    transition: 0.2s;
    background: ${({ theme }) => theme.colors.background.tertiary};
  }
`
export const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
`

export const ModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  gap: 5px;
  h2 {
    font-size: ${({ theme }) => theme.fontSize.heading4};
  }
  p {
    margin: 0;
  }
`

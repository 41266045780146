import { notification } from 'antd'
import { Form, Formik, FormikValues } from 'formik'
import { isEqual, values } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { getActiveAccount } from 'src/store'
import { patchOneAccountData } from 'src/store/Account/actions/patchOneAccountData'
import { Account } from 'src/types'
import { handleFormikErrors } from 'src/utils'
import { AppButton, AppInput, InputGrid } from '../../AppComponents'
import { SettingsContainer, SettingsTitle } from '../SettingsContainer.styles'
import { StyledForm } from './styles'
import { userFormValidationSchema } from './userFormValidationSchema'

type UserFormProps = {
  account: Account
}

export const UserForm = ({ account }: UserFormProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const initialValues = {
    firstName: account.firstName,
    lastName: account.lastName,
  }

  const handleSuccess = () => {
    notification.success({
      message: t('ChangedProfileInfo'),
    })
    dispatch(getActiveAccount({}))
  }

  const handleError = (err: any) => {
    notification.error({
      message: String(err.response.data.message),
    })
  }

  const onSubmit = (values: FormikValues) => {
    if (account?._id) {
      dispatch(
        patchOneAccountData({
          payload: values,
          accountId: account?._id,
          onSuccess: handleSuccess,
          onError: (err) => handleError(err),
        })
      )
    }
  }

  return (
    <SettingsContainer>
      <SettingsTitle>
        <h2>{t('PersonalInformation')}</h2>
        <AppButton
          type="ghost"
          onClick={() => {
            navigate(`/@${account.username}`)
          }}
        >
          {t('GoToProfile')}
        </AppButton>
      </SettingsTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={userFormValidationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, submitForm, validateForm }) => (
          <StyledForm>
            <InputGrid $twoColumns>
              <AppInput
                name="firstName"
                label={t('NameInputText')}
                placeholder="John"
                required
                error={errors.firstName}
              />
              <AppInput
                name="lastName"
                label={t('LastNameInputText')}
                placeholder="Kowalski"
                required
                error={errors.lastName}
              />
            </InputGrid>
            <AppButton
              onClick={async () => {
                handleFormikErrors(await validateForm())
                submitForm()
              }}
              disabled={isEqual(values, initialValues)}
            >
              {t('Save')}
            </AppButton>
          </StyledForm>
        )}
      </Formik>
    </SettingsContainer>
  )
}

export function getBrowserLocales() {
  let browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages

  if (!browserLocales) {
    return undefined
  }

  browserLocales = browserLocales.map((locale) => {
    const trimmedLocale = locale.trim().split(/-|_/)[0]

    return trimmedLocale
  })

  return browserLocales[0]
}

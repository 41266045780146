import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { SliceStatus, RootState, Event } from 'src/types'
import { Category } from 'src/types/Categories/Category'
import { getAllCategoriesAction } from './actions'

type categoriesSliceData = {
  status: SliceStatus
}

export const categoriesAdapter = createEntityAdapter<Category>({
  selectId: ({ _id }) => _id,
})

export const categoriesSlice = createSlice({
  name: 'categoriesSlice',
  initialState: categoriesAdapter.getInitialState<categoriesSliceData>({
    status: {
      isLoading: false,
      error: null,
    },
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCategoriesAction.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(getAllCategoriesAction.fulfilled, (state, action) => {
      categoriesAdapter.setAll(state, action.payload)
      state.status.isLoading = false
      state.status.error = null
    })
    builder.addCase(getAllCategoriesAction.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
  },
})

export const categoriesSelector = categoriesAdapter.getSelectors<RootState>(
  (state) => state.categories.all
)

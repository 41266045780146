import { Event } from 'src/types'
import { createPatchService } from 'src/utils/services'

type patchOneEventByIdServiceArgs = {
  eventId: string
  payload: Omit<Partial<Event>, 'image' | 'tags'> & {
    image?: string | null | undefined
    tags?: string[]
  }
}

export const patchOneEventByIdService = createPatchService<
  patchOneEventByIdServiceArgs,
  Event
>({
  urlCreator: ({ eventId }) => `/events/${eventId}`,
})

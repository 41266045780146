import { Account } from 'src/types'
import { useTheme } from 'styled-components'
import { ProfileBecomePatron } from './ProfileBecomePatron'
import {
  ProfileBio,
  ProfileDetailsContentContaier,
  ProfileLink,
  ProfileName,
} from './styles'

export const ProfileDetailsContent: React.FC<{
  account: Partial<Account> | undefined
  screen: number
}> = ({ account, screen }) => {
  const theme = useTheme()
  const isLinkRegex = /^(http|https):\/\//i

  const link = account?.bio?.link
  const onClick = () => {
    if (!link) return
    const targetLink = isLinkRegex.test(link) ? link : `https://${link}`
    window.open(targetLink, '_blank')
  }

  const splittedLink = link ? link.split('/') : []
  const domain =
    link && isLinkRegex.test(link) ? splittedLink[2] : splittedLink[0]

  const isTablet = screen <= theme.mediaBreakpoints.numbers.tablet

  return (
    <ProfileDetailsContentContaier>
      {!isTablet && <ProfileName>{account?.name}</ProfileName>}
      <ProfileBio>{account?.bio?.text}</ProfileBio>
      {account && account?.roles?.includes('patron') && (
        <ProfileBecomePatron account={account} />
      )}
      {link && <ProfileLink onClick={onClick}>{domain}</ProfileLink>}
    </ProfileDetailsContentContaier>
  )
}

import { Account } from 'src/types'
import { createGetService } from 'src/utils/services'

type getAccountsByQueryServiceArgs = {
  token?: string
  query?: string
}
type getAccountsByQueryServiceResp = {
  total: number
  data: Account[]
}

export const getAccountsByQueryService = createGetService<
  getAccountsByQueryServiceArgs,
  getAccountsByQueryServiceResp
>({
  urlCreator: ({ query = '' }) => `/accounts/search?${query}`,
})

import { Account } from 'src/types'
import { createGetService } from 'src/utils/services'

type getAccountByUsernameServiceArgs = {
  token?: string
  username: string
}

export const getAccountByUsernameService = createGetService<
  getAccountByUsernameServiceArgs,
  Account
>({
  urlCreator: ({ token, username }) =>
    `/accounts/username/${username}${token ? `?access_token=${token}` : ''}`,
})

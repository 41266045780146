import { useState } from 'react'
import { EyeInvisibleFilled, EyeFilled } from '@ant-design/icons'
import { useFormikContext } from 'formik'
import { InputProps } from 'formik-antd'
import { omit, get } from 'lodash'
import {
  ErrorIcon,
  InputLabel,
  InputWrapper,
  StyledAppInput,
} from './AppInput.styles'
import { AppNotification, AppNotificationPopover } from '../../AppNotification'
import { MessageType } from '../types'

export type AppInputPasswordProps = InputProps & {
  message?: string
  messageType?: MessageType
  required?: boolean
  touched?: boolean
  label?: string
}

export const AppInputPassword: React.FC<AppInputPasswordProps> = (props) => {
  const { name, touched, label } = props
  const { values, errors } = useFormikContext<any>()

  const inputProps = omit(props, ['message', 'messageType', 'type'])
  const [revealPassword, setRevealPassword] = useState<boolean>(false)

  const switchRevealPassword = () => {
    setRevealPassword((prevState) => !prevState)
  }

  const value = get(values, name)
  const error = errors[name] as string
  const isError = Boolean(error) || props.messageType === 'error'

  const message = error || props.message

  const returnMessageType = () => {
    if (props.messageType) return props.messageType
    if (isError) return 'error'
    return 'info'
  }
  const messageType = returnMessageType()

  const passwordButtons = () => (
    <>
      {isError && touched && (
        <AppNotificationPopover
          trigger="hover"
          content={
            <AppNotification type={messageType} showClose={false}>
              {message}
            </AppNotification>
          }
        >
          <ErrorIcon />
        </AppNotificationPopover>
      )}
      {revealPassword ? (
        <EyeInvisibleFilled onClick={switchRevealPassword} />
      ) : (
        <EyeFilled onClick={switchRevealPassword} />
      )}
    </>
  )

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <InputWrapper
        value={value}
        disabled={props.disabled}
        $isError={isError}
        $isRequired={props.required}
      >
        <StyledAppInput
          {...inputProps}
          type={revealPassword ? 'text' : 'password'}
          value={value}
          suffix={passwordButtons()}
        />
      </InputWrapper>
    </div>
  )
}

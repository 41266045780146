import { createGetService } from 'src/utils/services'
import { Event } from 'src/types'
type searchAllEventsServiceArgs = {
  query?: string
}

type searchAllEventsServiceResp = {
  total: number
  data: Event[]
}

export const searchAllEventsService = createGetService<
  searchAllEventsServiceArgs,
  searchAllEventsServiceResp
>({
  urlCreator: ({ query = '' }) => `/events/search?${query}`,
})

import styled from 'styled-components/macro'

export const SearchResultsContainerFooter = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  height: 12%;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.heading5};
  color: ${({ theme }) => theme.colors.purple.main};

  :hover {
    color: ${({ theme }) => theme.colors.purple.hover};
  }

  :focus {
    color: ${({ theme }) => theme.colors.purple.focus};
  }

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    display: flex;
  }
`

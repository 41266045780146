import { ReactElement } from 'react'
import { FaHandshake } from 'react-icons/fa'
import { MdWavingHand } from 'react-icons/md'
import { StyledNavbarItem } from 'src/components/Footers/MobileFooter/styles'
import { StyledNavbar } from '../styles/StyledNavbar'

type NavbarPaths = {
  [key: string]: {
    path: string
    disabled?: boolean
    label: string
    icon: ReactElement
  }
}

export const LandingNavbar = () => {
  const paths: NavbarPaths = {
    about: {
      path: '/about-us',
      label: 'O nas',
      icon: <MdWavingHand size={24} />,
    },
    patron: {
      path: '/patron',
      label: 'Zostań patronem!',
      icon: <FaHandshake size={24} />,
    },
  }

  const renderPaths = Object.values(paths).map((item) => (
    <StyledNavbarItem
      key={item.label}
      path={item.path}
      label={item.label}
      icon={item.icon}
      disabled={item.disabled}
    />
  ))

  return <StyledNavbar>{renderPaths}</StyledNavbar>
}

import { Account } from 'src/types'
import { createPostService } from 'src/utils/services'

type postResendVerifyEmailServiceArgs = {
  payload: undefined
}

export const postResendVerifyEmailService = createPostService<
  postResendVerifyEmailServiceArgs,
  Account
>({
  urlCreator: () => '/auth/verify-email-resend',
})

import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  place-items: left;
  padding-top: 20px;
  gap: 15px;
  align-self: center;
  margin: auto;
`

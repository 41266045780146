import styled from 'styled-components/macro'

export const SearchResultsContent = styled.div`
  width: 100%;
  height: fit-content;
  .ant-table-thead .ant-table-cell {
    background-color: #302366;
    border: '1px solid #7856FF';
    color: white;
  }
  box-sizing: border-box;

  border-radius: 5px;
  border-width: 1px;
  box-shadow: 0 0 10px #121216;
  overflow: hidden;

  font-weight: bold;
`

import { MdDarkMode, MdLightMode } from 'react-icons/md'
import { LanguageButton } from './styles/HeaderLanguageChange.styles'

export const HeaderLogoAndFlags = () => {

  const isLightTheme = localStorage.getItem('theme') === 'light'

  return (
    <LanguageButton onClick={() => {
        localStorage.setItem('theme', isLightTheme ? 'dark' : 'light') 
        window.location.reload()
    }}>
      {isLightTheme ? <MdDarkMode size={25}/> : <MdLightMode size={25} />}
    </LanguageButton>
  )
}

import { createSlice } from '@reduxjs/toolkit'
import { SliceStatus, Account } from 'src/types'
import {
  deleteAccountByIdAction,
  deleteActiveAccountByIdAction,
} from './actions'
import { getActiveAccount } from './actions/getActiveAccount'

type ActiveAccountSliceData = {
  account?: Account & { exp: number }
  status: SliceStatus
}

const initialState: ActiveAccountSliceData = {
  account: undefined,
  status: {
    isLoading: false,
    error: null,
  },
}

export const activeAccountSlice = createSlice({
  name: 'activeAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActiveAccount.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(getActiveAccount.fulfilled, (state, action) => {
      state.status.isLoading = false
      state.status.error = null
      state.account = action.payload
    })
    builder.addCase(getActiveAccount.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })

    builder.addCase(
      deleteActiveAccountByIdAction.fulfilled,
      (state, action) => {
        state.status.isLoading = false
        state.status.error = null
        state.account = undefined
      }
    )
  },
})

import { createGetService } from 'src/utils/services'
import { Event } from 'src/types'
type getOneEventByCleanIdServiceArgs = {
  eventId: number
}

export const getOneEventByCleanIdService = createGetService<
  getOneEventByCleanIdServiceArgs,
  Event
>({
  urlCreator: ({ eventId }) => `/events/clean/${eventId}`,
})

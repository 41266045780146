import { Account } from 'src/types'
import { createPostService } from 'src/utils/services'

type postVerifyEmailArgs = {
  payload: {
    token: string
  }
}

export const postVerifyEmailService = createPostService<
  postVerifyEmailArgs,
  Account
>({
  urlCreator: () => '/auth/verify-email',
})

import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 10px;
  place-items: center;
  flex-flow: column nowrap;
`

export const Message = styled.div``

export const AddNowButton = styled.div`
  padding: 10px 50px;
  background: ${({ theme }) => theme.colors.background.primary};
  transition: 0.3s;
  width: calc(100% - 100px);
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: ${({ theme }) => theme.border.radius};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  &:hover {
    background: ${({ theme }) => theme.colors.background.secondary};
    transition: 0.3s;
  }
`

import * as Yup from 'yup'
import { t } from '../../locale'

export const SignUpFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      'E-mail: ' + t('invalidEmail')
    )
    .required('E-mail: ' + t('required')),
  firstName: Yup.string()
    .min(2, `${t('NameInputText')}: ${t('tooShort2Chars')}`)
    .max(50, `${t('NameInputText')}: ${t('tooLong50Chars')}`)
    .required(`${t('NameInputText')}: ${t('required')}`),
  lastName: Yup.string()
    .min(2, `${t('LastNameInputText')}: ${t('tooShort2Chars')}`)
    .max(50, `${t('LastNameInputText')}: ${t('tooLong50Chars')}`)
    .required(`${t('LastNameInputText')}: ${t('required')}`),
  username: Yup.string()
    .min(4, `${t('UsernameInputText')}: ${t('tooShort4Chars')}`)
    .max(32, `${t('UsernameInputText')}: ${t('tooLongUsername')}`)
    .matches(
      /^(?![_.])[a-z0-9._]+$/,
      `${t('UsernameInputText')}: ${t('UsernameDoesNotMatch')}`
    )
    .required(`${t('UsernameInputText')}: ${t('required')}`),
  password: Yup.string()
    .min(8, `${t('PasswordInputText')}: ${t('tooShortPassword')}`)
    .matches(/[0-9]/, `${t('PasswordInputText')}: ${t('passRequiresNumber')}`)
    .matches(
      /[a-z]/,
      `${t('PasswordInputText')}: ${t('passRequiresLowercase')}`
    )
    .matches(
      /[A-Z]/,
      `${t('PasswordInputText')}: ${t('passRequiresUppercase')}`
    )
    .matches(/[^\w]/, `${t('PasswordInputText')}: ${t('passRequiresSymbol')}`)
    .required(`${t('PasswordInputText')}: ${t('required')}`),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      `${t('ConfirmPasswordInputText')}: ${t('passDoNotMatch')}`
    )
    .required(`${t('ConfirmPasswordInputText')}: ${t('required')}`),
  rulesAreAccepted: Yup.boolean().isTrue(t('youNeedToAcceptRules')),
})

import { DefaultTheme } from 'styled-components/macro'

import { common, dark } from './colors'
import {
  fontFamily,
  fontSize,
  inputSize,
  spacing,
  mediaBreakpoints,
  fontWeight,
  border,
  sideMenu,
} from './common/commonStyles'

export const darkTheme: DefaultTheme = {
  fontFamily,
  fontSize,
  fontWeight,
  inputSize,
  border,
  sideMenu,
  colors: {
    ...common,
    ...dark,
  },
  spacing,
  mediaBreakpoints,
}

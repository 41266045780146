import { AppTag, AppTagProps } from 'src/components/AppComponents'
import { TagWrapper } from '../TagsManager.styles'
import { MdDelete, MdEdit } from 'react-icons/md'
import { TagEditForm } from '../TagEditForm'
import { Tag, TagPayload } from 'src/types'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { patchTagAction } from 'src/store/Tags/actions/patchTagAction'
import { deleteTagAction, getTagsAction } from 'src/store'
import { Popconfirm, notification } from 'antd'
import { isEqual } from 'lodash'

type TagOptionProps = {
  tagProps: AppTagProps
  tag: Tag
  parent?: string
}

export const TagOption = ({ tagProps, tag, parent }: TagOptionProps) => {
  const dispatch = useAppDispatch()
  const isPatchLoading = useAppSelector(
    (state) => state.tags.all.patch.isLoading
  )

  const handleTagPatch = (values: TagPayload) => {
    const { name, parent, type, key } = values
    const { events, children, _id, ...tagData } = tag
    if (!isEqual(tagData, values)) {
      dispatch(
        patchTagAction({
          tagId: tag._id,
          payload: {
            name,
            parent,
            type,
            key,
          },
          onSuccess: () => {
            dispatch(getTagsAction({}))
          },
          onError: (error: any) => {
            notification.error({
              message: error.message,
            })
          },
        })
      )
    } else {
      notification.error({
        message: 'Nic się nie zmieniło :/',
      })
    }
  }

  const handleTagDelete = () => {
    dispatch(
      deleteTagAction({
        tagId: tag._id,
        onSuccess: () => {
          notification.success({
            message: `Tag '${tag.name}' został usunięty`,
          })
          dispatch(getTagsAction({}))
        },
        onError: (error: any) => {
          notification.error({
            message: error.message,
          })
        },
      })
    )
  }

  return (
    <TagWrapper>
      <AppTag {...tagProps} />
      {tagProps.isActive && (
        <>
          <TagEditForm
            name={tag.name}
            keyString={tag.key}
            parent={parent}
            type={tag.type}
            openIcon={<MdEdit />}
            onSubmit={handleTagPatch}
            loading={isPatchLoading}
          />
          <Popconfirm
            title="Czy jesteś w 100% pewien, że chcesz skasować tą kategorię?"
            onConfirm={handleTagDelete}
            okText="Tak"
            cancelText="Nie"
          >
            <AppTag
              name={<MdDelete />}
              type={tagProps.type}
              prefix=" "
              isActive={false}
            />
          </Popconfirm>
        </>
      )}
    </TagWrapper>
  )
}

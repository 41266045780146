import styled from 'styled-components/macro'

export const SingleEventImgContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  margin-bottom: 20px;
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    padding: 0;
  }
`

export const SingleEventImgBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  width: 100%;
  @media screen and (min-width: 800px) {
    max-width: 800px;
    max-height: 450px;
  }
`

export const SingleEventImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
`

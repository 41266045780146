import styled from 'styled-components/macro'

export const ExploreContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.textL};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 30px;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.heading3};
  }
  p {
    margin: 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    h1 {
      font-size: ${({ theme }) => theme.fontSize.heading2};
    }
  }
`

export const AccountsPageTextContainer = styled.div`
  padding: 0px 20px;
`

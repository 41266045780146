import { RegisterData, RegisterResp } from 'src/types'
import { createPostService } from 'src/utils/services'

type RegisterServiceArgs = {
  payload: RegisterData
}

export const registerService = createPostService<
  RegisterServiceArgs,
  RegisterResp
>({
  urlCreator: () => '/auth/register/',
})

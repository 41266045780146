import { useActiveAccount, useAppDispatch } from 'src/hooks'
import { UsersTable } from 'src/components'
import {
  EventsTable,
  SearchInput,
  TagsManager,
} from 'src/components/AdminPanel'
import {
  AdminPanelContainer,
  AdminTabs,
  SearchInputContainer,
  SearchResultsContent,
} from 'src/components/AdminPanel/styles'
import { useAppSelector } from 'src/hooks'
import { useEffect, useState } from 'react'
import { formatQueryTyped } from 'src/utils'
import { TabPaneProps } from 'antd'
import {
  eventsListSelector,
  searchAllEventsAction,
  getAccountsByQueryFULL,
  fetchedAccountsSelectorFULL,
} from 'src/store'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const AdminPanel = () => {
  const accountData = useActiveAccount()
  const [isAccountsLoading, setAccountsLoading] = useState<boolean>(true)
  const [isEventsLoading, setEventsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const [query, setQuery] = useSearchParams()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      getAccountsByQueryFULL({
        onSuccess: () => {
          setAccountsLoading(false)
        },
        query: formatQueryTyped({ adminInfo: true, limit: 1000 }),
      })
    )
  }, [])

  const accountResults = useAppSelector(fetchedAccountsSelectorFULL.selectAll)

  useEffect(() => {
    dispatch(
      searchAllEventsAction({
        onSuccess: () => {
          setEventsLoading(false)
        },
        query: formatQueryTyped({
          limit: 100,
        }),
      })
    )
  }, [])

  const eventsResults = useAppSelector(eventsListSelector.selectAll)

  const items: (Omit<TabPaneProps, 'tab'> & {
    key: string
    label: React.ReactNode
  })[] = accountData?.roles?.includes('admin')
    ? [
        {
          label: 'Users',
          children: (
            <div>
              <SearchInputContainer>
                <SearchInput />
              </SearchInputContainer>
              <SearchResultsContent>
                <UsersTable results={accountResults} />
              </SearchResultsContent>
            </div>
          ),
          key: '1',
        },
        {
          label: 'Events',
          children: (
            <SearchResultsContent>
              <EventsTable results={eventsResults} />
            </SearchResultsContent>
          ),
          key: '2',
        },
        {
          label: 'Zarządzanie Tagami',
          children: <TagsManager />,
          key: '3',
        },
      ]
    : [
        {
          label: 'Events',
          children: (
            <SearchResultsContent>
              <EventsTable results={eventsResults} />
            </SearchResultsContent>
          ),
          key: '2',
        },
      ]

  if (
    accountData == undefined ||
    (accountData !== undefined &&
      !(
        accountData?.roles?.includes('admin') ||
        accountData?.roles?.includes('mod')
      ))
  )
    return (
      <>
        <Helmet>
          <title>The user does not have administrator rights.</title>
          <meta name="robots" content="noindex" />
        </Helmet>
        <AdminPanelContainer>
          <h1>The user does not have administrator rights.</h1>
        </AdminPanelContainer>
      </>
    )

  if (isAccountsLoading || isEventsLoading) {
    return (
      <h1 style={{ verticalAlign: 'middle', textAlign: 'center' }}>
        Pobieranie danych
      </h1>
    )
  }

  return (
    <>
      <Helmet>
        <title>Admin Panel</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AdminPanelContainer>
        <div>
          <AdminTabs
            items={items}
            onTabClick={(activeTab: string) =>
              setQuery({
                tab: activeTab,
              })
            }
            defaultActiveKey={query.get('tab') ?? '1'}
          />
        </div>
      </AdminPanelContainer>
    </>
  )
}

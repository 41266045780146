import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppButton, GoBack, MetaTags } from 'src/components'
import { SignInForm } from 'src/components/SignIn'
import { ForgotPasswordButton } from 'src/components/SignIn/styles'
import { useTokenBoolean } from 'src/hooks'
import { t } from 'src/locale'
import {
  EmptyContainer,
  EntryContainer,
  FormContainer,
} from '../../components/Containers'

export const SignIn = () => {
  const navigate = useNavigate()

  const token = useTokenBoolean()

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  })

  return (
    <>
      <MetaTags
        title="Zaloguj się do swojego konta"
        desc="Przeglądaj wydarzenia z całej Polski! Wszystkie zebrane w jednym miejscu!"
        url="https://mapawiedzy.com/signin"
        image="https://mapawiedzy.com/meta-images/signin.png"
      />
      <EmptyContainer>
        <EntryContainer>
          <FormContainer>
            <h3>{t('SignInTitle')}</h3>
            <SignInForm />
            <ForgotPasswordButton
              type="link"
              onClick={() => navigate('/reset-password')}
            >
              {t('ForgotYourPassword')}
            </ForgotPasswordButton>
          </FormContainer>
          <GoBack
            question={t('DontHaveAnAccount')}
            button={
              <AppButton type="link" onClick={() => navigate('/signup')}>
                {t('SignUpSubmissionButtonText')}
              </AppButton>
            }
          />
        </EntryContainer>
      </EmptyContainer>
    </>
  )
}

import styled from 'styled-components/macro'

export const StyledNavbar = styled.nav`
  visibility: hidden;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.mediaBreakpoints.numbers.pc - 2}px`}) {
    visibility: visible !important;
    display: flex;
    place-items: center;
    flex-wrap: nowrap;
    gap: 15px;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }
`

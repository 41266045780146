import styled from 'styled-components'

export const ListContainer = styled.div`
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-flow: column nowrap;
  max-width: 800px;
  width: 100%;
  padding-top: 10px;
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    padding: 0;
    padding-bottom: 20px;
  }
`

export const ListTitle = styled.h2`
  max-width: 800px;
  width: 100%;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.textXL};
  padding: 20px;
  padding-top: 0;

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    padding: 0;
    padding-bottom: 20px;
  }
`

export const ListBox = styled.div`
  width: 100%;
  place-items: center;
  display: flex;
  flex-flow: column nowrap;
  padding-top: 20px;
`

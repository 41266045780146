import { useNavigate } from 'react-router-dom'
import { AppButton, AppPopconfirm } from 'src/components'
import {
  SettingsContainer,
  MainContainer,
  ButtonBox,
  ButtonBoxElement,
} from 'src/components/Settings'
import { t } from 'src/locale'
import { notification } from 'antd'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { useActiveAccount, useAppDispatch, useTokenBoolean } from 'src/hooks'
import { deleteActiveAccountByIdAction, logout } from 'src/store'
import { Helmet } from 'react-helmet'
import { UserForm } from 'src/components/Settings/UserForm'
import { E404 } from '../E404'
import { AccountForm } from 'src/components/Settings/AccountForm'
import { ChangeEmailForm } from 'src/components/Settings/ChangeEmailForm'

export const Settings = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const activeAccount = useActiveAccount()
  const isLoggedIn = useTokenBoolean()
  const confirm2 = () => {
    if (activeAccount?._id)
      dispatch(
        deleteActiveAccountByIdAction({
          accountId: activeAccount._id,
          onSuccess: () => {
            dispatch(logout())
            navigate('/account/deleted/')
          },
          onError: (err: any) => {
            notification.error({ message: err })
          },
        })
      )
  }
  if (!isLoggedIn || !!!activeAccount) {
    navigate('/')
    return <E404 />
  }
  return (
    <>
      <Helmet>
        <title>{t('settings')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <MainContainer>
        <h1>{t('settings')}</h1>
        <AccountForm account={activeAccount} />
        <UserForm account={activeAccount} />
        <SettingsContainer>
          <ButtonBoxElement
            label={t('ChangeEmail')}
            content={<ChangeEmailForm account={activeAccount} />}
          />
          <ButtonBox
            label={t('changePassword')}
            comment={t('changePasswordComment')}
            button={
              <AppButton onClick={() => navigate('/reset-password')}>
                {t('changePassword')}
              </AppButton>
            }
          />
          <ButtonBox
            label={t('deleteAccount')}
            comment={t('deleteAccountComment')}
            button={
              <AppPopconfirm
                title={t('confirmAccountDelete')}
                onConfirm={confirm2}
                icon={<AiOutlineQuestionCircle />}
                showCancel
                cancelText={t('cancel')}
                okText={t('confirm')}
                okButtonProps={{ danger: true }}
                cancelButtonProps={{
                  ghost: true,
                }}
              >
                <AppButton danger>{t('deleteAccount')}</AppButton>
              </AppPopconfirm>
            }
            danger
          />
        </SettingsContainer>
      </MainContainer>
      <br />
    </>
  )
}

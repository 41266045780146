import { t } from 'src/locale'
import * as Yup from 'yup'

export const accountFormValidationSchema = Yup.object().shape({
  name: Yup.string().required(`${t('AccountName')}: ${t('required')}`),
  username: Yup.string()
    .min(4, `${t('Username')}: ${t('tooShort4Chars')}`)
    .max(32, `${t('Username')}: ${t('tooLongUsername')}`)
    .matches(/^[a-z0-9_.-]*$/, `${t('Username')}: ${t('UsernameValidation')}`)
    .required(`${t('Username')}: ${t('required')}`),
  bio: Yup.object({
    link: Yup.string().matches(
      /^(http|https):\/\//i,
      `${t('Website')}: ${t('NotValidUrl')}`
    ),
    text: Yup.string().max(150, `${t('Bio')}: ${t('TextIsTooLong')}`),
  }),
})

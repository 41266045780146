import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAccountsByQueryServiceFULL } from 'src/services/Account/'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type GetAccountsArgs = {
  query?: string
  token?: string
  onSuccess?: OnSuccess
  onError?: OnError
}

type getAccountsByQueryFULLResp = {
  total: number
  data: Account[]
}

export const getAccountsByQueryFULL = createAsyncThunk<
  getAccountsByQueryFULLResp,
  GetAccountsArgs,
  { rejectValue: ApiError }
>(
  'account/getAccountsByQueryFULL',
  async ({ token, query, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const results = getAccountsByQueryServiceFULL(
        { token, query },
        { signal }
      )
      onSuccess?.()
      return results
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import { AiOutlineEye } from 'react-icons/ai'
import { t } from 'src/locale'
import { CTAButton } from 'src/types'
import { AppButton } from '../AppComponents'

export const CTASeeMore: React.FC<CTAButton> = ({ onClick, disabled }) => {
  return (
    <AppButton
      disabled={disabled}
      icon={<AiOutlineEye size={18} style={{ marginRight: '5px' }} />}
      onClick={onClick}
    >
      {t('CTASeeMore')}
    </AppButton>
  )
}

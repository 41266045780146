import styled from 'styled-components/macro'

export const FormContainer = styled.div<{ width?: number }>`
  width: 100%;
  ${({ width }) =>
    width &&
    `
   max-width:  ${width}px;`}
  padding: 25px;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: ${({ theme }) => theme.border.radius};
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.heading4};
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding: 20px;
  }
`

import styled from 'styled-components'

export const PatronTagBox = styled.div<{ xs?: boolean }>`
  font-size: ${({ theme, xs }) =>
    xs ? theme.fontSize.textXS : theme.fontSize.textS};
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.green.main};
  padding: ${({ xs }) => (xs ? '0px 5px' : '2px 5px')};
  border-radius: 5px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`

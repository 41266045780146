import { TfiWorld } from 'react-icons/tfi'
import { t } from 'src/locale'
import { CTAButton } from 'src/types'
import { AppButton } from '../AppComponents'

export const CTAWebsite: React.FC<CTAButton> = ({ onClick, disabled }) => {
  return (
    <AppButton
      disabled={disabled}
      icon={<TfiWorld size={18} style={{ marginRight: '5px' }} />}
      onClick={onClick}
    >
      {t('CTAWebsite')}
    </AppButton>
  )
}

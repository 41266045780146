import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AppButton, AppTabs, MetaTags } from 'src/components'
import { SearchInput, SearchResults } from 'src/components/AccountsPage'
import { ExploreAccounts } from 'src/components/AccountsPage/ExploreAccounts'
import {
  AccountsPageTextContainer,
  ExploreContainer,
  SearchResultsContainerHeader,
  SearchResultsContent,
} from 'src/components/AccountsPage/styles'
import { SearchInputContainer } from 'src/components/AccountsPage/styles/SearchInputContainer'
import { SearchResultsContainer } from 'src/components/AccountsPage/styles/SearchResults/SearchResultsContainer'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { t } from 'src/locale'
import { getAccountsByQuery } from 'src/store'
import { fetchedAccountsSelector } from 'src/store/Account/fetchedAccountsSlice'
import { formatQueryTyped } from 'src/utils'

export const Accounts = () => {
  const startLimit = 100
  const adjustLimitBy = 100

  const accountResults = useAppSelector(fetchedAccountsSelector.selectAll)
  const [accounstLimit, setAccountsLimit] = useState<number>(startLimit)
  const [search, setSearch] = useState<string>('')

  const total = useAppSelector((state) => state.account.fetchedAccounts.total)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      getAccountsByQuery({
        query: formatQueryTyped({
          arg: '',
          limit: accounstLimit,
        }),
      })
    )
  }, [])

  const sendRequest = ({
    otherLimit,
    otherSearch,
  }: {
    otherLimit?: number
    otherSearch?: string
  }) => {
    if (otherLimit && otherLimit !== accounstLimit) setAccountsLimit(otherLimit)
    dispatch(
      getAccountsByQuery({
        query: formatQueryTyped({
          arg: otherSearch ? otherSearch : search,
          limit: otherLimit ? otherLimit : accounstLimit,
        }),
      })
    )
  }

  const metaTitle = 'Przeglądaj profile na Mapie Wiedzy'
  const metaDesc =
    'Poznaj organizatorów i uczestników wydarzeń edukacyjnych z całej Polski.'
  const metaUrl = 'https://mapawiedzy.com/accounts/'
  const metaImage = 'https://mapawiedzy.com/meta-images/accounts.png'

  return (
    <>
      <MetaTags
        title={metaTitle}
        desc={metaDesc}
        url={metaUrl}
        image={metaImage}
      />
      <ExploreContainer>
        <AccountsPageTextContainer>
          <h1>{t('AccountsPageTitle')}</h1>
          <p>{t('AccountsPageParagraph')}</p>
        </AccountsPageTextContainer>
        <SearchInputContainer>
          <SearchInput
            setSearch={setSearch}
            sendRequest={sendRequest}
            startLimit={startLimit}
          />
        </SearchInputContainer>
        <SearchResultsContainer>
          <ExploreAccounts results={accountResults} />
          {accounstLimit < total && (
            <AppButton
              onClick={async () => {
                sendRequest({ otherLimit: accounstLimit + adjustLimitBy })
              }}
            >
              {t('LoadMore')}
            </AppButton>
          )}
        </SearchResultsContainer>
      </ExploreContainer>
    </>
  )
}

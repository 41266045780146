import { EventSkeleton, EventSkeletonTitle } from './EventsSkeleton.styles'

export const EventsSkeleton = () => {
  return (
    <>
      <EventSkeletonTitle active={true} />
      <EventSkeleton active={true} />
      <EventSkeleton active={true} />
      <EventSkeleton active={true} />
      <EventSkeleton active={true} />
    </>
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getOneEventByCleanIdService } from 'src/services'
import { ApiError, Event, OnError, OnSuccess } from 'src/types'

type GetOneEventByCleanIdActionArgs = {
  eventId: number
  onSuccess?: OnSuccess
  onError?: OnError
}

export const getOneEventByCleanIdAction = createAsyncThunk<
  Event,
  GetOneEventByCleanIdActionArgs,
  { rejectValue: ApiError }
>(
  'events/getOneEventByCleanId',
  async ({ onSuccess, onError, eventId }, { rejectWithValue, signal }) => {
    try {
      const result = await getOneEventByCleanIdService({ eventId }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

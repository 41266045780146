import { createAsyncThunk } from '@reduxjs/toolkit'
import { loginService } from 'src/services/Account'
import { ApiError, Token } from 'src/types'
import { OnError, OnSuccess } from 'src/types/Api/ActionArgs'

type loginData = {
  payload: {
    email: string
    password: string
  }
  onSuccess?: OnSuccess
  onError?: OnError
}

export const login = createAsyncThunk<
  Token,
  loginData,
  { rejectValue: ApiError }
>(
  'account/login',
  async ({ payload, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await loginService({ payload }, { signal })
      onSuccess?.()
      return result as Token
    } catch (err: any) {
      onError?.(err)
      return rejectWithValue(err.response.data.message)
    }
  }
)

import styled from "styled-components";

export const PatronCardContainer = styled.div`
   background: ${({theme}) => theme.colors.background.secondary};
   border-radius: 15px;
   display: flex;
   place-items: flex-start;
   justify-content: center;
   flex-flow: row nowrap;
   padding: 10px;
   transform: scale3d(1, 1, 1);
   transition: 0.3s;
   cursor: pointer;
   :hover {
      transform: scale3d(1.05, 1.05, 1.05);
      transition: 0.3s;
   }
   width: 250px;
`

export const PatronCardContent = styled.div`
   display: flex;
   place-items: center;
   flex-flow: column nowrap;
   gap: 5px;
   h3 {
      font-size: ${({theme}) => theme.fontSize.textM};
   }
   p {
      font-size: ${({theme}) => theme.fontSize.textS};
      color: ${({theme}) => theme.colors.fontColor.secondary};
   }
`

export const PatronImage = styled.div`
   overflow: hidden;
   object-fit: cover;
   border-radius: 50%;
   max-width: 150px;
   max-height: 150px;
   border: 1px solid ${({theme}) => theme.colors.purple.main};
   img {
      width: 150px;
      height: 150px;
   }
`


import { AppTabs } from 'src/components'
import styled from 'styled-components'

export const SingleEventTabs = styled(AppTabs)`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  border-bottom: none !important;
  .ant-tabs-nav {
    margin-bottom: 20px !important;
  }
  .ant-tabs-nav-wrap {
    padding: 0 15px;
    background: ${({ theme }) => theme.colors.background.secondary};
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
  }
  .ant-tabs-tab-btn {
    font-size: ${({ theme }) => theme.fontSize.textM};
    height: 30px;
    display: flex;
    place-items: center;
    padding: 0px 10px;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    padding: 0px 10px;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
  .ant-tabs-tab-disabled .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.disabled.background};
    :hover {
      color: ${({ theme }) => theme.colors.disabled.background};
    }
  }
`

import styled from 'styled-components'

export const StyledShortLogoContainer = styled.div`
  height: 100%;
  width: fit-content;
  img {
    height: 40px;
    width: fit-content;
  }
  @media screen and (min-width: ${({ theme }) =>
      `${theme.mediaBreakpoints.numbers.pc + 1}px`}) {
    img {
      height: 50px;
    }
  }
`

export const YupValidationErrors = {
  required: 'To pole jest wymagane',
  invalidEmail: 'Wprowadź właściwy adres email',
  tooShort2Chars: 'Wprowadź conajmniej 2 znaki',
  tooLong50Chars: 'Pole nie moze przekraczać 50 znaków',
  tooShort4Chars: 'Twoja nazwa uzytkownika musi mieć conajmniej 4 znaki',
  tooLongUsername: 'Twoja nazwa uzytkownika musi miec mniej niz 50 znaków',
  tooShortPassword: 'Twoje hasło musi miec conajmniej 8 znaków',
  passRequiresNumber: 'Twoje hasło musi zawierać conajmniej jedną cyfrę',
  passRequiresLowercase:
    'Twoje hasło musi zawierać conajmniej jedną małą literę',
  passRequiresUppercase:
    'Twoje hasło musi zawierać conajmniej jedną wielką literę',
  passRequiresSymbol:
    'Twoje hasło musi zawierać conajmniej jednen symbol specjalny',
  passDoNotMatch: 'Hasła się nie zgadzają',
  StartDateCannotBeLaterThanEndDate:
    'Data początkowa musi być wcześniej niż data końcowa.',
  EndDateMustBeLaterThanStartDate:
    'Data końcowa musi być później niż data początkowa.',
  youNeedToAcceptRules: 'Musisz zakceptować regulamin',
  UsernameDoesNotMatch:
    "Nazwa użytkownika nie jest poprawna. Dozwolone znaki: małe litery a-z, cyfry oraz znaki '.' oraz '_'",
}

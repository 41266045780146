import { Progress } from 'antd'
import styled from 'styled-components'

export const AppProgress = styled(Progress)`
  .ant-progress-inner {
    background: ${({ theme }) => theme.colors.borderColor};
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
  }
  .ant-progress-bg {
    background: ${({ theme }) => theme.colors.purple.main};
  }
`

import { AiFillHome } from '@react-icons/all-files/ai/AiFillHome'
import { AiFillTag } from '@react-icons/all-files/ai/AiFillTag'
import { FaMapMarkedAlt } from '@react-icons/all-files/fa/FaMapMarkedAlt'
import { ReactElement } from 'react'
import { MdForum, MdPeopleAlt, MdWavingHand } from 'react-icons/md'
import { t } from 'src/locale'
import { StyledNavbarItem } from '../Footers/MobileFooter/styles'
import { StyledNavbar } from './styles/StyledNavbar'

type NavbarPaths = {
  [key: string]: {
    path: string
    disabled?: boolean
    label: string
    icon: ReactElement
  }
}

export const Navbar = () => {
  const paths: NavbarPaths = {
    home: {
      path: '/',
      label: t('home'),
      icon: <AiFillHome size={24} />,
    },
    explore: {
      path: '/accounts/',
      label: t('accounts'),
      icon: <MdPeopleAlt size={24} />,
    },
    about: {
      path: '/about-us',
      label: t('AboutUs'),
      icon: <MdWavingHand size={24} />,
    },
    offers: {
      path: '/offers/',
      label: t('offers'),
      icon: <AiFillTag size={24} />,
      disabled: true,
    },
    map: {
      path: '/map/',
      label: t('map'),
      icon: <FaMapMarkedAlt size={24} />,
      disabled: true,
    },
  }

  const renderPaths = Object.values(paths).map((item) => (
    <StyledNavbarItem
      key={item.label}
      path={item.path}
      label={item.label}
      icon={item.icon}
      disabled={item.disabled}
    />
  ))

  return <StyledNavbar>{renderPaths}</StyledNavbar>
}

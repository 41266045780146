import { createAsyncThunk } from '@reduxjs/toolkit'
import { postResetPasswordEmailService } from 'src/services'
import { ApiError, OnError, OnStart, OnSuccess } from 'src/types'

type postResetPasswordEmailArgs = {
  payload: {
    email: string
  }
  onStart?: OnStart
  onSuccess?: OnSuccess
  onError?: OnError
}

export const postResetPasswordEmail = createAsyncThunk<
  undefined,
  postResetPasswordEmailArgs,
  { rejectValue: ApiError }
>(
  'account/verifyEmail',
  async (
    { payload, onSuccess, onError, onStart },
    { rejectWithValue, signal }
  ) => {
    try {
      onStart?.()
      const result = await postResetPasswordEmailService(
        { payload },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

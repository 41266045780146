const purple = {
  main: '#7856FF',
  hover: '#9980FF',
  pressed: '#AE99FF',
  alpha: '#7856FF44',
}

const red = {
  main: '#FF1971',
}

const blue = {
  main: '#1991FF',
}

const yellow = {
  main: '#FFD400',
}

export const common = {
  purple,

  disabled: {
    color: '#40404F',
    background: '#9090B0',
  },

  red,
  blue,
  yellow,

  white: '#ffffff',
  black: '#121216',

  danger: red,

  info: purple,
}

import { NotificationMenuMobileIcon } from 'src/icons'
import styled from 'styled-components/macro'

export const StyledNotificationMenuMobile = styled(NotificationMenuMobileIcon)`
  display: block !important;

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    display: none !important;
  }
`

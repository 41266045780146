import { Divider } from "antd";
import styled from "styled-components";

export const StyledDivider = styled(Divider)`
   &.ant-divider-with-text:before, &.ant-divider-with-text:after {
      border-top-color: ${({theme}) => theme.colors.borderColor};
      
   }
   svg {
      color: ${({theme}) => theme.colors.borderColor};
   }
`
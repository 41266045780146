import { createAsyncThunk } from '@reduxjs/toolkit'
import { postOneEventByIdService } from 'src/services'
import { ApiError, Event, OnError, OnSuccess } from 'src/types'

type postOneEventByIdActionArgs = {
  payload: {
    author: string
  }
  onSuccess?: (result: Event) => void
  onError?: OnError
}

export const postOneEventByIdAction = createAsyncThunk<
  Event,
  postOneEventByIdActionArgs,
  { rejectValue: ApiError }
>(
  'event/postOne',
  async ({ payload, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await postOneEventByIdService({ payload }, { signal })
      onSuccess?.(result)
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

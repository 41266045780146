import styled from 'styled-components'

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  font-size: ${({ theme }) => theme.fontSize.textL};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  h1 {
    font-size: ${({ theme }) => theme.fontSize.heading3};
  }
  p {
    margin: 0;
  }
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    h1 {
      font-size: ${({ theme }) => theme.fontSize.heading2};
    }
  }
`

import { Container, Logo } from './EntryContainer.styles'
import { LongLogo } from '../../Logo'
import { useNavigate } from 'react-router-dom'

type EntryContainerProps = {
  children?: React.ReactNode
}

export const EntryContainer: React.FC<EntryContainerProps> = ({ children }) => {
  const navigate = useNavigate()
  return (
    <Container>
      <>
        <Logo onClick={() => navigate('/')}>
          <LongLogo />
        </Logo>
        {children}
      </>
    </Container>
  )
}

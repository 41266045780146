import { useNavigate } from "react-router-dom"
import { Account } from "src/types"
import { PatronCardContainer, PatronCardContent, PatronImage } from "./styles/PatronCard.styles"

type PatronCardProps = {
   account: Account
}

export const PatronCard = ({ account }: PatronCardProps) => {
   const navigate = useNavigate()

   return (
      <PatronCardContainer onClick={() => navigate(`/@${account.username}`)}>
         <PatronCardContent>
            <PatronImage>
               <img src={account.avatar?.light_url || `/${localStorage.getItem('theme') === 'light' ? 'light' : 'dark'}-temporary.webp`} alt={account.name} />
            </PatronImage>
            <h3>{account.name}</h3>
            <p>{account.bio?.text}</p>
         </PatronCardContent>
      </PatronCardContainer>
   )
}
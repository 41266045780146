import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  AppButton,
  AppSelect,
  InputBlock,
  AppPopconfirm,
} from 'src/components/AppComponents'
import { useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { deleteOneEventByIdAction } from 'src/store'
import { InputLabel } from '../styles/EditSingleEventForm.styles'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

type EventAdditionalOptionsProps = {
  eventId: string
}

export const EventAdditionalOptions: React.FC<EventAdditionalOptionsProps> = ({
  eventId,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const privacyOptions = [
    {
      label: t('Public'),
      value: 'public',
    },
    {
      label: t('Private'),
      value: 'private',
    },
    {
      label: t('PrivacyOnlyWithTheLink'),
      value: 'link',
    },
  ]

  const deleteEvent = () => {
    dispatch(
      deleteOneEventByIdAction({
        eventId,
        onError: (error) => {
          notification.error(error)
        },
        onSuccess: () => {
          navigate('/')
        },
      })
    )
  }

  return (
    <InputBlock $twoColumns danger>
      <InputLabel>
        <span>{t('Privacy')}</span>
        <p>{t('EventPrivacyWarning')}</p>
        <AppSelect
          name="privacy"
          options={privacyOptions}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
        />
      </InputLabel>
      <InputLabel>
        <span>{t('EventDelete')}</span>
        <p>{t('EventDeleteWarning')}</p>
        <AppPopconfirm
          title={t('confirmEventDelete')}
          onConfirm={deleteEvent}
          icon={<AiOutlineQuestionCircle />}
          showCancel
          cancelText={t('cancel')}
          okText={t('confirm')}
          okButtonProps={{ danger: true }}
          cancelButtonProps={{
            ghost: true,
          }}
        >
          <AppButton danger>{t('Delete')}</AppButton>
        </AppPopconfirm>
      </InputLabel>
    </InputBlock>
  )
}

type DropdownItemProps = {
  title: string
  content: string
  onClick?: () => void
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  title,
  content,
  onClick,
}) => {
  return (
    //to make this a link leading to somewhere just turn the <></> tags into a navigation tag
    <div onClick={onClick}>
      <h5>{title}</h5>
      {content}
    </div>
  )
}

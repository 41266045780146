import { ReactElement } from 'react'
import { ButtonBoxContainer, Left, Right } from './ButtonBox.styles'

type ButtonBoxElementProps = {
  label?: string
  content?: ReactElement
  danger?: boolean
}

export const ButtonBoxElement: React.FC<ButtonBoxElementProps> = ({
  label,
  content,
  danger,
}) => (
  <ButtonBoxContainer $danger={danger}>
    <Left $danger={danger}>
      <span>{label}</span>
      {content}
    </Left>
  </ButtonBoxContainer>
)

import {
  AppTag,
  SmallHeader,
  TagsSelectorWrapper,
  TagsWrapper,
} from 'src/components'
import { MdAttachMoney, MdHistory, MdMoneyOff } from 'react-icons/md'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import {
  eventsFeedQuerySelector,
  setFinishedQuery,
  setFreeQuery,
} from 'src/store'

export const AdditionalQuery = () => {
  const dispatch = useAppDispatch()
  const { free, finished } = useAppSelector(eventsFeedQuerySelector)

  const handleFreeOnly = () => {
    if (free === true) {
      dispatch(setFreeQuery(undefined))
    } else {
      dispatch(setFreeQuery(true))
    }
  }

  const handlePaidOnly = () => {
    if (free === false) {
      dispatch(setFreeQuery(undefined))
    } else {
      dispatch(setFreeQuery(false))
    }
  }

  const handleFinished = () => {
    if (finished === true) {
      dispatch(setFinishedQuery(undefined))
    } else {
      dispatch(setFinishedQuery(true))
    }
  }

  return (
    <TagsSelectorWrapper>
      <SmallHeader>Dodatkowe filtry</SmallHeader>
      <TagsWrapper>
        <AppTag
          type="mode"
          name="Tylko darmowe"
          prefix={<MdMoneyOff />}
          onClick={handleFreeOnly}
          isActive={free === true}
        />
        <AppTag
          type="category"
          name="Tylko płatne"
          prefix={<MdAttachMoney />}
          onClick={handlePaidOnly}
          isActive={free === false}
        />
        <AppTag
          type="category"
          name="Wcześniejsze wydarzenia"
          prefix={<MdHistory />}
          onClick={handleFinished}
          isActive={finished === true}
        />
      </TagsWrapper>
    </TagsSelectorWrapper>
  )
}

import { Tooltip } from 'antd'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { NavbarItemContentContainer } from './styles/NavbarItemContentContainer'
import { t } from 'src/locale'

type NavbarItemProps = {
  label: string
  disabled?: boolean
  icon: ReactElement
  path: string
}

export const NavbarItem: React.FC<NavbarItemProps> = ({
  path,
  icon,
  label,
  disabled,
}) => {
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
  const theme = useTheme()
  const handleClick = (e: React.MouseEvent) => {
    if (disabled) e.preventDefault()
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth)
    })
  }, [])

  const isMobile = screenWidth < theme.mediaBreakpoints.numbers.pc

  if (disabled) {
    return (
      <Tooltip placement={isMobile ? 'top' : 'bottom'} title={t('comingSoon')}>
        <NavbarItemContentContainer
          to={path}
          onClick={handleClick}
          $disabled={disabled}
        >
          {icon}
          {label}
        </NavbarItemContentContainer>
      </Tooltip>
    )
  }

  return (
    <NavbarItemContentContainer
      to={path}
      onClick={handleClick}
      $disabled={disabled}
    >
      {icon}
      {label}
    </NavbarItemContentContainer>
  )
}

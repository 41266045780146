import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components/macro'

export const StyledLoadingIcon = styled(LoadingOutlined)<{ size?: number }>`
  font-size: ${({ size }) => (size ? `${size}px` : '30px')};
  color: ${({ theme }) => theme.colors.purple.main};
  .ant-spin-nested-loading {
    max-width: 100%;
  }
`

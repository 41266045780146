import { Steps, useSteps } from 'react-step-builder'
import { ProvideEmailStep, TellUsMoreStep } from './SignUpSteps/index'

type SignUpStepsArgs = {}

export const SignUpSteps: React.FC<SignUpStepsArgs> = () => {
  const stepManager = useSteps()

  return (
    <Steps>
      <ProvideEmailStep handleNextStep={stepManager.next} />
      <TellUsMoreStep handlePreviousStep={stepManager.prev} />
    </Steps>
  )
}

import {
  Box,
  Text,
  Divider,
  RightCol,
  Title,
  TitleBox,
  CentralCol,
  ButtonsBox,
} from './styles'
import { AppButton } from 'src/components/AppComponents'
import { useNavigate } from 'react-router-dom'

export const Welcome = () => {
  const navigate = useNavigate()

  return (
    <CentralCol>
      <Box>
        <TitleBox>
          <Title>Odkrywaj.</Title>
          <Title>Rozwijaj się.</Title>
          <Title>Działaj.</Title>
        </TitleBox>
        <Text>
          Przeglądaj dziesiątki wydarzeń, webinarów i powtórek. Porównuj oferty
          i organizuj swój czas. Wszystko w jednym miejscu.
        </Text>
        <ButtonsBox>
          <AppButton type="primary" onClick={() => navigate('/signup')}>
            Dołącz teraz!
          </AppButton>
          <AppButton type="ghost" onClick={() => navigate('/')}>
            Zobacz wydarzenia
          </AppButton>
        </ButtonsBox>
      </Box>
    </CentralCol>
  )
}

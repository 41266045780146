import { Event } from 'src/types'
import { SingleEventBodyContainer } from './SingleEventBody.styles'
import { SingleEventTabs } from '../SingleEventTabs'
import { SingleEventDetails } from '../SingleEventDetails'
import { t } from 'src/locale'
import { TabPaneProps, Tooltip } from 'antd'

type SingleEventBodyProps = {
  event: Event | null
}

export const SingleEventBody: React.FC<SingleEventBodyProps> = ({ event }) => {
  if (event === null) return <></>

  const items: (Omit<TabPaneProps, 'tab'> & {
    key: string
    label: React.ReactNode
  })[] = [
    {
      label: t('Details'),
      children: <SingleEventDetails event={event} />,
      key: '1',
    },
    {
      label: (
        <Tooltip placement="bottom" title={t('comingSoon')}>
          {t('Discussion')}
        </Tooltip>
      ),
      disabled: true,
      key: '2',
    },
    {
      label: (
        <Tooltip placement="bottom" title={t('comingSoon')}>
          {t('Reviews')}
        </Tooltip>
      ),
      disabled: true,
      key: '3',
    },
  ]

  return (
    <SingleEventBodyContainer>
      <SingleEventTabs items={items} />
    </SingleEventBodyContainer>
  )
}

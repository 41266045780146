import { createDeleteService } from 'src/utils/services'

type deleteOneAccountByIdServiceArgs = {
  accountId: string
}

export const deleteOneAccountByIdService = createDeleteService<
  deleteOneAccountByIdServiceArgs,
  undefined
>({
  urlCreator: ({ accountId }) => `/accounts/${accountId}/`,
})

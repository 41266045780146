import { AppLoading } from '../AppLoading'
import { AppTagWrapper } from './AppTag.styles'
import { AppTagType } from './types'

export type AppTagProps = {
  type: AppTagType
  isActive?: boolean
  prefix?: React.ReactNode
  name: React.ReactNode
  onClick?: () => void
  loading?: boolean
}

export const AppTag = ({
  type,
  prefix,
  name,
  isActive,
  onClick,
  loading = false,
}: AppTagProps) => {
  const prefixIcon = type === 'tag' && !prefix ? '#' : prefix

  return (
    <AppTagWrapper
      type={type}
      $isActive={isActive}
      onClick={onClick}
      $isClickable={!!onClick}
    >
      <AppLoading spinning={loading}>
        {prefixIcon}
        {name}
      </AppLoading>
    </AppTagWrapper>
  )
}

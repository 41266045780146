import { notification } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AppButton, AppModal } from 'src/components/AppComponents'
import { Event } from 'src/types'
import { flatTagsArray } from 'src/utils/Tags/flatTagsArray'
import { EventModToolsWrapper } from './EventModTools.styles'

type EventModToolsProps = {
  event: Event | null
}

export const EventModTools = ({ event }: EventModToolsProps) => {
  const [open, setOpen] = useState(false)

  const handleCopyClick = () => {
    navigator.clipboard.writeText(
      `https://link.mapawiedzy.com/wydarzenie/${event?.eventId}`
    )
    notification.success({
      message: 'Skopiowano turbo link do wydarzenia',
    })
  }

  const handleCopyOfTurboDesc = () => {
    const flatArr = flatTagsArray(
      event?.tags?.filter((tag) => ['tag', 'category'].includes(tag.type)) || []
    )

    const tagsString = flatArr.map((tag) => `#${tag.name}`).join(' ')

    const desc = `📆${moment(event?.terms?.[0].from).format(
      'DD.MM.YYYY HH:mm'
    )}\n${event?.title || ''}\n@${
      event?.author.name || ''
    }\n${tagsString}\n\n🔗 https://link.mapawiedzy.com/wydarzenie/${
      event?.eventId
    }
    `

    navigator.clipboard.writeText(desc)

    notification.success({
      message: 'Skopiowano MEGA OPIS wydarzenia',
    })
  }

  return (
    <>
      <AppButton
        onClick={() => {
          setOpen(true)
        }}
      >
        Strefa PRO
      </AppButton>
      <AppModal
        title="Strefa PRO"
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <EventModToolsWrapper>
          <AppButton onClick={handleCopyOfTurboDesc}>MEGA OPIS</AppButton>
          <AppButton onClick={handleCopyClick}>MEGA TURBO LINK</AppButton>
        </EventModToolsWrapper>
      </AppModal>
    </>
  )
}

import styled from 'styled-components/macro'

export const SettingsContainer = styled.div<{ width?: number }>`
  width: 100%;
  max-width: 800px;
  padding: 25px;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: ${({ theme }) => theme.border.radius};
  text-align: Left;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px;
  h1 {
    font-size: ${({ theme }) => theme.fontSize.heading3};
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding: 20px;
  }
`

export const SettingsTitle = styled.div`
  h2 {
    font-size: ${({ theme }) => theme.fontSize.heading5};
    padding: 0;
  }
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  gap: 15px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    flex-flow: row;
  }
`

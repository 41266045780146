import { InfoCircleFilled, CheckCircleFilled } from '@ant-design/icons'
import styled from 'styled-components/macro'
import { MessageType } from './types'

type AppFormItemExplainProps = {
  message?: string | null
  messageType?: MessageType
  isValue: boolean
  error?: string
  touched?: boolean
}

const StyledMessages = styled.div<{
  type: 'info' | 'success' | 'danger'
  isValue: boolean
}>`
  color: ${({ theme, type }) => theme.colors[type]?.main};
  display: flex;
  align-items: center;
  padding: 1px 8px 0 8px;
  font-size: ${({ theme }) => theme.fontSize.textM};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  transition: 1s ease-out;

  p {
    margin: 0;
    .anticon {
      padding-right: 8px;
    }
  }
`

const icons = {
  info: <InfoCircleFilled />,
  success: <CheckCircleFilled />,
  error: <InfoCircleFilled />,
}

const colors = {
  info: 'info',
  error: 'danger',
  success: 'success',
}

export const AppFormItemExplain: React.FC<AppFormItemExplainProps> = ({
  message,
  messageType = 'info',
  isValue = false,
  touched,
}) => {
  const iconRender = icons[messageType] || <InfoCircleFilled />
  const type = colors[messageType] as 'info' | 'success' | 'danger'

  const messageRender = (
    <StyledMessages type={type} isValue={isValue}>
      <p>
        <span>{iconRender}</span>
        {message}
      </p>
    </StyledMessages>
  )
  return (
    <>
      {touched ? (
        <div className="ant-form-item-explain">{messageRender}</div>
      ) : (
        <div></div>
      )}
    </>
  )
}

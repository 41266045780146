import { StyledImage } from 'src/components/Profile'
import styled from 'styled-components/macro'

export const SearchResultAvatar = styled.img`
  border: solid 1px ${({ theme }) => theme.colors.borderColor};
  border-radius: ${({ theme }) => theme.border.radiusXL};
  width: 32px;
  height: 32px;

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    width: 48px;
    height: 48px;
  }
`

import { MdVerified } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Account, SearchResultType } from 'src/types'
import {
  SearchResultAvatar,
  SearchResultContent,
  SearchResultNameWrapper,
  SearchResultUsername,
} from './styles'
import { SearchResultContainer } from './styles/SearchResultContainer'

type SearchResultProps = {
  result: Account
}

export const SearchResult: React.FC<SearchResultProps> = ({ result }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/@${result.username}`)
  }

  return (
    <>
      <SearchResultContainer
        onClick={handleClick}
        $patron={result.roles?.includes('patron')}
      >
        <SearchResultAvatar
          src={
            result?.avatar?.light_url
              ? result?.avatar?.light_url
              : `/${localStorage.getItem('theme') === 'light' ? 'light' : 'dark'}-temporary.webp`
          }
        />
        <SearchResultContent>
          <SearchResultUsername>
            @{result.username} {result.isUserVerified && <MdVerified />}
          </SearchResultUsername>
          <SearchResultNameWrapper>{result.name}</SearchResultNameWrapper>
        </SearchResultContent>
      </SearchResultContainer>
    </>
  )
}

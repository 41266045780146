import styled from 'styled-components/macro'

export const AppHeaderProfileButtonContainer = styled.button<{
  $active?: boolean
}>`
  border: none;
  background: transparent;
  padding: 5px 10px;
  color: ${({ theme, $active }) =>
    $active ? theme.colors.purple.main : theme.colors.fontColor.primary};
  border-radius: 15px;
  display: flex;
  place-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  transition: 0.3s;

  &:hover {
    color: ${({ theme }) => theme.colors.purple.main};
    transition: 0.3s;
  }
`
export const AppHeaderProfileButtonAvatar = styled.div<{
  $active?: boolean
}>`
  border-radius: 50%;
  object-fit: cover;
  max-width: 40px;
  max-height: 40px;
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    ${({ $active, theme }) =>
      $active
        ? `border: 2px solid ${theme.colors.purple.main};`
        : `border: 1px solid ${theme.colors.borderColor};`}
  }

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    max-width: 45px;
    max-height: 45px;

    img {
      width: 45px;
      height: 45px;
    }
  }
`

import { createEntityAdapter, createSlice, Slice } from '@reduxjs/toolkit'
import { SliceStatus, Account, RootState } from 'src/types'
import { getPatronsByQueryAction } from './actions'

type PatronsSliceData = {
  status: SliceStatus
  total: number
}

export const patronsAdapter = createEntityAdapter<Account>({
  selectId: ({ _id }) => _id,
})

export const patronsSlice = createSlice({
  name: 'patrons',
  initialState: patronsAdapter.getInitialState<PatronsSliceData>({
    status: {
      isLoading: false,
      error: null,
    },
    total: 0,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPatronsByQueryAction.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(getPatronsByQueryAction.fulfilled, (state, action) => {
      patronsAdapter.setAll(state, action.payload.data)
      state.status.isLoading = false
      state.status.error = null
      state.total = action.payload.total
    })
    builder.addCase(getPatronsByQueryAction.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
  },
})

export const patronsSelector = patronsAdapter.getSelectors<RootState>(
  (state) => state.account.patrons
)

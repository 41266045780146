import styled from 'styled-components'

const changeGridLayoutBreakpoint = '800px'
const mobileGridLayoutBreakpoint = '555px'

export const ExploreContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  place-items: center;
  flex-flow: column nowrap;
  padding-top: 50px;
  padding-bottom: 50px;
`

export const ExploreHeadTitle = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.purple.main};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.heading4};
`

export const ExploreGrid = styled.div`
  width: 100%;
  display: flex;
  place-items: center;
  gap: 25px;
  flex-flow: column nowrap;
  padding: 0px 15px;
  @media screen and (min-width: ${changeGridLayoutBreakpoint}) {
    width: fit-content;
    flex-flow: row nowrap;
    padding: 0;
  }
`
export const GeneralBlock = styled.div<{ $notClickable?: boolean }>`
  background: ${({theme}) => theme.colors.background.primary};
  border: 1px solid ${({theme}) => theme.colors.borderColor};
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;

  h2 {
    font-size: ${({theme}) => theme.fontSize.textXL};
    padding-bottom: 0;
  }

  p {
    font-size: ${({theme}) => theme.fontSize.textM};
    color: ${({theme}) => theme.colors.fontColor.secondary};
    text-align: left;
    margin-bottom: 0;
  }

  transform: scale3d(1, 1, 1) rotate3d(0);

  ${({$notClickable, theme}) => !$notClickable && `
    :hover {
      border: 1px solid ${theme.colors.purple.main};
      transition: 0.3s;
      background: ${theme.colors.background.secondary};
      transform: scale3d(1.01, 1.01, 1.01) rotate3d(2.5, -0.87, 0, 5.6025deg);
    }
  `}
`

//Block 1
export const Block1 = styled(GeneralBlock)`
  width: 100%;
  height: fit-content;
  padding: 0px 20px;
  height: 145px;
  display: grid;
  place-items: center;

  @media screen and (min-width: ${changeGridLayoutBreakpoint}) {
    width: 250px;
    height: 400px;
    padding: 0;
  }
`

export const Block1Content = styled.div`
  display: flex;
  place-items: center;
  flex-flow: row nowrap;
  gap: 10px;

  img {
    height: 100px;
    width: auto;
  }

  @media screen and (min-width: ${changeGridLayoutBreakpoint}) {
    flex-flow: column nowrap;
    p {
      text-align: center;
      white-space: pre;
    }
  }
`
export const Block1Details = styled.div`
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  @media screen and (min-width: ${changeGridLayoutBreakpoint}) {
    place-items: center;
    gap: 10px;
  }
`

export const Block2 = styled(GeneralBlock)`
  width: 100%;   
  height: fit-content;
  display: grid; 
  place-items: center;
  padding: 15px 10px;

  @media screen and (min-width: ${mobileGridLayoutBreakpoint}) {
    width: calc(100% - 20px);   
  }

  @media screen and (min-width: ${changeGridLayoutBreakpoint}) {
    width: 250px;
    height: 230px;
  }
`

export const Block2Content = styled.div`
  display: flex;
  place-items: center;
  flex-flow: column nowrap;
  gap: 7px;

  p {
    text-align: center;
  }
`

export const Block3 = styled(GeneralBlock)`
  width: 100%;
  height: fit-content;
  padding: 15px 10px;
  height: 145px;

  @media screen and (min-width: ${mobileGridLayoutBreakpoint}) {
    width: calc(100% - 20px);   
  }
  @media screen and (min-width: ${changeGridLayoutBreakpoint}) {
    width: 250px;
    height: 145px;
  }
`

export const Block3TikTok = styled(Block3)`
  display: grid; 
  place-items: center;
`

export const Block3TikTokContent = styled.div`
  display: flex;
  place-items: center;
  flex-flow: row nowrap;
  gap: 10px;
`

export const Block3Patron = styled(Block3)`
  display: flex; 
  flex-flow: column nowrap;
  place-items: flex-start;
  h2 {
    color: ${({theme}) => theme.colors.black};
  }
  p {
    color: ${({theme}) => theme.colors.black};
  }
  background: ${({theme}) => theme.colors.green.main};
  justify-content: center;
  :hover {
    background: ${({theme}) => theme.colors.green.main} !important;
    border: none;
  }
`


export const ExploreSubGrid = styled.div`
  width: 100%;
  display: flex;
  place-items: center;
  align-items: flex-start;
  gap: 25px;
  flex-flow: column nowrap;
  @media screen and (min-width: ${mobileGridLayoutBreakpoint}) {
    flex-flow: row nowrap;
  }
  @media screen and (min-width: ${changeGridLayoutBreakpoint}) {
    width: fit-content;
    flex-flow: column nowrap;
  }
`

export const Title = styled.h2`
  text-align: center;
  font-size: 25px;
`

export const Blocks = styled.div`
  align-self: center;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
`

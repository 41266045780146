import { t } from 'src/locale'
import { SingleEventHeaderPriceTag } from './styles'

type SingleEventHeaderPriceProps = {
  price: number
  free: boolean
}

export const SingleEventHeaderPrice: React.FC<SingleEventHeaderPriceProps> = ({
  price,
  free,
}) => {
  return (
    <SingleEventHeaderPriceTag
      $free={free}
      title={free ? '' : `${t('Price')}: `}
      value={free ? t('Free') : price}
      precision={2}
      suffix={free ? '' : 'zł'}
    />
  )
}

import CheckableTag from 'antd/lib/tag/CheckableTag'
import styled from 'styled-components'

export const DefaultFiltersContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  width: 100%;
  gap: 8px;
`
export const TopFilters = styled.div`
  width: 100%;
  max-width: 760px;
  display: grid;
  grid-template-columns: calc(50% - 4px) calc(50% - 4px);
  gap: 8px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
`

export const SwitchField = styled.div`
  display: flex;
  place-items: center;
  gap: 5px;
  font-size: ${({ theme }) => theme.fontSize.textXM};

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding-right: 8px;
    border-right: 1px solid ${({ theme }) => theme.colors.purple.main};
  }
`

export const StyledCheckedTag = styled(CheckableTag)<{
  $green?: boolean
  value?: boolean
  $spacer?: boolean
}>`
  &.ant-tag-checkable-checked {
    background: ${({ theme, $green }) =>
      $green ? theme.colors.green.main : theme.colors.purple.main} !important;
    border: none;
    &:hover {
      opacity: 0.7;
      background: ${({ theme, $green, value }) =>
        $green && value
          ? theme.colors.green.main
          : theme.colors.purple.main} !important;
    }
  }
  &:hover {
    background: ${({ theme }) => theme.colors.background.tertiary} !important;
    color: ${({ theme, $green, value }) =>
      $green && value
        ? theme.colors.black
        : theme.colors.fontColor.primary} !important;
  }
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.background.secondary} !important;
  color: ${({ theme, $green, value }) =>
    $green && value ? theme.colors.black : theme.colors.fontColor.primary};
  padding: 5px 20px;
  font-size: ${({ theme }) => theme.fontSize.textXM};
  border-radius: ${({ theme }) => theme.border.radius};
  display: inline-flex;
  place-items: center;
  justify-content: center;
  gap: 5px;
  i {
    display: inline-flex;
    place-items: center;
    min-width: 17px;
  }
  &:last-of-type {
    margin-right: 0;
  }
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    width: fit-content;
  }
`

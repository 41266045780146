import { ReactElement } from 'react'
import {
  BlockContainer,
  BlockImage,
  BlockText,
  BlockTitle,
} from './styles'

type BlockProps = {
  image: ReactElement
  title: string
}

export const Block = ({
  image,
  title,
}: BlockProps) => {
  return (
    <BlockContainer>
      <BlockImage>{image}</BlockImage>
      <BlockText>
        <BlockTitle>{title}</BlockTitle>
      </BlockText>
    </BlockContainer>
  )
}

import { ReactElement } from 'react'
import { NotificationHeader } from 'src/components'
import { MobileFooter } from 'src/components/Footers/MobileFooter'
import { AppHeader } from 'src/components/Headers'
import { VerifyEmailModal } from 'src/components/VerifyEmailModal'
import { useActiveAccount, useAppSelector } from 'src/hooks'
import { MobileWrapper } from './styles'

type MainProps = {
  children: ReactElement
}

export const Main: React.FC<MainProps> = ({ children }) => {
  const isActiveAccountLoading = useAppSelector(
    (state) => state.account.activeAccount.status.isLoading
  )

  const activeAccount = useActiveAccount()

  return (
    <>
      {!activeAccount?.isEmailVerified && !isActiveAccountLoading && (
        <NotificationHeader />
      )}
      <AppHeader />
      <MobileWrapper>
        {/*!isActiveAccountLoading && <VerifyEmailModal />*/}
        {children}
      </MobileWrapper>
      <MobileFooter />
    </>
  )
}

import { Event } from 'src/types'
import { Space, Table, notification, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useAppDispatch } from 'src/hooks'
import {
  deleteOneEventByIdAction,
  patchOneEventByIdAction,
  searchAllEventsAction,
} from 'src/store'
import { AppButton, AppPopconfirm } from 'src/components'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { formatQueryTyped } from 'src/utils'
import { useNavigate } from 'react-router'
import { MdDelete, MdEdit } from 'react-icons/md'

export const EventsTable: React.FC<{
  results: Event[]
}> = ({ results }) => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const actionDelete = (eventID: string) => {
    dispatch(
      deleteOneEventByIdAction({
        eventId: eventID,
        onSuccess: () => {
          dispatch(
            searchAllEventsAction({
              query: formatQueryTyped({
                limit: 100,
              }),
            })
          )
        },
        onError: (err: any) => {
          notification.error({ message: err })
        },
      })
    )
  }

  const changeFreeInto = (event_id: string, free: boolean) => {
    dispatch(
      patchOneEventByIdAction({
        eventId: event_id,
        payload: {
          free,
        },
        onSuccess: () => {
          dispatch(
            searchAllEventsAction({
              query: formatQueryTyped({
                limit: 100,
              }),
            })
          )
        },
      })
    )
  }

  const columns: ColumnsType<Event> = [
    {
      title: 'ID',
      dataIndex: 'eventId',
      render: (text) => <a href={'/events/' + text}>{text + ''}</a>,
    },
    {
      title: 'Author',
      render: (_, record) => (
        <a href={'/@' + record.author.username}>{record.author.username}</a>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render: (text) => <p>{text}</p>,
    },
    {
      key: 'action',
      render: (_, record) => (
        <Space size="small">
          {record.free ? (
            <Tooltip placement="bottom" title="Zmień na płatne">
              <AppButton
                type="primary"
                onClick={() => changeFreeInto(record._id, false)}
              >
                FREE
              </AppButton>
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="Zmień na darmowe">
              <AppButton
                danger
                onClick={() => changeFreeInto(record._id, true)}
              >
                PAID
              </AppButton>
            </Tooltip>
          )}

          <AppButton
            onClick={() => navigate('/events/' + record.eventId + '/edit')}
          >
            <MdEdit />
          </AppButton>
          <AppPopconfirm
            title="Usunąć ten Event?"
            onConfirm={() => actionDelete(record._id)}
            icon={<AiOutlineQuestionCircle />}
            showCancel
            cancelText="Anuluj"
            okText="Usuń"
            okButtonProps={{ danger: true }}
            cancelButtonProps={{
              ghost: true,
            }}
          >
            <AppButton danger>
              <MdDelete />
            </AppButton>
          </AppPopconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={results}
      pagination={false}
      rowKey={(record) => record._id}
    />
  )
}

import { RangePickerProps } from 'formik-antd'
import moment from 'moment'
import { InputBlock } from 'src/components/AppComponents'
import { AppDatePicker } from 'src/components/AppComponents/AppDatePicker'
import { t } from 'src/locale'
import { Term } from 'src/types'
import { InputLabel } from '../styles/EditSingleEventForm.styles'

type EventTermsProps = {
  terms: Term[]
}

export const EventTerms: React.FC<EventTermsProps> = ({ terms }) => {
  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < moment().subtract(0, 'day')
  }

  return (
    <InputBlock $twoColumns>
      <InputLabel>
        <span>{t('EventStartDate')}</span>
        <AppDatePicker
          name="terms[0].from"
          showTime={{
            format: 'HH:mm',
          }}
          format="YYYY-MM-DD HH:mm"
          allowClear={false}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          required
          disabledDate={disabledDate}
        />
      </InputLabel>
      <InputLabel>
        <span>{t('EventEndDate')}</span>

        <AppDatePicker
          name="terms[0].to"
          showTime={{
            format: 'HH:mm',
          }}
          format="YYYY-MM-DD HH:mm"
          allowClear={false}
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          disabledDate={disabledDate}
          required
        />
      </InputLabel>
    </InputBlock>
  )
}

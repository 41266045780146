import { createAsyncThunk } from '@reduxjs/toolkit'
import { getEventsByAuthorIdService } from 'src/services'
import { ApiError, Event, OnError, OnSuccess } from 'src/types'

type getEventsByAuthorIdActionArgs = {
  authorId: string
  query?: string
  onSuccess?: OnSuccess
  onError?: OnError
}

type getEventsByAuthorIdActionResp = {
  total: number
  data: Event[]
}

export const getEventsByAuthorIdAction = createAsyncThunk<
  getEventsByAuthorIdActionResp,
  getEventsByAuthorIdActionArgs,
  { rejectValue: ApiError }
>(
  'events/getEventsByAuthorId',
  async (
    { onSuccess, onError, authorId, query },
    { rejectWithValue, signal }
  ) => {
    try {
      const result = await getEventsByAuthorIdService(
        { authorId, query },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import { createPostService } from 'src/utils/services'

type postResetPasswordEmailServiceArgs = {
  payload: {
    email: string
  }
}

export const postResetPasswordEmailService = createPostService<
  postResetPasswordEmailServiceArgs,
  undefined
>({
  urlCreator: () => '/auth/reset-password/email',
})

import { combineReducers } from 'redux'
import {
  accountLoginSlice,
  accountProfileSlice,
  accountRegisterSlice,
  activeAccountSlice,
  fetchedAccountSlice,
} from './Account'
import { fetchedAccountsSlice } from './Account/fetchedAccountsSlice'
import { fetchedAccountsSliceFULL } from './Account/fetchedAccountsSliceFULL'
import { patronsSlice } from './Account/patronsSlice'
import { categoriesSlice } from './Categories'
import { accountEventsSlice, singleEventSlice, eventsListSlice } from './Events'
import { tagsSlice } from './Tags'
import {
  eventsFeedSlice,
  eventsFeedQuerySlice,
  todaysEventsFeedSlice,
  upcomingEventsFeedSlice,
  previousEventsFeedSlice,
  inProgressEventsFeedSlice,
} from './EventsFeed'

export const rootReducer = combineReducers({
  account: combineReducers({
    login: accountLoginSlice.reducer,
    register: accountRegisterSlice.reducer,
    fetchedAccount: fetchedAccountSlice.reducer,
    activeAccount: activeAccountSlice.reducer,
    accountProfile: accountProfileSlice.reducer,
    fetchedAccounts: fetchedAccountsSlice.reducer,
    fetchedAccountsFULL: fetchedAccountsSliceFULL.reducer,
    patrons: patronsSlice.reducer,
  }),
  events: combineReducers({
    single: singleEventSlice.reducer,
    accountEvents: accountEventsSlice.reducer,
    eventsList: eventsListSlice.reducer,
    feed: combineReducers({
      data: eventsFeedSlice.reducer,
      todays: todaysEventsFeedSlice.reducer,
      upcoming: upcomingEventsFeedSlice.reducer,
      previous: previousEventsFeedSlice.reducer,
      inProgress: inProgressEventsFeedSlice.reducer,
    }),
    feedQuery: eventsFeedQuerySlice.reducer,
  }),
  categories: combineReducers({
    all: categoriesSlice.reducer,
  }),
  tags: combineReducers({
    all: tagsSlice.reducer,
  }),
})

import styled from 'styled-components'
import { AppTagType } from './types'

export const AppTagWrapper = styled.div<{
  type: AppTagType
  $isActive?: boolean
  $isClickable?: boolean
}>`
  display: flex;
  place-items: center;
  gap: 5px;
  flex-flow: row nowrap;
  padding: 10px;
  border-radius: 15px;
  width: fit-content;
  border: none;
  height: 35px;
  min-width: 35px;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSize.textXM};
  .ant-spin-container,
  .ant-spin-nested-loading {
    display: flex;
    place-items: center;
    justify-content: center;
  }
  ${({ $isClickable }) => $isClickable && 'cursor: pointer;'}
  ${({ theme, type, $isActive }) => {
    switch (type) {
      case 'category':
        return `
          background: ${
            $isActive
              ? theme.colors.purple.main
              : theme.colors.background.tertiary
          };
          color: ${$isActive ? theme.colors.white : theme.colors.purple.main}
        `
      case 'tag':
        return `
          background: ${
            $isActive
              ? theme.colors.danger.main
              : theme.colors.background.tertiary
          };
          color: ${$isActive ? theme.colors.white : theme.colors.danger.main}
        `
      case 'mode':
        return `
        background: ${
          $isActive
            ? theme.colors.success.main
            : theme.colors.background.tertiary
        };
        color: ${$isActive ? theme.colors.black : theme.colors.success.main}
      `
      case 'archived':
        return `
        background: ${
          $isActive
            ? theme.colors.fontColor.secondary
            : theme.colors.background.tertiary
        };
        color: ${$isActive ? theme.colors.black : theme.colors.success.main}
      `
    }
  }}
`

export const AppTagName = styled.div``

export const AppTagPrefix = styled.div``

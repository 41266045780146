import styled from 'styled-components'

export const CentralCol = styled.div`
  display: grid;
  grid-template-columns: 100%;
  min-height: 350px;
  position: relative;
  max-width: 1000px;
  place-items: center;
  margin: auto;
  padding-top: 50px;
`

export const Box = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-items: center;
  gap: 30px;
  padding-left: 10px;
`

export const Title = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: ${({ theme }) => theme.fontSize.heading1};
  line-height: 40px;
  margin-top: 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.mobile}) {
    font-size: 55px;
  }
`

export const TitleBox = styled.h1`
  display: flex;
  place-items: center;
  gap: 10px;
  flex-flow: column nowrap;
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    flex-flow: row nowrap;
  }
`

export const ButtonsBox = styled.div`
  display: flex;
  place-items: center;
  flex-flow: column nowrap;
  gap: 10px;
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    flex-flow: row nowrap;
  }
`

export const Divider = styled.hr`
  border: 2px solid ${({ theme }) => theme.colors.purple.main};
  width: 150px;
  margin: 0;
  border-radius: 15px;
  font-size: ${({ theme }) => theme.fontSize.textXL};
`

export const Text = styled.p`
  margin: 0;
  max-width: 500px;
  color: ${({ theme }) => theme.colors.fontColor.secondary};
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    font-size: ${({ theme }) => theme.fontSize.textL};
    max-width: 600px;
  }
`

export const RightCol = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  @media screen and (max-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    position: absolute;
    opacity: 0.3;
    z-index: 1;
  }
`

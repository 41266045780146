import { useNavigate } from 'react-router-dom'
import {
  AppButton,
  EmptyContainer,
  EntryContainer,
  FormContainer,
  GoBack,
  MetaTags,
  SignUpForm,
} from '../../components'
import { t } from '../../locale'

export const SignUp = () => {
  const navigate = useNavigate()

  return (
    <>
      <MetaTags
        title="Dołącz do społeczności głodnej wiedzy!"
        desc="Zarejestruj się do Mapy Wiedzy i uzyskaj dostęp do dziesiątek wydarzeń i ofert z całej Polski!"
        url="https://mapawiedzy.com/signup"
        image="https://mapawiedzy.com/meta-images/signup.png"
      />
      <EmptyContainer>
        <EntryContainer>
          <FormContainer>
            <SignUpForm />
          </FormContainer>
          <GoBack
            question={t('DoYouHaveAnAccount')}
            button={
              <AppButton type="link" onClick={() => navigate('/signin')}>
                {t('SignInTitle')}
              </AppButton>
            }
          />
        </EntryContainer>
      </EmptyContainer>
    </>
  )
}

import styled from 'styled-components/macro'

export const SearchResultContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.fontSize.textS};
`

export const SearchResultUsername = styled.div`
  display: flex;
  place-items: center;
  gap: 5px;
`

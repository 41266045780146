import { Event } from 'src/types'
import { flatTagsArray } from 'src/utils/Tags/flatTagsArray'
import { SingleEventHeaderCategoriesWrapper } from './styles'
import { EventTag } from '../../components'
type SingleEventHeaderCategoriesProps = {
  event: Event | null
}

export const SingleEventHeaderCategories = ({
  event,
}: SingleEventHeaderCategoriesProps) => {
  const flatArr = flatTagsArray(
    event?.tags.filter((tag) => ['tag', 'category'].includes(tag.type)) || []
  )

  return (
    <SingleEventHeaderCategoriesWrapper>
      {flatArr.map((tag) => (
        <EventTag
          key={tag.key}
          $isCategory={tag.type === 'category'}
          $clickable
        >
          #{tag.name}
        </EventTag>
      ))}
    </SingleEventHeaderCategoriesWrapper>
  )
}

import { useEffect, useState } from 'react'
import { SmallHeader } from 'src/components/Typography'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import {
  eventsFeedQuerySelector,
  getTagsAction,
  modesTagsSelector,
  setTagsQuery,
} from 'src/store'
import { AppTag, TagsSelectorWrapper, TagsWrapper } from 'src/components'
import { Tag } from 'src/types'
import { useEventsFeed } from 'src/hooks/EventsFeed'

export const ModeQuery = () => {
  const dispatch = useAppDispatch()
  const { tags } = useEventsFeed()
  const { tags: queryTags } = useAppSelector(eventsFeedQuerySelector)
  const [activeModes, setActiveModes] = useState<string[]>([])

  const modes = useAppSelector(modesTagsSelector)

  useEffect(() => {
    dispatch(getTagsAction({}))
  }, [])

  const onTagSelect = (id: string) => {
    if (activeModes?.includes(id)) {
      const filteredModes = activeModes.filter(
        (selectedId) => selectedId !== id
      )
      const filteredTags = (queryTags || []).filter((tagId) => tagId !== id)
      setActiveModes(filteredModes)
      dispatch(setTagsQuery(filteredTags))
    } else {
      setActiveModes([...activeModes, id])
      dispatch(setTagsQuery([...(queryTags || []), id]))
    }
  }

  return (
    <TagsSelectorWrapper>
      <SmallHeader>Tryb</SmallHeader>
      <TagsWrapper>
        {modes
          .filter((mode) => tags.includes(mode._id))
          .map((mode: Tag) => (
            <AppTag
              key={mode.key}
              type="mode"
              name={mode.name}
              onClick={() => onTagSelect(mode._id)}
              isActive={activeModes.includes(mode._id)}
            />
          ))}
      </TagsWrapper>
    </TagsSelectorWrapper>
  )
}

import { useFormikContext } from 'formik'
import { InputProps } from 'formik-antd'
import { omit, get } from 'lodash'
import {
  ErrorIcon,
  InputLabel,
  InputWrapper,
  StyledAppInput,
  StyledInputGroup,
} from './AppInput.styles'
import { AppNotification, AppNotificationPopover } from '../../AppNotification'
import { MessageType } from '../types'
import Input from 'antd/lib/input/Input'

export type AppInputProps = InputProps & {
  error?: string
  required?: boolean
  touched?: boolean
  label?: string
}

export const AppInput: React.FC<AppInputProps> = (props) => {
  const { name, touched, label, error } = props
  const { values } = useFormikContext<any>()

  const inputProps = omit(props, ['error'])

  const value = get(values, name)
  const isError = Boolean(error)

  return (
    <div>
      {label && <InputLabel>{label}</InputLabel>}
      <InputWrapper
        value={value}
        disabled={props.disabled}
        $isError={isError}
        $isRequired={props.required}
      >
        <StyledInputGroup>
          <StyledAppInput {...inputProps} value={value} />

          {isError && (
            <AppNotificationPopover
              trigger="hover"
              content={
                <AppNotification type="error" showClose={false}>
                  {error}
                </AppNotification>
              }
            >
              <ErrorIcon />
            </AppNotificationPopover>
          )}
        </StyledInputGroup>
      </InputWrapper>
    </div>
  )
}

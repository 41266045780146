export * from './getBrowserLocale'
export * from './copyTextToClipboard'
export * from './handleFormikErrors'

export * from './getEventStatus'
/**
 * This fn is proper typed but in most cases params are `any` type
 * (probably caused by not proper RTKQ with TS usage). It brakes app
 * in many places so extra util temporarily created.
 */
export const formatQueryTyped = <
  P extends string,
  T extends Record<P, any> = Record<P, any>
>(
  params: T
) => {
  const filteredParamEntries = (
    Object.entries<[P, any]>(params) as [P, any][]
  ).filter(([pk, paramValue]) => ![null, undefined].includes(paramValue))

  return new URLSearchParams(
    Object.fromEntries(filteredParamEntries)
  ).toString()
}
export const formatCurrency = (
  value?: number | string,
  currency?: string,
  maxDigits = 2
): string =>
  (Number(value) || 0).toLocaleString('pl-PL', {
    style: 'currency',
    currency: currency || 'USD',
    maximumFractionDigits: maxDigits,
  })

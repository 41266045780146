import { useEffect, useState } from 'react'
import { notification } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  AppButton,
  EmptyContainer,
  EntryContainer,
  FormContainer,
  GoBack,
} from 'src/components'
import { ResetPasswordNewForm } from 'src/components'
import { AppLoading } from 'src/components/AppComponents/AppLoading'
import { useAppDispatch } from 'src/hooks'
import { SuccessIcon } from 'src/icons'
import { t } from 'src/locale'
import { postResetPassword } from 'src/store/Account/actions/postResetPassword'
import { Helmet } from 'react-helmet'

export const ResetPasswordNew = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [success, setSuccess] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  useEffect(() => {
    if (token === null) navigate('/')
  }, [token])

  const handleSubmit = (values: { token: string; password: string }) => {
    dispatch(
      postResetPassword({
        payload: values,
        onStart: () => {
          setLoading(true)
        },
        onSuccess: () => {
          setLoading(false)
          setSuccess(true)
        },
        onError: (error: any) => {
          setLoading(false)
          notification.error({
            message: String(error.response.data.message),
          })
        },
      })
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('ResetPassword')}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <EmptyContainer>
        <EntryContainer>
          <FormContainer>
            {!success && !loading && (
              <>
                <h1>{t('ResetPassword')}</h1>
                <p>{t('EnterNewPasswordForYourAccount')}</p>
                <ResetPasswordNewForm handleSubmit={handleSubmit} />
              </>
            )}
            {success && !loading && (
              <>
                <SuccessIcon />
                <h1>{t('Success')}</h1>
                <p>{t('ResetPasswordSucceed')}</p>
                <AppButton type="primary" onClick={() => navigate('/signin')}>
                  {t('SignInTitle')}
                </AppButton>
              </>
            )}
            {loading && <AppLoading />}
          </FormContainer>
          {!success && (
            <GoBack
              question={t('DontHaveAnAccount')}
              button={
                <AppButton type="link" onClick={() => navigate('/signup')}>
                  {t('SignUpSubmissionButtonText')}
                </AppButton>
              }
            />
          )}
        </EntryContainer>
      </EmptyContainer>
    </>
  )
}

import styled from "styled-components";

export const FooterContainer = styled.div`
   padding-bottom: 10px;
`

export const Footer = styled.footer`
   background: ${({theme}) => theme.colors.background.secondary};
   width: 100%;
   max-width: 1000px;
   padding: 30px;
   align-self: center;
   margin: auto;
   border-radius: 15px;
   margin-bottom: 15px;
`

export const Top = styled.div`
   display: flex;
   place-items: flex-start;
   flex-flow: column nowrap;
   gap: 10px;
   @media screen and (min-width: ${({theme}) => theme.mediaBreakpoints.tablet}) {
      flex-flow: row nowrap;
      gap: 70px;
   }
`

export const Bottom = styled.div`
   width: 100%;
   border-top: 1px solid ${({theme}) => theme.colors.borderColor};
   display: flex;
   place-items: center;
   flex-flow: row nowrap;
   padding-top: 20px;
   gap: 10px;
   img {
      height: 40px;
      width: auto;
   }
   span {
      color: ${({theme}) => theme.colors.fontColor.secondary};
   }
`

export const List = styled.div`
   display: flex;
   place-items: flex-start;
   flex-flow: column nowrap;

   span {
      font-size: ${({theme}) => theme.fontSize.textM};
      font-weight: ${({theme}) => theme.fontWeight.bold};
   }
   ul {
      padding: 0;
      list-style-type: none;
      li {
         color: ${({theme}) => theme.colors.fontColor.secondary};
         font-size: ${({theme}) => theme.fontSize.textM};
         cursor: pointer;
         transition: 0.3s;
         :hover {
            color: ${({theme}) => theme.colors.green.main};
            transition: 0.3s;
         }
      }
   }
`
import styled from 'styled-components/macro'

export const SearchResultsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  .ant-tabs-nav-wrap {
    padding-left: 10px;
  }
  gap: 15px;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    width: 100%;
    max-width: 800px;
    .ant-tabs-nav-wrap {
      padding-left: 0;
    }
  }
`

import { useFormikContext } from 'formik'
import { DatePicker, DatePickerProps } from 'formik-antd'
import { get } from 'lodash'
import moment from 'moment'
import { t } from 'src/locale'
import { fontFamily } from 'src/themes/common/commonStyles'
import styled from 'styled-components'

const StyledAppDatePicker = styled(DatePicker)<{
  $isError?: boolean
  $isRequired?: boolean
  $value: boolean
}>`
  color: ${({ theme }) => theme.colors.fontColor.primary} !important;
  background: ${({ theme }) => theme.colors.background.tertiary} !important;
  border: 1px solid ${({ theme }) => theme.colors.purple.main} !important;
  border-radius: ${({ theme }) => theme.border.radius};
  .ant-picker-input > input {
    color: ${({ theme }) => theme.colors.fontColor.primary} !important;
  }
  .ant-picker-suffix {
    color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
  }
  &.ant-picker-focused {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.purple.main}14;
  }
  &.ant-picker-disabled {
    .ant-picker-input > input {
      color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
    }
    background: ${({ theme }) => theme.colors.background.secondary} !important;
    border: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
  }
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0px;
    background-color: ${({ theme, $isError, $isRequired, $value }) =>
      ($isError && theme.colors.red.main) ||
      ($isRequired && $value
        ? theme.colors.green.main
        : theme.colors.red.main) ||
      'unset'};
    height: 100%;
    width: ${({ $isRequired, disabled, $isError }) =>
      !$isError && (disabled || !$isRequired) ? 0 : '5px'};
    transition: width 0.2s;
    border-radius: 0px 4px 4px 0px;
  }
`

const StyledDropdown = styled.div`
  width: 100%;
  .ant-picker-dropdown {
    .ant-picker-panel-container {
      background: ${({ theme }) =>
        theme.colors.background.secondary} !important;
      .ant-picker-panel {
        border: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
        .ant-picker-header {
          color: ${({ theme }) => theme.colors.fontColor.primary} !important;
          .ant-picker-header-view button {
            color: ${({ theme }) => theme.colors.fontColor.primary} !important;
          }

          border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
          button {
            color: ${({ theme }) =>
              theme.colors.fontColor.secondary} !important;
          }
        }
        .ant-picker-body {
          .ant-picker-content th {
            color: ${({ theme }) => theme.colors.fontColor.primary} !important;
          }
          .ant-picker-cell {
            color: ${({ theme }) =>
              theme.colors.fontColor.secondary} !important;
          }
          .ant-picker-cell-in-view {
            color: ${({ theme }) => theme.colors.fontColor.primary} !important;
          }
          .ant-picker-cell-today .ant-picker-cell-inner::before {
            border: 2px solid ${({ theme }) => theme.colors.purple.main} !important;
          }
          .ant-picker-cell-selected .ant-picker-cell-inner {
            background: ${({ theme }) => theme.colors.purple.main} !important;
          }
          .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
              .ant-picker-cell-range-start
            ):not(.ant-picker-cell-range-end):not(
              .ant-picker-cell-range-hover-start
            ):not(.ant-picker-cell-range-hover-end)
            .ant-picker-cell-inner {
            background: ${({ theme }) => theme.colors.purple.main}52 !important;
          }
          .ant-picker-cell-disabled {
            opacity: 0.7;
            text-decoration: overline;
            color: ${({ theme }) =>
              theme.colors.fontColor.primary}55 !important;
            background: ${({ theme }) => theme.colors.purple.main}52 !important;
          }
        }
      }
      .ant-picker-time-panel {
        border-left: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
        .ant-picker-time-panel-cell-inner {
          color: ${({ theme }) => theme.colors.fontColor.primary} !important;
        }
        .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
          background: ${({ theme }) => theme.colors.purple.main} !important;
        }
        .ant-picker-time-panel-column:not(:first-child) {
          border-left: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
        }
        .ant-picker-time-panel-cell-inner:hover {
          background: ${({ theme }) => theme.colors.purple.main}52 !important;
        }
      }
      .ant-picker-footer {
        border-top: 1px solid ${({ theme }) => theme.colors.borderColor} !important;

        .ant-picker-now-btn {
          overflow: hidden;
          display: flex;
          place-items: center;
          width: 60px;
          color: ${({ theme }) => theme.colors.background.secondary};
          ::before {
            display: flex;
            padding-right: 0 30px;
            text-decoration: underline;
            content: '${t('Now')}';
            color: white;
          }
        }
        .ant-btn {
          font-family: ${({ theme }) => theme.fontFamily.primary} !important;
          font-weight: ${({ theme }) => theme.fontWeight.bold} !important;
          color: ${({ theme }) => theme.colors.fontColor.primary};
          background: ${({ theme }) => theme.colors.purple.main} !important;
          border-color: ${({ theme }) => theme.colors.purple.main} !important;
        }
      }
    }
  }
`

export type AppDatePickerProps = DatePickerProps & {
  error?: string
  required?: boolean
  touched?: boolean
  label?: string
}

export const AppDatePicker: React.FC<AppDatePickerProps> = ({
  ...inputProps
}) => {
  const { name, error } = inputProps
  const { values } = useFormikContext<any>()
  const value = get(values, name)
  const isError = Boolean(error)

  return (
    <StyledDropdown>
      <StyledAppDatePicker
        $value={Boolean(value)}
        $isError={isError}
        $isRequired={inputProps.required}
        {...inputProps}
      />
    </StyledDropdown>
  )
}

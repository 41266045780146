export const Navbar = {
  home: 'Home',
  explore: 'Eksploruj',
  events: 'Wydarzenia',
  offers: 'Oferty',
  map: 'Mapa',
  add: 'Dodaj',
  logOut: 'Wyloguj',
  profile: 'Profil',
  settings: 'Ustawienia',
  accounts: 'Użytkownicy',
  comingSoon: 'Wkrótce...',
  support: 'Support',
  contact: 'Kontakt',
  ContactOurSupportTeam: 'Skontaktuj się z Supportem!',
  WriteAnEmailOrSubmitForm: 'Wyślij e-mail lub wypełnij formularz Google.',
  ContactUs: 'Skontaktuj się z nami!',
  Tutorial: 'Instrukcja korzystania z platformy',
}

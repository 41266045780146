import { AppButton } from 'src/components/AppComponents'
import { t } from 'src/locale'
import styled from 'styled-components'

export const EventHeaderContainer = styled.div<{
  isToday?: boolean
  isInProgress?: boolean
  archived?: boolean
}>`
  max-width: 800px;
  width: 100%;
  background: ${({ theme }) => theme.colors.background.secondary};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  box-shadow: 0px -1px 0px ${({ isToday, isInProgress, archived, theme }) => {
      if (archived) {
        return theme.colors.disabled.background
      } else if (isInProgress) {
        return theme.colors.green.main
      } else if (isToday) {
        return theme.colors.purple.main
      } else {
        return theme.colors.borderColor
      }
    }};
  border-bottom: none;
  padding: 15px;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    flex-flow: row nowrap;
  }
  &::before {
    place-items: center;
    ${({ isToday, isInProgress, archived, theme }) => {
      if (archived) {
        return `
              color: ${theme.colors.disabled.color};
              background: ${theme.colors.disabled.background};
              content: '${t('EventEnded')}';
              display: flex;
              `
      } else if (isInProgress) {
        return `
              color: ${theme.colors.black};
              background: ${theme.colors.green.main};
              content: '${t('EventInProgess')}';
              display: flex;
              `
      } else if (isToday) {
        return `
              color: ${theme.colors.white};
              background: ${theme.colors.purple.main};
              content: '${t('EventToday')}';
              display: flex;
              `
      } else {
        return `
          display: none;
          `
      }
    }}
    position: absolute;
    top: -26px;
    left: 5px;
    padding: 2px 10px;
    padding-bottom: 0px;
    border-top-right-radius: ${({ theme }) => theme.border.radius};
    border-top-left-radius: ${({ theme }) => theme.border.radius};

    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize.textXM};

    @media screen and (min-width: ${({ theme }) =>
        theme.mediaBreakpoints.tablet}) {
      font-size: ${({ theme }) => theme.fontSize.textM};

      top: -28px;
    }
  }
`

export const EventHeaderTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.heading5};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.heading4};
  }
`

export const EventHeaderContainerLeft = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-items: flex-start;
  width: 100%;
`

export const EventHeaderContainerRight = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  min-width: 200px;

  width: 100%;
  align-items: flex-start;
  align-self: flex-start;
  .ant-btn {
    width: 100%;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    align-items: flex-end;
    align-self: flex-end;
    width: auto;
  }
`
export const Space = styled.div`
  height: 35px;
`

import styled from 'styled-components/macro'

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const BurgerMenu = styled.div`
  display: flex;
  height: 100%;
  place-items: center;
  color: ${({ theme }) => theme.colors.purple.main};
  cursor: pointer;
`

import { Select } from 'formik-antd'
import styled from 'styled-components'

export const AppSelect = styled(Select)<{
  $bordered?: boolean
  $fitContent?: boolean
}>`
  width: ${({ $fitContent }) => ($fitContent ? 'fit-content' : '100%')};
  &.ant-select-disabled {
    .ant-select-selector {
      .ant-select-selection-item {
        color: ${({ theme }) => theme.colors.fontColor.secondary} !important;
      }
      background: ${({ theme }) =>
        theme.colors.background.secondary} !important;
      border: 1px solid
        ${({ theme, $bordered = true }) =>
          $bordered
            ? theme.colors.purple.main
            : theme.colors.borderColor} !important;
    }
  }
  &.ant-select-focused:not(.ant-select-disabled),
  &.ant-select:hover:not(.ant-select-disabled) {
    .ant-select-selector {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.purple.main}14 !important;
      border: 1px solid
        ${({ theme, $bordered = true }) =>
          $bordered
            ? theme.colors.purple.main
            : theme.colors.borderColor} !important;
    }
  }
  &.ant-select {
    .ant-select-selector {
      background: ${({ theme, $bordered = true }) =>
        $bordered
          ? theme.colors.background.tertiary
          : theme.colors.background.secondary} !important;
      border: 1px solid
        ${({ theme, $bordered = true }) =>
          $bordered
            ? theme.colors.purple.main
            : theme.colors.borderColor} !important;
      border-radius: ${({ theme }) => theme.border.radius};
      color: ${({ theme }) => theme.colors.fontColor.primary} !important;
    }
    .ant-select-arrow .anticon {
      color: ${({ theme }) => theme.colors.fontColor.secondary};
    }
    .ant-select-dropdown {
      background: ${({ theme }) => theme.colors.background.tertiary} !important;
      border: 1px solid ${({ theme }) => theme.colors.borderColor};
      border-radius: ${({ theme }) => theme.border.radius};
      color: ${({ theme }) => theme.colors.fontColor.primary} !important;
      .ant-select-item {
        color: ${({ theme }) => theme.colors.fontColor.primary} !important;
      }
      .ant-select-item-option-selected {
        background: ${({ theme }) => theme.colors.purple.main} !important;
      }
      .ant-select-item-option-active {
        background: ${({ theme }) => theme.colors.purple.main}52 !important;
      }
    }
  }
`

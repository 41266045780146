import { createGetService } from 'src/utils/services'

type getCheckIfAccountUsernameIsTakenServiceArgs = {
  username: string
}

type response = {
  isNameTaken: boolean
}

export const getCheckIfAccountUsernameIsTakenService = createGetService<
  getCheckIfAccountUsernameIsTakenServiceArgs,
  response
>({
  urlCreator: ({ username }) => `/accounts/free-username/${username}`,
})

import { Upload } from 'antd'
import styled from 'styled-components/macro'

export const ModalFooter = styled.div`
  display: flex;
  place-items: center;
  flex-direction: row-reverse;
  gap: 15px;
`

export const AvatarUpload = styled(Upload)`
  .ant-upload {
    border: none;
    background: ${({ theme }) => theme.colors.background.primary};
    overflow: hidden;
    transition: 0.3s;
    margin: 0;
  }
  .ant-upload:hover {
    opacity: 0.8;
    transition: 0.3s;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    .ant-upload {
      width: 80px;
      height: 80px;
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    .ant-upload {
      width: 200px;
      height: 200px;
    }
  }
`
export const AppTooltipContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  place-items: center;
`

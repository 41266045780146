import { Dropdown, DropDownProps } from 'antd'
import styled from 'styled-components'

const AppDropdownContainer = styled.div`
  .ant-dropdown-menu {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    padding: 5px;
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    opacity: 1 !important;
  }
  .ant-dropdown-arrow {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    padding: 5px;
    &::before {
      background-color: ${({ theme }) => theme.colors.borderColor};
    }
  }
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-active:hover {
    background: ${({ theme }) => theme.colors.purple.main}52 !important;
  }
  .ant-dropdown-menu-item {
    color: ${({ theme }) => theme.colors.fontColor.primary};
    margin-top: 5px;
    text-size: 18px;
    margin-bottom: 5px;
  }
  .ant-dropdown-menu-item:last-of-type {
    margin-bottom: 0;
  }
  .ant-dropdown-menu-item-danger {
    color: ${({ theme }) => theme.colors.fontColor.primary};
    background: ${({ theme }) => theme.colors.red.main};
    border-radius: 5px;
  }
`

type AppDropdownProps = DropDownProps

export const AppDropdown: React.FC<AppDropdownProps> = ({ ...inputProps }) => {
  return (
    <>
      <AppDropdownContainer>
        <Dropdown
          {...inputProps}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        />
      </AppDropdownContainer>
    </>
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getEventsFeedService } from 'src/services'
import { ApiError, EventsFeed, OnError, OnSuccess } from 'src/types'

type GetEventsFeedActionArgs = {
  query?: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const getEventsFeedAction = createAsyncThunk<
  EventsFeed,
  GetEventsFeedActionArgs,
  { rejectValue: ApiError }
>(
  'events/feed',
  async ({ onSuccess, onError, query }, { rejectWithValue, signal }) => {
    try {
      const result = await getEventsFeedService({ query }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

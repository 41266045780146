import { useEffect, useState } from 'react'
import { AccountDetails } from 'src/types'
import { useTheme } from 'styled-components'
import { ProfileDetails } from './ProfileDetails'
import { ProfileDetailsContent } from './ProfileDetailsContent'
import { ProfilePicture } from './ProfilePicture'
import { ProfileInfoContainer, ProfilePCLayout } from './styles'

type ProfileCardProps = {
  account?: AccountDetails
}

export const ProfileCard: React.FC<ProfileCardProps> = ({ account }) => {
  const theme = useTheme()
  const [screenWidth, setScreenWidth] = useState<number>()
  useEffect(() => {
    setScreenWidth(window.screen.width)
    window.addEventListener('resize', () => {
      setScreenWidth(window.screen.width)
    })
  }, [])
  return (
    <ProfileInfoContainer>
      <ProfilePCLayout>
        <ProfilePicture account={account} />
        <ProfileDetails account={account} />
      </ProfilePCLayout>
      {screenWidth && screenWidth <= theme.mediaBreakpoints.numbers.mobile && (
        <ProfileDetailsContent screen={screenWidth} account={account} />
      )}
    </ProfileInfoContainer>
  )
}

import { ListBox, ListContainer, ListTitle } from './styles'
import { useEventsFeed } from 'src/hooks/EventsFeed/useEventsFeed'
import { EventFeedComponent } from 'src/components'
import { EventsSkeleton } from '../EventsSkeleton/EventsSkeleton'

export const List = () => {
  const {
    todaysEvents: {
      data: todaysEvents,
      get: { isLoading },
    },
    upcomingEvents: { data: upcomingEvents },
    previousEvents: { data: previousEvents },
    inProgressEvents: { data: inProgressEvents },
  } = useEventsFeed()

  const renderInProgressList = inProgressEvents.map((event, index) => (
    <EventFeedComponent key={index} event={event} />
  ))

  const renderTodaysList = todaysEvents.map((event, index) => (
    <EventFeedComponent key={index} event={event} />
  ))

  const renderUpcomingList = upcomingEvents.map((event, index) => (
    <EventFeedComponent key={index} event={event} />
  ))

  const renderPrevousList = previousEvents.map((event, index) => (
    <EventFeedComponent key={index} event={event} />
  ))

  if (isLoading)
    return (
      <ListBox>
        <ListContainer>
          <EventsSkeleton />
        </ListContainer>
      </ListBox>
    )

  return (
    <ListBox>
      {inProgressEvents.length > 0 && (
        <>
          <ListTitle>Wydarzenia w trakcie</ListTitle>
          <ListContainer>{renderInProgressList}</ListContainer>
        </>
      )}
      {todaysEvents.length > 0 && (
        <>
          <ListTitle>Dzisiaj</ListTitle>
          <ListContainer>{renderTodaysList}</ListContainer>
        </>
      )}
      {renderUpcomingList.length > 0 && (
        <>
          <ListTitle>Nadchodzące wydarzenia</ListTitle>
          <ListContainer>{renderUpcomingList}</ListContainer>
        </>
      )}
      {renderPrevousList.length > 0 && (
        <>
          <ListTitle>Wcześniejsze wydarzenia</ListTitle>
          <ListContainer>{renderPrevousList}</ListContainer>
        </>
      )}
    </ListBox>
  )
}

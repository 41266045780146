import { useNavigate } from 'react-router-dom'
import { Username } from 'src/components/Profile'
import { AccountRole, Image } from 'src/types'
import {
  SingleEventHeaderProfileAccount,
  SingleEventHeaderProfileContainer,
  SingleEventHeaderProfileImage,
  SingleEventHeaderProfileName,
} from './styles'

type SingleEventHeaderProfileProps = {
  username: string
  avatar?: Image
  name: string
  isUserVerified?: boolean
  roles?: AccountRole[]
}

export const SingleEventHeaderProfile = ({
  username,
  avatar,
  name,
  isUserVerified,
  roles,
}: SingleEventHeaderProfileProps) => {
  const navigate = useNavigate()

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    navigate(`/@${username}`)
  }

  return (
    <SingleEventHeaderProfileContainer onClick={onClick}>
      <SingleEventHeaderProfileAccount>
        <SingleEventHeaderProfileImage>
          <img
            src={
              avatar?.light_url ||
              `/${
                localStorage.getItem('theme') === 'light' ? 'light' : 'dark'
              }-temporary.webp`
            }
            alt={name}
          />
        </SingleEventHeaderProfileImage>
        <SingleEventHeaderProfileName>
          <Username
            isUserVerified={isUserVerified}
            roles={roles}
            username={username}
            xs
          />
        </SingleEventHeaderProfileName>
      </SingleEventHeaderProfileAccount>
    </SingleEventHeaderProfileContainer>
  )
}

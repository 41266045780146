import styled from 'styled-components'

export const PatronLayoutBox = styled.div`
  display: grid;
  grid-template-columns: 100%;
  width: 100%;
  max-width: 1300px;
  align-items: center;
  margin: auto;
  padding: 0px 20px;
  padding-top: 10px;
  gap: 10px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    grid-template-columns: 50% 50%;
    padding: 0px;
    gap: 0px;
  }
`

export const PatronLeft = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-items: start;
  h1 {
    text-transform: uppercase;
    line-height: 30px;
    font-size: ${({ theme }) => theme.fontSize.heading4};
  }
  p {
    max-width: 400px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.mobile}) {
    h1 {
      font-size: ${({ theme }) => theme.fontSize.heading2};
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    h1 {
      line-height: 40px;
      font-size: ${({ theme }) => theme.fontSize.heading1};
    }
  }
`

export const PatronRight = styled.div`
  h2 {
    font-size: ${({ theme }) => theme.fontSize.heading5};
  }
`

export const List = styled.ul`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  color: ${({ theme }) => theme.colors.fontColor.primary};
  font-size: ${({ theme }) => theme.fontSize.textM};
  li::marker {
    color: ${({ theme }) => theme.colors.green.main};
    content: '✓ ';
    font-size: ${({ theme }) => theme.fontSize.textXL};
  }
  margin-bottom: 0px;

  padding-left: 20px;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding-left: 40px;
  }
`

export const PatronsGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  place-items: center;
`

export const PatronImg = styled.div`
  width: 70px;
  height: 70px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 50%;
  display: grid;
  place-items: center;
  overflow: hidden;
  img {
    max-width: 70px;
    max-height: 70px;
  }
  cursor: pointer;
`

export const PatronsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  place-items: start;
  gap: 10px;

  width: 100%;
  max-width: 1300px;
  align-self: center;
  margin: auto;
  padding: 20px;
  h2 {
    font-size: ${({ theme }) => theme.fontSize.heading5};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding: 0px;
    padding-top: 40px;
  }
`

import { AiFillLinkedin } from 'react-icons/ai'
import { BsFacebook, BsInstagram } from 'react-icons/bs'
import { FaHandshake, FaTiktok } from 'react-icons/fa'
import {
  MdVolunteerActivism,
  MdWavingHand,
} from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { t } from 'src/locale'
import {
  Content,
  SidebarContainer,
  SidebarDrawer,
  SidebarItem,
  SocialIconButton,
  Socials,
  SocialsGrid,
} from './styles'

type SidebarProps = {
  open: boolean
  setOpen: any
}

export const LandingSidebar: React.FC<SidebarProps> = ({ open, setOpen }) => {
  const navigate = useNavigate()

  const items = [
    {
      key: 'about-us',
      label: 'O nas',
      icon: <MdWavingHand size={18} />,
      onClick: () => {
        navigate('/about-us')
        setOpen(!open)
      },
    },
    {
      key: 'patron',
      label: 'Zostań patronem!',
      icon: <FaHandshake size={18} />,
      onClick: () => {
        navigate('/patron')
        setOpen(!open)
      },
    },
  ]
  

  const socials = [
    {
      link: 'https://fb.me/mapa.wiedzy',
      icon: <BsFacebook size={20} />,
      key: 'fb',
    },
    {
      link: 'https://instagram.com/mapa.wiedzy',
      icon: <BsInstagram size={20} />,
      key: 'ig',
    },
    {
      link: 'https://tiktok.com/@mapa.wiedzy',
      icon: <FaTiktok size={20} />,
      key: 'tt',
    },
    {
      link: 'https://linkedin.com/company/mapa-wiedzy-pl',
      icon: <AiFillLinkedin size={20} />,
      key: 'in',
    },
  ]

  const renderItems = items.map((item) => (
    <SidebarItem key={item.key} onClick={() => item.onClick()}>
      {item?.icon} <div>{item?.label}</div>
    </SidebarItem>
  ))

  const renderSocials = socials.map((item) => (
    <SocialIconButton
      key={item.key}
      onClick={() => window.open(item.link, '_blank')}
    >
      {item.icon}
    </SocialIconButton>
  ))

  return (
    <>
      <SidebarDrawer
        open={open}
        mask={false}
        closable={false}
      >
        <SidebarContainer>
          <Content>
            {renderItems}
            <SidebarItem
              onClick={() => window.open('https://zrzutka.pl/tyzyd7', '_blank')}
              $green
            >
              <MdVolunteerActivism size={20} /> <div>{t('SupportUs')}</div>
            </SidebarItem>
          </Content>
          <Socials>
            <SocialsGrid>{renderSocials}</SocialsGrid>
            <span>@mapa.wiedzy | Mapa Wiedzy</span>
            <span>&copy; 2023 Mapa Wiedzy</span>
          </Socials>
        </SidebarContainer>
      </SidebarDrawer>
    </>
  )
}

import { ReactElement } from 'react'
import { Provider } from 'react-redux'
import { store } from 'src/store'
import { ThemeMode } from './ThemeMode'

export const AppProviders: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  return (
    <Provider store={store}>
      <ThemeMode>{children}</ThemeMode>
    </Provider>
  )
}

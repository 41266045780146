import { Form } from 'formik'
import styled from 'styled-components'

export const StyledForm = styled(Form)`
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;
  justify-content: right;
  align-items: flex-end;
`

import {
  Home,
  Profile,
  Accounts,
  SingleEvent,
  Settings,
  AdminPanel,
  EditSingleEvent,
  EventsPage,
} from '../pages'
import { RoutesConfig } from '../types'

export const routesConfig: RoutesConfig = {
  profile: {
    PageComponent: Profile,
    path: '/@:username',
  },
  home: {
    PageComponent: EventsPage,
    path: '/',
  },
  categories: {
    PageComponent: EventsPage,
    path: '/:categoryKey',
  },
  explore: {
    PageComponent: Accounts,
    path: '/accounts',
  },
  settings: {
    PageComponent: Settings,
    path: '/settings',
  },
  singleEvent: {
    PageComponent: SingleEvent,
    path: '/events/:eventId',
  },
  adminPanel: {
    PageComponent: AdminPanel,
    path: '/adminPanel',
  },
  editSingleEvent: {
    PageComponent: EditSingleEvent,
    path: '/events/:eventId/edit',
  },
}

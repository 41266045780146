import styled from 'styled-components'
import { AppButton } from 'src/components/AppComponents'

export const ProfileInfoContainer = styled.div`
  display: flex;
  place-items: left;
  flex-flow: column nowrap;
  width: 100%;
  gap: 10px;
  padding: 30px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    max-width: 800px;
    gap: 30px;
  }
`

export const ProfilePCLayout = styled.div`
  display: flex;
  place-items: center;
  gap: 10px;
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    gap: 20px;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
`

export const ProfileDetailsContentContaier = styled.div`
  display: flex;
  flex-direction: column;
  place-items: left;
  max-width: 400px;
  gap: 5px;
  text-align: left;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    max-width: 100%;
    place-items: flex-start;
  }
`

export const ProfilePictureContainer = styled.div`
  position: relative;
  width: auto;
  height: auto;

  .image {
    opacity: 1;
    display: block;
    transition: 0.5s ease;
    backface-visibility: hidden;
  }

  .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    color: ${({ theme }) => theme.colors.white};
  }

  :hover .image {
    opacity: 0.4;
  }

  :hover .overlay {
    opacity: 1;
  }

  .icon {
    font-size: 3rem;
  }
`

export const ProfileDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  gap: auto;
  flex: 3;
`

export const ProfileLink = styled.div`
  cursor: pointer;
  font-weight: 900 !important;
  color: ${({ theme }) => theme.colors.purple.main};
  transition: 0.3s;
  :hover {
    color: ${({ theme }) => theme.colors.green.main};
    transition: 0.3s;
  }

  font-size: ${({ theme }) => theme.fontSize.textM};
`

export const ProfileUsername = styled.div`
  display: flex;
  place-items: flex-start;
  flex-flow: column nowrap;
  h2 {
    font-size: ${({ theme }) => theme.fontSize.textL};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    display: flex;
    place-items: center;
    gap: 5px;
    padding: 0;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.mobile}) {
    h2 {
      font-size: ${({ theme }) => theme.fontSize.textXL};
      display: flex;
      place-items: center;
      gap: 5px;
    }
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    gap: 10px;
    padding-bottom: 10px;
    flex-flow: row nowrap;
    place-items: center;
    padding-bottom: 0;
    justify-content: space-between;
    h2 {
      font-size: ${({ theme }) => theme.fontSize.heading4};
    }
  }
`
export const ProfileBio = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: ${({ theme }) => theme.fontSize.textM};
  white-space: pre-wrap;
`

export const ProfileName = styled.span`
  font-size: ${({ theme }) => theme.fontSize.textM};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.textL};
  }
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    font-size: ${({ theme }) => theme.fontSize.heading4};
  }
`
export const StyledImage = styled.img`
  border-radius: 50%;
  border-style: solid;
  width: 100px;
  height: 100px;
  border: 2px solid ${({ theme }) => theme.colors.purple.main};

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    width: 120px;
    height: 120px;
  }
  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    width: 200px;
    height: 200px;
  }
`
export const ProfileButtonContainer = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) =>
      theme.mediaBreakpoints.tablet}) {
    padding: 0;
    padding-bottom: 16px;
  }
`

export const ProfileButton = styled(AppButton)`
  width: fit-content !important;
  gap: 5px;
  padding: 5px 10px !important;
  margin: 0 5px;
`

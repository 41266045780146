import styled from 'styled-components'

export const LogoAndLanguageContainer = styled.div`
  display: flex;
  gap: 10px;
  place-items: center;
`
export const LanguageButton = styled.div`
  cursor: pointer;
  display: grid; 
  place-items: center;
  height: 100%;
`

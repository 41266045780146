import styled from 'styled-components/macro'

export const AdminPanelContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.heading4};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 30px;
  min-width: 80%;

  h1 {
    font-size: ${({ theme }) => theme.fontSize.heading3};
  }
`

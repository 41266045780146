import { BsFacebook } from 'react-icons/bs'
import { CTAButton } from 'src/types'
import styled from 'styled-components'
import { AppButton } from '../AppComponents'

const StyledFacebookCTAButton = styled(AppButton)`
  && {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme, $clicked }) =>
      $clicked
        ? theme.colors.white
        : `linear-gradient(180deg, #17A9FD, #0165E1)`};
  }
`

export const CTAFacebook: React.FC<CTAButton> = ({ onClick, disabled }) => {
  return (
    <StyledFacebookCTAButton
      disabled={disabled}
      icon={<BsFacebook size={18} style={{ marginRight: '5px' }} />}
      onClick={onClick}
    >
      Facebook
    </StyledFacebookCTAButton>
  )
}

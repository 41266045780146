import { Event } from 'src/types'
import { createPostService } from 'src/utils/services'

type postOneEventByIdServiceArgs = {
  payload: {
    author: string
  }
}

export const postOneEventByIdService = createPostService<
  postOneEventByIdServiceArgs,
  Event
>({
  urlCreator: () => `/events/`,
})

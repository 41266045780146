import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteTagService } from 'src/services/Tags/deleteTagService'
import { ApiError, OnError, OnSuccess } from 'src/types'

type TagsArgs = {
  tagId: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const deleteTagAction = createAsyncThunk<
  undefined,
  TagsArgs,
  { rejectValue: ApiError }
>(
  'tags/delete',
  async ({ tagId, onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await deleteTagService({ tagId }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data)
    }
  }
)

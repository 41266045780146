import { createAsyncThunk } from '@reduxjs/toolkit'
import { searchAllEventsService } from 'src/services'
import { ApiError, Event, OnError, OnSuccess } from 'src/types'

type searchAllEventsActionArgs = {
  query?: string
  onSuccess?: OnSuccess
  onError?: OnError
}

type searchAllEventsActionResp = {
  total: number
  data: Event[]
}

export const searchAllEventsAction = createAsyncThunk<
  searchAllEventsActionResp,
  searchAllEventsActionArgs,
  { rejectValue: ApiError }
>(
  'events/searchAllEvents',
  async ({ onSuccess, onError, query }, { rejectWithValue, signal }) => {
    try {
      const result = await searchAllEventsService({ query }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

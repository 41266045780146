import { RiAdminLine } from '@react-icons/all-files/ri/RiAdminLine'
import { useState } from 'react'
import { AiFillLinkedin, AiOutlineQuestionCircle } from 'react-icons/ai'
import { BsFacebook, BsInstagram } from 'react-icons/bs'
import { FaRegHandshake, FaTiktok } from 'react-icons/fa'
import {
  MdContactMail,
  MdMail,
  MdOutlineLogout,
  MdSettings,
  MdSupport,
  MdVolunteerActivism,
} from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { AppButton, AppModal } from 'src/components/AppComponents'
import { useActiveAccount, useScreen } from 'src/hooks'
import { activeLanguage, switchLanguageTo, t } from 'src/locale'
import {
  Content,
  LogoutButton,
  ModalContent,
  SidebarContainer,
  SidebarDrawer,
  SidebarItem,
  SocialIconButton,
  Socials,
  SocialsGrid,
} from './styles'

type SidebarProps = {
  open: boolean
  setOpen: any
}

export const Sidebar: React.FC<SidebarProps> = ({ open, setOpen }) => {
  const navigate = useNavigate()
  const { isBigger } = useScreen()
  const [contactModal, setContactModal] = useState<boolean>(false)
  const [supportModal, setSupportModal] = useState<boolean>(false)

  const items = [
    {
      key: 'support',
      label: t('support'),
      icon: <MdSupport size={18} />,
      onClick: () => {
        setContactModal(false)
        setSupportModal(true)
        setOpen(!open)
      },
    },
    {
      key: 'contact',
      label: t('contact'),
      icon: <MdMail size={18} />,
      onClick: () => {
        setSupportModal(false)
        setContactModal(true)
        setOpen(!open)
      },
    },
    {
      key: 'patron',
      label: t('Patron'),
      icon: <FaRegHandshake size={18} />,
      onClick: () => {
        navigate('/patron')
        setOpen(!open)
      },
    },
    {
      key: 'tutorial',
      label: t('Tutorial'),
      icon: <AiOutlineQuestionCircle size={18} />,
      onClick: () => {
        window.open(
          'https://docs.google.com/document/d/1i95p6S8xesaZfG8ZovHxhfFMIkX7rSPaiLf6pRmQTYw/edit?usp=sharing',
          '_blank'
        )
        setOpen(!open)
      },
    },
    {
      key: 'change-language',
      label: activeLanguage == 'en' ? 'Zmień na polski' : 'Switch to English',
      icon:
        activeLanguage == 'en' ? (
          <img src="/PL-icon.svg" width={20} />
        ) : (
          <img src="/UK-icon.svg" width={20} />
        ),
      onClick: () => {
        if (activeLanguage == 'en') {
          switchLanguageTo('pl')
        } else {
          switchLanguageTo('en')
        }
      },
    }
  ]

  const activeAccount = useActiveAccount()

  if (activeAccount) {
    items.unshift({
      key: 'settings',
      label: t('settings'),
      icon: <MdSettings size={18} />,
      onClick: () => {
        navigate(`/settings`)
        setOpen(!open)
      },
    })
  }

  if (
    (activeAccount?.roles?.includes('mod') ||
      activeAccount?.roles?.includes('admin')) &&
    window.innerWidth > 1200
  ) {
    items.unshift({
      key: 'adminPanel',
      label: 'Admin-Panel',
      icon: <RiAdminLine size={18} />,
      onClick: () => {
        navigate(`/adminPanel`)
        setOpen(!open)
      },
    })
  }

  const socials = [
    {
      link: 'https://fb.me/mapa.wiedzy',
      icon: <BsFacebook size={20} />,
      key: 'fb',
    },
    {
      link: 'https://instagram.com/mapa.wiedzy',
      icon: <BsInstagram size={20} />,
      key: 'ig',
    },
    {
      link: 'https://tiktok.com/@mapa.wiedzy',
      icon: <FaTiktok size={20} />,
      key: 'tt',
    },
    {
      link: 'https://linkedin.com/company/mapa-wiedzy-pl',
      icon: <AiFillLinkedin size={20} />,
      key: 'in',
    },
  ]

  const renderItems = items.map((item) => (
    <SidebarItem key={item.key} onClick={() => item.onClick()}>
      {item?.icon} <div>{item?.label}</div>
    </SidebarItem>
  ))

  const renderSocials = socials.map((item) => (
    <SocialIconButton
      key={item.key}
      onClick={() => window.open(item.link, '_blank')}
    >
      {item.icon}
    </SocialIconButton>
  ))

  return (
    <>
      <AppModal
        open={supportModal}
        closable={false}
        footer={
          <AppButton type="ghost" onClick={() => setSupportModal(false)}>
            {t('Cancel')}
          </AppButton>
        }
        zIndex={9999}
      >
        <ModalContent>
          <h2>{t('ContactOurSupportTeam')}</h2>
          <p>{t('WriteAnEmailOrSubmitForm')}</p>
          <AppButton
            type="text"
            onClick={() =>
              window.open('mailto:support@mapawiedzy.com', '_blank')
            }
          >
            support@mapawiedzy.com
          </AppButton>
          <AppButton
            onClick={() =>
              window.open('https://forms.gle/EbsLsrPMLX6dkf136', '_blank')
            }
          >
            Google Form
          </AppButton>
        </ModalContent>
      </AppModal>
      <AppModal
        open={contactModal}
        closable={false}
        footer={
          <AppButton type="ghost" onClick={() => setContactModal(false)}>
            {t('Cancel')}
          </AppButton>
        }
        zIndex={9999}
      >
        <ModalContent>
          <h2>{t('ContactUs')}</h2>
          <p>{t('WriteAnEmailOrSubmitForm')}</p>
          <AppButton
            type="text"
            onClick={() =>
              window.open('mailto:kontakt@mapawiedzy.com', '_blank')
            }
          >
            kontakt@mapawiedzy.com
          </AppButton>
          <AppButton
            onClick={() =>
              window.open('https://forms.gle/Y4c5ZbXN4yh7gCro7', '_blank')
            }
          >
            Google Form
          </AppButton>
        </ModalContent>
      </AppModal>
      <SidebarDrawer
        open={open}
        mask={false}
        closable={false}
        biggerSpace={activeAccount && !activeAccount?.isEmailVerified}
      >
        <SidebarContainer>
          <Content>
            {renderItems}

            <SidebarItem
              onClick={() => window.open('https://zrzutka.pl/tyzyd7', '_blank')}
              $green
            >
              <MdVolunteerActivism size={20} /> <div>{t('SupportUs')}</div>
            </SidebarItem>
            {!!activeAccount && (
              <LogoutButton
                key={'logoutButon'}
                onClick={() => {
                  localStorage.removeItem('token'), navigate(`/`)
                }}
              >
                <MdOutlineLogout size={18} /> {t('logOut')}
              </LogoutButton>
            )}
          </Content>
          <Socials>
            <SocialsGrid>{renderSocials}</SocialsGrid>
            <span>@mapa.wiedzy | Mapa Wiedzy</span>
            <span>&copy; 2023 Mapa Wiedzy</span>
          </Socials>
        </SidebarContainer>
      </SidebarDrawer>
    </>
  )
}

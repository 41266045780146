import { AppButton } from 'src/components/AppComponents'
import styled from 'styled-components/macro'

export const MobileFooterContainer = styled.nav`
  position: fixed;
  bottom: 0px;
  left: 0;
  height: 60px;
  width: 100vw;
  background: ${({ theme }) => theme.colors.background.secondary};
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 8000;

  @media screen and (min-width: ${({ theme }) => theme.mediaBreakpoints.pc}) {
    display: none;
  }
`
export const StyledAddButton = styled(AppButton)`
  border-radius: 10px;
  border-color: ${({ theme }) => theme.colors.borderColor};
  width: 75px;
  height: 45px;
  padding: 0px;
  font-size: 30px !important;
  font-weight: bold;
  box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
  bottom: 0px;
`

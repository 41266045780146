import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { AppPopconfirm } from 'src/components/AppComponents'
import { useActiveAccount, useAppDispatch } from 'src/hooks'
import { t } from 'src/locale'
import { postOneEventByIdAction } from 'src/store'
import { AddNowButton, Container, Message } from './styles'

export const YouHaventAddedAnyEventsYet = () => {
  const dispatch = useAppDispatch()
  const activeAccount = useActiveAccount()

  const navigate = useNavigate()

  const postOneEvent = () => {
    if (activeAccount?._id && activeAccount.isEmailVerified) {
      dispatch(
        postOneEventByIdAction({
          payload: {
            author: activeAccount._id,
          },
          onSuccess: (result) => {
            navigate(`/events/${result.eventId}/edit`)
          },
          onError: (err: any) => {
            notification.error({
              message: String(err.response.data.message),
            })
          },
        })
      )
    } else if (!activeAccount?.isEmailVerified) {
      notification.error({
        message: t('YourEmailIsNotVerified'),
      })
    }
  }

  return (
    <AppPopconfirm
      title={t('AreYouSureYouWantToAddNewEvent')}
      onConfirm={postOneEvent}
      okText={t('Yes')}
      cancelText={t('Cancel')}
    >
      <Container>
        <Message>{t('YouHaventAddedAnyEventsYet')}</Message>
        <AddNowButton>{t('AddButtonText')}</AddNowButton>
      </Container>
    </AppPopconfirm>
  )
}

import { notification } from 'antd'
import { Formik, FormikValues } from 'formik'
import { debounce, isEqual } from 'lodash'
import { useState } from 'react'
import { AppButton, AppInput } from 'src/components/AppComponents'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { t } from 'src/locale'
import {
  getActiveAccount,
  getCheckIfAccountEmailIsTaken,
  postResendVerifyEmail,
} from 'src/store'
import { patchOneAccountData } from 'src/store/Account/actions/patchOneAccountData'
import { Account } from 'src/types'
import { handleFormikErrors } from 'src/utils'
import { changeEmailValidationSchema } from './changeEmailValidationSchema'
import { Buttons, Input, Placeholder, StyledForm } from './styles'

type ChangeEmailForm = {
  account: Account
}

export const ChangeEmailForm = ({ account }: ChangeEmailForm) => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const isEmailTaken = useAppSelector(
    (state) => state.account.accountProfile.isEmailTaken
  )

  const initialValues = {
    email: account.email,
  }

  const checkEmail = debounce((e) => {
    if (!e.target.value) return
    dispatch(
      getCheckIfAccountEmailIsTaken({
        email: e.target.value,
      })
    )
  }, 300)

  const emailError = (error: string | undefined, value: string) => {
    if (error) return error

    if (isEmailTaken && account.email !== value) return t('EmailIsTaken')

    return ''
  }

  const handleSuccess = () => {
    notification.success({
      message: t('ChangedEmail'),
    })
    dispatch(
      postResendVerifyEmail({
        onSuccess: () => {
          notification.success({
            message: t('WeHaveSentYouAnEmail'),
          })
        },
        onError: () => {
          notification.error({
            message: t('VerificationEmailWasNotSend'),
          })
        },
      })
    )
    dispatch(getActiveAccount({}))
  }

  const handleError = (err: any) => {
    notification.error({
      message: String(err.response.data.message),
    })
  }

  const handleSubmit = (values: FormikValues) => {
    dispatch(
      patchOneAccountData({
        payload: {
          ...values,
          isEmailVerified: false,
        },
        accountId: account?._id,
        onSuccess: handleSuccess,
        onError: (err) => handleError(err),
      })
    )
    setEditMode(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changeEmailValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, submitForm, validateForm, resetForm }) => (
        <StyledForm>
          {editMode ? (
            <>
              <Input
                type="email"
                name="email"
                required
                error={emailError(errors.email, values.email)}
                placeholder="john.kowalski@website.com"
                onChange={checkEmail}
              />
              <Buttons>
                <AppButton
                  onClick={async () => {
                    handleFormikErrors(await validateForm())
                    submitForm()
                  }}
                  disabled={isEqual(values, initialValues)}
                >
                  {t('Save')}
                </AppButton>
                <AppButton
                  onClick={() => {
                    resetForm()
                    setEditMode(false)
                  }}
                  type="ghost"
                >
                  {t('Cancel')}
                </AppButton>
              </Buttons>
            </>
          ) : (
            <>
              <Placeholder>{account.email}</Placeholder>
              <Buttons>
                <AppButton onClick={() => setEditMode(true)}>
                  {t('ChangeEmail')}
                </AppButton>
              </Buttons>
            </>
          )}
        </StyledForm>
      )}
    </Formik>
  )
}

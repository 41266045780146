import { createAsyncThunk } from '@reduxjs/toolkit'
import { postResendVerifyEmailService } from 'src/services/Account/postResendVerifyEmailService'
import { OnError, OnSuccess, Account, ApiError } from 'src/types'

type postResendVerifyEmailArgs = {
  onSuccess?: OnSuccess
  onError?: OnError
}

export const postResendVerifyEmail = createAsyncThunk<
  Account,
  postResendVerifyEmailArgs,
  { rejectValue: ApiError }
>(
  'account/verifyEmail',
  async ({ onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const result = await postResendVerifyEmailService(
        { payload: undefined },
        { signal }
      )
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

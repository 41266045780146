import styled from 'styled-components/macro'
import { AppNotificationType } from './types'
import { AppButton } from '../AppButtons'

export const Wrapper = styled.div<{ $type?: AppNotificationType }>`
  display: flex;
  border-radius: 12px;
  gap: 5px;
  padding: 15px 20px;
  text-align: center;
  background: ${({ theme }) => theme.colors.background.tertiary};
  color: ${({ theme }) => theme.colors.fontColor.primary};
  ${({ $type, theme }) => {
    switch ($type) {
      case 'warning':
        return `
          border-right: 10px solid ${theme.colors.info.main};
          .anticon {
            color: ${theme.colors.info.main};
          }
        `
      case 'error':
        return `
          border-right: 10px solid ${theme.colors.danger.main};
          .anticon {
            color: ${theme.colors.danger.main};
          }
        `
      case 'info':
        return `
          border-right: 10px solid ${theme.colors.info.main};
          .anticon {
            color: ${theme.colors.info.main};
          }
        `
      case 'success':
        return `
          border-right: 10px solid ${theme.colors.success.main};
          .anticon {
            color: ${theme.colors.success.main};
          }
        `
      default:
        return `
          border-right: 10px solid ${theme.colors.success.main};
          .anticon {
            color: ${theme.colors.success.main};
          }
        `
    }
  }}

  .anticon {
    svg {
      font-size: 20px;
    }
  }
`

export const Title = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.heading5};
  color: ${({ theme }) => theme.colors.fontColor.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-align: center;
`

export const CloseButton = styled(AppButton)`
  position: absolute;
  right: 0;
  top: 18px;

  .anticon {
    svg {
      color: ${({ theme }) => theme.colors.fontColor.primary};
      font-size: 16px;
    }
  }
`

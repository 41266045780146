import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllCategoriesService } from 'src/services/Categories'
import { OnError, OnSuccess, Category, ApiError } from 'src/types'

type getAllCategoriesActionArgs = {
  onSuccess?: OnSuccess
  onError?: OnError
}

export const getAllCategoriesAction = createAsyncThunk<
  Category[],
  getAllCategoriesActionArgs,
  { rejectValue: ApiError }
>(
  'categories/getAllCategoriesAction',
  async ({ onSuccess, onError }, { rejectWithValue, signal }) => {
    try {
      const results = getAllCategoriesService({}, { signal })
      onSuccess?.()
      return results
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import styled from 'styled-components/macro'

interface CardType {
  cardType: 'facebook' | 'instagram' | 'tiktok' | 'linkedin'
}

export const SocialMediaCardContainer = styled.div<CardType>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-right: 20%;
  background: ${(p) =>
    p.cardType === 'facebook'
      ? '#0165E1'
      : p.cardType === 'instagram'
      ? 'linear-gradient(90deg, rgba(240,148,51,1) 16.6%, rgba(230,104,60,1) 33.3%, rgba(229,99,61,1) 50%, rgba(220,39,67,1) 66.6%, rgba(209,36,92,1) 83.3%, rgba(204,35,102,1) 100%)'
      : p.cardType === 'tiktok'
      ? '#000000'
      : p.cardType === 'linkedin'
      ? '#0E76A8'
      : '#ffffff'};
  border-radius: ${({ theme }) => theme.border.radiusL};
`

export const SocialMediaCardContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

export const SocialMediaCardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  felx-basis: 100%;
  flex: 1;
  margin-left: -2%;
`

export const SocialMediaCardContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  felx-basis: 100%;
  flex: 4;
  margin-top: 2%;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`

import { createSlice } from '@reduxjs/toolkit'
import { SliceStatus, Event } from 'src/types'
import { deleteOneEventByIdAction, getOneEventByCleanIdAction } from './actions'
import { patchOneEventByIdAction } from './actions/patchOneEventByIdAction'

type SingleEventSliceType = {
  event: Event | null
  get: SliceStatus
  patch: SliceStatus
}

const initialState: SingleEventSliceType = {
  event: null,
  get: {
    isLoading: false,
    error: null,
  },
  patch: {
    isLoading: false,
    error: null,
  },
}

export const singleEventSlice = createSlice({
  name: 'events/single',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getOneEventByCleanIdAction.pending, (state) => {
      state.get.isLoading = true
    })
    builder.addCase(getOneEventByCleanIdAction.fulfilled, (state, action) => {
      state.get.isLoading = false
      state.get.error = null
      state.event = action.payload
    })
    builder.addCase(getOneEventByCleanIdAction.rejected, (state, action) => {
      state.get.isLoading = false
      state.get.error = action.payload
    })
    builder.addCase(patchOneEventByIdAction.pending, (state) => {
      state.get.isLoading = true
    })
    builder.addCase(patchOneEventByIdAction.fulfilled, (state, action) => {
      state.get.isLoading = false
      state.get.error = null
      state.event = action.payload
    })
    builder.addCase(patchOneEventByIdAction.rejected, (state, action) => {
      state.get.isLoading = false
      state.get.error = action.payload
    })
    builder.addCase(deleteOneEventByIdAction.pending, (state) => {
      state.get.isLoading = true
    })
    builder.addCase(deleteOneEventByIdAction.fulfilled, (state, action) => {
      state.get.isLoading = false
      state.get.error = null
      state.event = null
    })
    builder.addCase(deleteOneEventByIdAction.rejected, (state, action) => {
      state.get.isLoading = false
      state.get.error = action.payload
    })
  },
})

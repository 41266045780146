import { Account } from 'src/types'
import { Space, Table, Menu, Dropdown, notification, Popover } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useAppDispatch } from 'src/hooks'
import { deleteAccountByIdAction, getAccountsByQueryFULL } from 'src/store'
import { AppButton, AppPopconfirm } from 'src/components'
import { AiOutlineQuestionCircle, AiFillInfoCircle } from 'react-icons/ai'
import { postResetPasswordEmail } from 'src/store/Account/actions/postResetPasswordEmail'
import { formatQueryTyped } from 'src/utils'
import { patchOneAccountData } from 'src/store/Account/actions/patchOneAccountData'
import {
  MdDelete,
  MdVerified,
  MdLock,
  MdEmail,
  MdCheckCircle,
  MdArrowDropDownCircle,
} from 'react-icons/md'
import { GrPowerReset } from 'react-icons/gr'
import { RiVipCrownFill } from 'react-icons/ri'
import { postResendVerifyEmailByAdmin } from 'src/store/Account/actions/postResendVerifyEmailByAdmin'
import { useState } from 'react'

export const UsersTable: React.FC<{
  results: Account[]
}> = ({ results }) => {
  const [resultsTab, setResults] = useState<Account[]>(results)

  const dispatch = useAppDispatch()

  const actionNewPassword = (email: string) => {
    dispatch(
      postResetPasswordEmail({
        payload: { email },
        onError: (error: any) => {
          notification.error({
            message: String(error.response.data.message),
          })
        },
      })
    )
  }

  const actionDelete = (userID: string) => {
    dispatch(
      deleteAccountByIdAction({
        accountId: userID,
        onSuccess: () => {
          dispatch(
            getAccountsByQueryFULL({
              query: formatQueryTyped({
                arg: '',
                adminInfo: true,
                limit: '1000',
              }),
            })
          )
        },
        onError: (err: any) => {
          notification.error({ message: err })
        },
      })
    )
  }

  const actionVerifiedUser = (userID: string, isUserVerified: boolean) => {
    dispatch(
      patchOneAccountData({
        accountId: userID,
        payload: { isUserVerified },
        onError: (error: any) => {
          notification.error({
            message: String(error.response.data.message),
          })
        },
      })
    )
  }

  const actionEmailUserVerify = (userID: string, isEmailVerified: boolean) => {
    dispatch(
      patchOneAccountData({
        accountId: userID,
        payload: { isEmailVerified },
        onError: (error: any) => {
          notification.error({
            message: String(error.response.data.message),
          })
        },
      })
    )
  }

  const actionVerifyEmailResend = (email: string) => {
    dispatch(
      postResendVerifyEmailByAdmin({
        payload: { email },
        onError: (error: any) => {
          notification.error({
            message: String(error.response.data.message),
          })
        },
      })
    )
  }

  const onFilterClick = (key: string) => {
    if (key === '1') {
      setResults(
        results.filter((element) => {
          return element.isEmailVerified === true
        })
      )
    }
    if (key === '2') {
      setResults(
        results.filter((element) => {
          return element.isEmailVerified === false
        })
      )
    }
    if (key === '0') {
      setResults(results)
    }
  }

  const filterEmailMenu = (
    <Menu style={{ border: '2px black solid' }}>
      <Menu.Item key="1" onClick={() => onFilterClick('1')}>
        Status: Zweryfikowany
      </Menu.Item>
      <Menu.Item key="2" onClick={() => onFilterClick('2')}>
        Status: Nie zweryfikowany
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={() => onFilterClick('0')}>
        Nie filtruj
      </Menu.Item>
    </Menu>
  )

  const columns: ColumnsType<Account> = [
    {
      title: 'Username',
      dataIndex: 'username',
      render: (text, record) => (
        <a href={'/@' + text} style={{color:"black"}}>
          {text}{' '}
          {record.roles?.includes('admin') && <RiVipCrownFill color="red" />}
          {record.roles?.includes('mod') && (
            <RiVipCrownFill color="green" />
          )}{' '}
        </a>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => <p>{text}</p>,
    },
    {
      key: 'action',
      render: (_, record) => (
        <Space size="small">
          <AppPopconfirm
            title="Wysłać link do zmiany hasła?"
            onConfirm={() => actionNewPassword(record.email)}
            icon={<AiOutlineQuestionCircle />}
            showCancel
            cancelText="Anuluj"
            okText="Wyślij"
            okButtonProps={{ danger: true }}
            cancelButtonProps={{
              ghost: true,
            }}
          >
            <AppButton danger>
              <GrPowerReset />
              <MdLock />
            </AppButton>
          </AppPopconfirm>
          <AppPopconfirm
            title="Zmienić stan weryfikacji użytkownika?"
            onConfirm={() =>
              actionVerifiedUser(record._id, !record.isUserVerified)
            }
            icon={<AiOutlineQuestionCircle />}
            showCancel
            cancelText="Anuluj"
            okText="Zmień"
            okButtonProps={{ danger: true }}
            cancelButtonProps={{
              ghost: true,
            }}
          >
            {record.isUserVerified ? (
              <AppButton>
                <MdVerified />
              </AppButton>
            ) : (
              <AppButton danger>
                <MdVerified />
              </AppButton>
            )}
          </AppPopconfirm>
          <AppPopconfirm
            title="Zweryfikować email?"
            onConfirm={() => actionEmailUserVerify(record._id, true)}
            icon={<AiOutlineQuestionCircle />}
            showCancel
            cancelText="Anuluj"
            okText="Zweryfikuj email"
            okButtonProps={{ danger: true }}
            cancelButtonProps={{
              ghost: true,
            }}
          >
            {record.isEmailVerified ? (
              <AppButton disabled>
                <MdCheckCircle /> <MdEmail />
              </AppButton>
            ) : (
              <AppButton danger>
                <MdCheckCircle /> <MdEmail />
              </AppButton>
            )}
          </AppPopconfirm>
          <AppPopconfirm
            title="Wysłać ponownie email weryfikacyjny?"
            onConfirm={() => actionVerifyEmailResend(record.email)}
            icon={<AiOutlineQuestionCircle />}
            showCancel
            cancelText="Anuluj"
            okText="Wyślij email"
            okButtonProps={{ danger: true }}
            cancelButtonProps={{
              ghost: true,
            }}
          >
            {record.isEmailVerified ? (
              <AppButton disabled>
                <GrPowerReset /> <MdEmail />
              </AppButton>
            ) : (
              <AppButton danger>
                <GrPowerReset /> <MdEmail />
              </AppButton>
            )}
          </AppPopconfirm>
          <AppPopconfirm
            title="Usunąć to konto?"
            onConfirm={() => actionDelete(record._id)}
            icon={<AiOutlineQuestionCircle />}
            showCancel
            cancelText="Anuluj"
            okText="Usuń"
            okButtonProps={{ danger: true }}
            cancelButtonProps={{
              ghost: true,
            }}
          >
            <AppButton danger>
              <MdDelete />
            </AppButton>
          </AppPopconfirm>
          <Popover
            content={
              <>
                <p>
                  <b>Username:</b> {record.username}
                </p>
                <p>
                  <b>FirstName:</b> {record.firstName}
                </p>
                <p>
                  <b>LastName:</b> {record.lastName}
                </p>
                <p>
                  <b>Name:</b> {record.name}
                </p>
              </>
            }
          >
            <AppButton>
              <AiFillInfoCircle />
            </AppButton>
          </Popover>
        </Space>
      ),
    },
  ]

  return (
    <>
      <AppButton type="primary">
        <Dropdown overlay={filterEmailMenu}>
          <a>
            Filtruj status{' '}
            <MdArrowDropDownCircle style={{ verticalAlign: 'middle' }} />
          </a>
        </Dropdown>
      </AppButton>
      <br />
      <Table
        columns={columns}
        dataSource={resultsTab}
        pagination={false}
        rowKey={(record) => record._id}
      />
    </>
  )
}

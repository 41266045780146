import { Formik } from 'formik'
import { t } from 'src/locale'
import * as Yup from 'yup'
import { AppButton, AppInput, FormikForm } from '../AppComponents'
import { useActiveAccount, useTokenBoolean } from 'src/hooks'

type ResetPasswordFormProps = {
  handleSubmit: (values: { email: string }) => void
}

const ResetPasswordValidation = Yup.object().shape({
  email: Yup.string().email(t('invalidEmail')).required(t('required')).min(1),
})

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  handleSubmit,
}) => {
  const activeAccount = useActiveAccount()
  const tokenBoolean = useTokenBoolean()

  return (
    <Formik
      initialValues={{
        email: tokenBoolean && activeAccount ? activeAccount?.email : '',
      }}
      onSubmit={handleSubmit}
      validationSchema={ResetPasswordValidation}
    >
      {({ isValid, dirty }) => (
        <FormikForm>
          <AppInput name="email" placeholder="E-mail" required type="email" />
          <AppButton
            htmlType="submit"
            disabled={!isValid && !dirty}
            onClick={() => localStorage.removeItem('token')}
          >
            {t('SendMeAnEmail')}
          </AppButton>
        </FormikForm>
      )}
    </Formik>
  )
}

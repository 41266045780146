export const fontFamily = {
  primary: "'Baloo 2'",
}

export const fontSize = {
  textXXS: '10px',
  textXS: '12px',
  textS: '13px',
  textXM: '14px',
  textM: '16px',
  textL: '18px',
  textXL: '20px',
  heading5: '18px',
  heading4: '25px',
  heading3: '30px',
  heading2: '35px',
  heading1: '40px',
}

export const inputSize = {
  md: '40px',
}

export const spacing = {
  xxs: '4px',
  xs: '8px',
  s: '12px',
  m: '14px',
  l: '16px',
  xl: '18px',
  xxl: '24px',
  xxxl: '28px',
  h: '40px',
  xh: '48px',
  xxh: '60px',
}

export const mediaBreakpoints = {
  mobile: '480px',
  tablet: '768px',
  pc: '1024px',
  numbers: {
    mobile: 480,
    tablet: 768,
    pc: 1024,
  },
}

export const fontWeight = {
  bold: '700',
  normal: '400',
  light: '300',
}

export const border = {
  radius: '5px',
  radiusL: '15px',
  radiusXL: '25px',
  width: {
    default: '1px',
  },
}

export const sideMenu = {
  maxWidth: '308px',
  maxWidthSmall: '250px',
  minWidth: '224px',
  width_default: 'calc(18% + 40px)',
  width_small: 'calc(19% + 24px)',
}

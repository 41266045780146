import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import {
  MetaTags,
  SingleEventContainer,
  SingleEventHeader,
  SingleEventImage,
  Space,
} from 'src/components'
import { AppLoading } from 'src/components/AppComponents/AppLoading'
import { SingleEventBody } from 'src/components/Events/SingleEvent/SingleEventBody'
import { SingleEventButtons } from 'src/components/Events/SingleEvent/SingleEventButtons'
import { useActiveAccount, useAppDispatch, useAppSelector } from 'src/hooks'
import { t } from 'src/locale'
import { E404 } from 'src/pages/E404'
import { getOneEventByCleanIdAction } from 'src/store'

export const SingleEvent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { eventId } = useParams()
  const { event, get } = useAppSelector((state) => state.events.single)
  const { isLoading } = get
  const activeAccount = useActiveAccount()

  useEffect(() => {
    if (eventId) {
      dispatch(
        getOneEventByCleanIdAction({
          eventId: +eventId,
        })
      )
    }
  }, [])

  const isAuthor = activeAccount?._id === event?.author._id
  const isJustAUser = !(
    activeAccount?.roles?.includes('admin') ||
    activeAccount?.roles?.includes('mod')
  )
  const isPrivate = event?.privacy === 'private'
  const renderedMetaTitle = `${event?.title.substring(0, 44).trim()}${
    event?.title && event?.title.length > 40 ? '...' : ''
  }`
  const renderedMetaDesc = `${event?.description
    .substring(0, 156)
    .replace('\n', ' ')
    .trim()}${
    event?.description && event?.description.length > 156 ? '...' : ''
  }`
  const renderedMetaUrl = `https://mapawiedzy.com/events/${event?.eventId}/`
  const renderedMetaImage = `${
    event?.image?.light_url ||
    `https://mapawiedzy.com/${
      localStorage.getItem('theme') === 'light' ? 'light' : 'dark'
    }-169placeholder.webp`
  }`

  if (isLoading) return <AppLoading />

  if ((!isLoading && !event) || (!isAuthor && isPrivate && isJustAUser))
    return <E404 />
  return (
    <>
      <MetaTags
        title={renderedMetaTitle}
        desc={renderedMetaDesc}
        url={renderedMetaUrl}
        image={renderedMetaImage}
      />
      <SingleEventContainer>
        <SingleEventButtons
          authorId={event?.author._id}
          eventId={event?.eventId}
          event={event}
        />
        {event?.image && event?.image !== null ? (
          <SingleEventImage image={event.image.light_url} title={event.title} />
        ) : (
          <Space />
        )}
        <SingleEventHeader event={event} />
        <SingleEventBody event={event} />
      </SingleEventContainer>
    </>
  )
}

import styled from 'styled-components/macro'

export const HeroContent = styled.div`
  fontsize: ${({ theme }) => theme.fontSize.textL};
  fontweight: ${({ theme }) => theme.fontWeight.normal};
  //add a 10% padding to left side and 20% to the right side
  padding: 2% 15% 0 7%;
  line-height: 1.5;

  br {
    display: block;
    margin-top: 1.25rem;
    content: '';
  }
`

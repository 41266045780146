import moment from 'moment'
import { Term } from 'src/types'

export const getEventStatus = (term?: Term | { from: string; to: string }) => {
  if (term === undefined)
    return {
      err: 'Term is undefined',
      isToday: false,
      isInProgress: false,
    }
  const currentDate = moment(Date.now()).format('YYYY-MM-DD')
  const startDate = moment(term.from).format('YYYY-MM-DD')

  const isToday = currentDate === startDate
  const isInProgress =
    new Date(term.from).getTime() <= new Date(Date.now()).getTime() &&
    new Date(term.to).getTime() > new Date(Date.now()).getTime()

  return {
    isToday,
    isInProgress,
  }
}

import { Link } from 'react-router-dom'
import {
  AppButton,
  AppInput,
  AppSwitch,
  InputBlock,
} from 'src/components/AppComponents'
import { AppInputNumber } from 'src/components/AppComponents/AppInputs/AppInputNumber'
import { t } from 'src/locale'
import {
  EventPriceCheckbox,
  InputLabel,
  SupportUs,
} from '../styles/EditSingleEventForm.styles'

type EventPriceProps = {
  free: boolean
}

export const EventPrice: React.FC<EventPriceProps> = ({ free }) => {
  return (
    <InputBlock $twoColumns>
      {!free ? (
        <>
          <EventPriceCheckbox>
            <strong>{t('PaidEvent')}</strong>
            {t('ThankYouForSupportingOurProject')}
          </EventPriceCheckbox>
          <InputLabel>
            <span>{t('Price')}</span>
            <AppInputNumber name="price" />
          </InputLabel>
        </>
      ) : (
        <>
          <EventPriceCheckbox>
            <strong>{t('EventFree')}</strong>
            {t('DoYouWantToAddPaidEvent')}
          </EventPriceCheckbox>
          <SupportUs>
            <AppButton
              type="primary"
              onClick={() =>
                window.open('https://zrzutka.pl/tyzyd7/award/jyat4m', '_blank')
              }
            >
              {t('SupportUs')}
            </AppButton>
          </SupportUs>
        </>
      )}
    </InputBlock>
  )
}

import styled from 'styled-components'

export const DomainLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSize.textS};
  color: ${({ theme }) => theme.colors.fontColor.secondary};
  font-style: italic;
  width: 100%;
  display: flex;
  flex-flow: column;
  place-items: center;
`

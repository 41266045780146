import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteOneEventByIdService } from 'src/services'
import { ApiError, OnError, OnSuccess } from 'src/types'

type deleteOneEventByIdActionArgs = {
  eventId: string
  onSuccess?: OnSuccess
  onError?: OnError
}

export const deleteOneEventByIdAction = createAsyncThunk<
  undefined,
  deleteOneEventByIdActionArgs,
  { rejectValue: ApiError }
>(
  'events/deleteOneEventById',
  async ({ onSuccess, onError, eventId }, { rejectWithValue, signal }) => {
    try {
      const result = await deleteOneEventByIdService({ eventId }, { signal })
      onSuccess?.()
      return result
    } catch (error: any) {
      onError?.(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

import axios from 'axios'
import { api } from '../../api'
import { ApiServiceHelpers } from '../../types'

export const createPatchService =
  <
    ServiceArgs extends {
      payload: Record<string, any>
      [key: string]: any
    } = { payload: Record<string, any> },
    ServiceResponse = unknown
  >({
    urlCreator,
  }: {
    urlCreator: (args: Omit<ServiceArgs, 'payload'>) => string
  }) =>
  async (
    { payload: data, ...args }: ServiceArgs,
    { signal }: ApiServiceHelpers
  ): Promise<ServiceResponse> => {
    const source = axios.CancelToken.source()

    signal.addEventListener('abort', () => source.cancel)

    const response = await api.request<ServiceResponse>({
      url: urlCreator(args),
      method: 'PATCH',
      cancelToken: source.token,
      data,
    })

    return response.data
  }

export const createPatchFormService =
  <
    ServiceArgs extends {
      payload: Record<string, any>
      [key: string]: any
    } = { payload: Record<string, any> },
    ServiceResponse = unknown
  >({
    urlCreator,
  }: {
    urlCreator: (args: Omit<ServiceArgs, 'payload'>) => string
  }) =>
  async (
    { payload: data, ...args }: ServiceArgs,
    { signal }: ApiServiceHelpers
  ): Promise<ServiceResponse> => {
    const source = axios.CancelToken.source()

    signal.addEventListener('abort', () => source.cancel)
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
      // TODO: find better solution to make formData from js object
      // @ts-ignore
      const value = data[key]
      if (Array.isArray(value)) {
        value.forEach((element) => formData.append(key, element))
      } else {
        formData.append(key, value || '')
      }
    })

    const response = await api.request<ServiceResponse>({
      url: urlCreator(args),
      method: 'PATCH',
      cancelToken: source.token,
      data: formData,
    })

    return response.data
  }

import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppLoading } from 'src/components/AppComponents/AppLoading'
import { EditSingleEventContainer, EditSingleEventForm } from 'src/components'
import { useActiveAccount, useAppDispatch, useAppSelector } from 'src/hooks'
import { getOneEventByCleanIdAction } from 'src/store'
import { E404 } from 'src/pages/E404'
import { TopButtons } from 'src/components/Events/EditSingleEvent/forms/TopButtons'
import { Helmet } from 'react-helmet'
import { t } from 'src/locale'

export const EditSingleEvent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { eventId } = useParams()
  const { event, get } = useAppSelector((state) => state.events.single)
  const { isLoading } = get
  const activeAccount = useActiveAccount()

  useEffect(() => {
    if (eventId) {
      dispatch(
        getOneEventByCleanIdAction({
          eventId: +eventId,
        })
      )
    }
  }, [])

  const isAuthor = activeAccount?._id === event?.author._id
  const isJustAUser = !(
    activeAccount?.roles?.includes('admin') ||
    activeAccount?.roles?.includes('mod')
  )

  if (isLoading) return <AppLoading />

  if ((!isLoading && !event) || (!isAuthor && isJustAUser)) return <E404 />

  return (
    <>
      <Helmet>
        <title>
          {t('Edit')} {event?.title} // mapawiedzy.com
        </title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <EditSingleEventContainer>
        <TopButtons eventId={eventId} />
        {event && <EditSingleEventForm event={event} />}
      </EditSingleEventContainer>
    </>
  )
}

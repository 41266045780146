import { createSlice } from '@reduxjs/toolkit'
import { SliceStatus, Account } from 'src/types'
import { getActiveAccount } from './actions/getActiveAccount'

type FetchedAccountSliceData = {
  account: Account | null
  status: SliceStatus
}

const initialState: FetchedAccountSliceData = {
  account: null,
  status: {
    isLoading: false,
    error: null,
  },
}

export const fetchedAccountSlice = createSlice({
  name: 'fetchedUser',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActiveAccount.pending, (state) => {
      state.status.isLoading = true
    })
    builder.addCase(getActiveAccount.fulfilled, (state, action) => {
      state.status.isLoading = false
      state.status.error = null
      state.account = action.payload
    })
    builder.addCase(getActiveAccount.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.error = action.payload
    })
  },
})

import styled from 'styled-components/macro'

export const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% 15%;
  height: 500px;
`

export const HeroContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

export const HeroContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

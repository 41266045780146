import { useState } from 'react'
import { useTheme } from 'styled-components'

export const useScreen = () => {
  const theme = useTheme()
  const [screen, setScreen] = useState<number>(window.innerWidth)
  window.addEventListener('resize', () => {
    setScreen(window.innerWidth)
  })
  const isMobile = screen <= theme.mediaBreakpoints.numbers.mobile
  const isTablet = screen <= theme.mediaBreakpoints.numbers.tablet
  const isPc = screen <= theme.mediaBreakpoints.numbers.pc
  const isBigger = screen > 1150
  const isHuge = !isMobile && !isTablet && !isPc
  return {
    screen,
    isMobile,
    isTablet,
    isPc,
    isBigger,
    isHuge,
  }
}

import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { TagsSelectorWrapper, TagsWrapper } from '../styles'
import { SmallHeader } from 'src/components/Typography'
import { useAppDispatch, useAppSelector } from 'src/hooks'
import { getTagsAction, modesTagsSelector } from 'src/store'
import { AppTag } from 'src/components'
import { Tag } from 'src/types'

type ModeSelectorProps = {
  name: string
}

export const ModeSelector = ({ name }: ModeSelectorProps) => {
  const dispatch = useAppDispatch()
  const { values, setFieldValue } = useFormikContext<{
    [record: string]: string
  }>()

  const [activeModeId, setActiveModeId] = useState<string>(values[name])

  const modes = useAppSelector(modesTagsSelector)

  useEffect(() => {
    dispatch(getTagsAction({}))
    setFieldValue(name, values[name])
  }, [])

  useEffect(() => {
    setFieldValue(name, activeModeId)
  }, [activeModeId])

  return (
    <TagsSelectorWrapper>
      <SmallHeader>Tryb</SmallHeader>
      <TagsWrapper>
        {modes.map((mode: Tag) => (
          <AppTag
            key={mode.key}
            type="mode"
            name={mode.name}
            onClick={() => {
              setActiveModeId(mode._id)
            }}
            isActive={activeModeId === mode._id}
          />
        ))}
      </TagsWrapper>
    </TagsSelectorWrapper>
  )
}
